import React, { useEffect, useRef, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import search from "../../assets/icons/searchIcon.svg";
import reportsData from "./reports-data";
import { useNavigate } from "react-router-dom";
import ReportssItemContainer from "../../components/reports/reports-item-container";

const Reports = () => {
  const [reportModules, setreportModules] = useState(reportsData);
  const reportRefs = useRef([]);
  const [selectedIndex, setSelectedIndex] = useState(0); // State to track the selected quick link

  const navigate = useNavigate();
  const handleItemClick = (index, report) => {
    navigate(report.path);
    const element = document.querySelector(".innerLayoutScroller");
    if (element.style.overflow === "hidden") {
      element.style.overflow = "auto";
    }
  };

  const SearchBar = () => {
    const [searchValue, setSearchValue] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
      const element = document.querySelector(".innerLayoutScroller");
      if (searchValue) {
        const results = reportsData
          .map((report) => {
            const childrens = report.childrens.filter((child) =>
              child.title.toLowerCase().includes(searchValue.toLowerCase())
            );
            if (childrens.length > 0) {
              return {
                childrens,
              };
            }
          })
          .filter((result) => result !== undefined)
          .reduce((acc, curr) => {
            return [...acc, ...curr.childrens];
          }, []);

        element.style.overflow = "hidden";

        setSearchResults(results.slice(0, 15));
      } else {
        element.style.overflow = "auto";
      }
      console.log(searchResults, "searchResults");
    }, [searchValue]);

    return (
      <>
        {searchValue && (
          <div
            className="h-[120%] -mt-5 w-full bg-[#0000005a] absolute z-0 "
            onClick={() => {
              setSearchValue("");
            }}
          ></div>
        )}
        <div className="w-[calc(100%-60px)] mx-[30px] bg-[#F9F9F9] mt-[1rem] mb-4 p-5 rounded-lg absolute">
          <div
            style={{ height: "2.5rem" }}
            className={`input_border flex items-center rounded-md w-full bg-white`}
          >
            <span className="px-2">
              <img width={"16px"} src={search} alt="search icon" />
            </span>
            <input
              placeholder={`Search reports...`}
              className=" table_search_input w-full rounded-md fs_12 placeholder:text-fs_12"
              type="text"
              onChange={(e) => {
                setTimeout(() => {
                  setSearchValue(e.target.value);
                }, 500);
              }}
              onFocus={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>
          {searchValue &&
            (searchResults.length > 0 ? (
              <div className="mt-5 grid grid-cols-3 gap-3 resultContainer">
                {searchResults.map((result, index) => {
                  console.log("Sbfkhbfjsjsvf", result);
                  return (
                    <>
                      <ReportssItemContainer
                        key={index}
                        report={result}
                        search
                        handleItemClick={handleItemClick}
                      />
                    </>
                  );
                })}
              </div>
            ) : (
              <div className="text-center mt-5 text-000000">
                No results found
              </div>
            ))}
        </div>
      </>
    );
  };

  useEffect(() => {
    reportRefs.current = reportModules.map(
      (_, i) => reportRefs.current[i] ?? React.createRef()
    );
  }, [reportModules]);

  const handleQuickLinkClick = (index) => {
    setSelectedIndex(index); // Update the selected index

    const element = reportRefs.current[index]?.current;
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash.startsWith("#report-")) {
        const index = hash.replace("#report-", "");
        const element = reportRefs.current[index]?.current;
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    };

    window.addEventListener("hashchange", handleHashChange, false);

    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange, false);
    };
  }, []);

  return (
    <InnerLayout backBtn={true} list={true} title="Reports">
      <div className="h-full w-full">
        {/* <nav className="sticky top-0 z-30   bg-f3f8ff quick-div  w-full flex justify-center px-[3rem]">
        <ul className="flex gap-8 py-4 overflow-x-auto w-full  justify-center">
            {reportModules?.map((rep, repIndex) => {
              return (
                <li>
                  <button
                    onClick={() => handleQuickLinkClick(repIndex)}
                    className={`cursor-pointer bg-transparent text-sm fw_400 text-000000 border-none hover:text-primary hover:underline   ${
                      selectedIndex === repIndex
                        ? "text-primary font-bold underline"
                        : "text-blue-500"
                    }`}
                  >
                    {rep.title}
                  </button>
                </li>
              );
            })}
          </ul>
        </nav> */}
        <div
          style={
            {
              // maxHeight: `calc(100% - ${heightOfFirstDiv}px)`,
              // height: `calc(100% - ${heightOfFirstDiv}px)`,
            }
          }
          className={`w-full flex gap-10 relative `}
        >
          <SearchBar />
          <div
            className={`flex flex-wrap ml-3 w-full px-5 ${
              reportModules.length > 0 ? "mt-32" : "mt-5"
            }`}
          >
            {reportModules.map((report, reportIndex) => (
              <div
                ref={reportRefs.current[reportIndex]}
                key={reportIndex}
                id={`report-${reportIndex}`} /* Add this id to target scroll position */
                className="w-full my-3"
              >
                <>
                  <div className="max-w-[1200px] m-auto w-full">
                    <div className="font-semibold text-lg">{report.title}</div>
                    <div className="grid grid-cols-3 gap-3">
                      {report?.childrens?.map((child, childIndex) => {
                        return (
                          <>
                            <ReportssItemContainer
                              key={childIndex}
                              handleItemClick={(index, clickedReport) => {
                                handleItemClick(index, clickedReport);
                              }}
                              report={child}
                              index={childIndex}
                            />
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>
              </div>
            ))}
          </div>
        </div>
      </div>
    </InnerLayout>
  );
};

export default Reports;
