import React, { useEffect, useRef, useState } from "react";
import { customStylesForFilter } from "../../../components/reports/reports-layout";
import { paymentModeWiseSalesReportAction } from "../../../redux/actions/report-action";
import { useDispatch } from "react-redux";
import ReportLayout from "../../../components/reports/report-layout";
import DeleteIcon from "../../../assets/icons/delete-icon";
import Select from "react-select";
import { PlusIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import SearchSelectFieldNonFormik from "../../../components/forms/search-select-field";
import { paymentMethodListAction } from "../../../redux/actions/general-action";
import {
  conditionsOptions,
  handleAddFilterRow,
  handleFilterRowChange,
  handleRemoveFilterRow,
  transformData,
} from "../report-helper-function";
import errorToast from "../../../components/helper-components/error-toast";
import { invoiceTableAction } from "../../../redux/actions/invoices-action";

const PaymentModeWiseSalesReport = () => {
  const dispatch = useDispatch();
  const [finalFilters, setFinalFilters] = useState({});
  const [tableData, setTableData] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState(normalHeading);
  const [tableBody, setTableBody] = useState(normalColumns);
  const [openMoreFilter, setOpenMoreFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [overlayHeight, setOverlayHeight] = useState("85vh");
  const filterContainerRef = useRef(null);
  const [groupByOptions, setgroupByOptions] = useState([
    { label: "None", value: "" },
    { label: "Payment Mode", value: "so.payment_mode_name" },
  ]);
  const [payModesList, setPayModesList] = useState([]);
  useEffect(() => {
    dispatch(paymentMethodListAction(onSuccessPayList, onErrorPayList));
  }, []);
  const onErrorPayList = () => {};
  const onSuccessPayList = (data) => {
    setPayModesList(
      data?.data?.paymentModes.map((item) => {
        return { label: item?.paymentType, value: item?.paymentType };
      })
    );
  };
  const [filters, setFilters] = useState({
    filter_by: "",
    filters: [
      { column: null, condition: null, value: "", from: null, to: null },
    ],
    selectedColumns: selectedColumns.map((column) => tableBody[column]),
    from: null,
    to: null,
    group_by: "",
  });
  const initFilter = {
    filter_by: "",
    filters: [
      { column: null, condition: null, value: "", from: null, to: null },
    ],
    selectedColumns: normalHeading.map((column) => normalColumns[column]),
    from: null,
    to: null,
    group_by: "",
  };
  function ApplyFilterFn(filters) {
    setLoading(true);
    dispatch(
      paymentModeWiseSalesReportAction(
        filters,
        (data) => {
          setLoading(false);
          const transformedData = transformData(
            data?.data?.result,
            filters?.group_by === "so.payment_mode_name"
              ? paymentModeColumn
              : normalColumns
          );
          setTableData(transformedData);
          setOpenMoreFilter(false);
          setFinalFilters(filters);

          if (filters?.group_by === "so.payment_mode_name") {
            setSelectedColumns(paymentModeHeading);
            setTableBody(paymentModeColumn);
          } else {
            console.log(filters, "fdsfdsfsdfsdfds");
            setSelectedColumns(normalHeading);
            setTableBody(normalColumns);
          }
        },
        onError
      )
    );
  }

  const onError = (data) => {
    errorToast(data?.data?.message);
    setLoading(false);
    setOpenMoreFilter(false);
  };
  useEffect(() => {
    if (openMoreFilter && filterContainerRef.current) {
      const filterContainerHeight = filterContainerRef.current.clientHeight;
      setOverlayHeight(`calc(85vh - ${filterContainerHeight}px)`);
    } else {
      setOverlayHeight("85vh");
    }
  }, [openMoreFilter]);

  useEffect(() => {
    ApplyFilterFn(initFilter);
  }, []);

  // useEffect(() => {
  //   const filter = {
  //     filter_by: filters?.filter_by,
  //     filters: filters?.filters,
  //     selectedColumns: selectedColumns.map((column) => tableBody[column]),
  //     from: filters?.from,
  //     to: filters?.to,
  //     group_by: filters?.group_by,
  //   };

  // }, [selectedColumns]);

  const dropdownOptions = {
    payment_mode: payModesList,
  };

  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight =
        document.querySelector(".report-header")?.clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  console.log(tableData, "dsfsfsdfdsfsdfsf");

  return (
    <ReportLayout
      tableData={tableData}
      availableColumns={filters?.group_by ? paymentModeHeading : normalHeading}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      groupByOptions={groupByOptions}
      handleApply={(filters) => {
        console.log(filters, "dsfsfsdfdsfsdfsf");
        if (filters?.group_by) {
          ApplyFilterFn({
            ...filters,
            selectedColumns: paymentModeHeading.map(
              (column) => paymentModeColumn[column]
            ),
          });
        } else {
          console.log(filters, "dsfsdfdsfdsfdsfdsfds");
          setSelectedColumns(normalHeading);
          setTableBody(normalColumns);
          ApplyFilterFn({
            ...filters,
            selectedColumns: normalHeading.map(
              (column) => normalColumns[column]
            ),
          });
        }
      }}
      openMoreFilter={openMoreFilter}
      setOpenMoreFilter={setOpenMoreFilter}
      filters={filters}
      setFilters={setFilters}
      title={"Payment Mode Wise Sales Report"}
      initFilter={initFilter}
    >
      {openMoreFilter && (
        <div className="flex flex-col gap-3 pt-5 mx-6" ref={filterContainerRef}>
          {filters?.filters.map((row, index) => (
            <div key={index} className="flex gap-3">
              <div
                style={{ maxWidth: "11rem", height: "2.6rem" }}
                className={`w-full flex items-center rounded-md input_border`}
              >
                <Select
                  className="border-0 w-full text-xs placeholder:text-xs"
                  menuPortalTarget={document.body}
                  styles={customStylesForFilter}
                  value={filterOptions?.filter(
                    (item) => item?.value === row?.column
                  )}
                  options={filterOptions}
                  placeholder="Filters"
                  onChange={(selectedOption) =>
                    handleFilterRowChange(
                      index,
                      "column",
                      selectedOption?.value,
                      setFilters,
                      filters
                    )
                  }
                />
              </div>
              <div
                style={{ maxWidth: "11rem", height: "2.6rem" }}
                className={`w-full flex items-center rounded-md input_border`}
              >
                <Select
                  className="border-0 w-full text-xs placeholder:text-xs"
                  menuPortalTarget={document.body}
                  styles={customStylesForFilter}
                  value={conditionsOptions.filter(
                    (item) => item.value === row.condition
                  )}
                  options={conditionsOptions?.filter((opt) =>
                    filterOptions
                      ?.find((option) => option.value === row.column)
                      ?.operators?.includes(opt?.value)
                  )}
                  placeholder="Conditions"
                  onChange={(selectedOption) =>
                    handleFilterRowChange(
                      index,
                      "condition",
                      selectedOption?.value,
                      setFilters,
                      filters
                    )
                  }
                />
              </div>

              {row?.condition === "range" ? (
                <>
                  {filterOptions?.find((option) => option.value === row.column)
                    ?.valueField === "search" ? (
                    <>
                      <SearchSelectFieldNonFormik
                        isMulti={true}
                        value={{
                          label: row?.from ? (
                            row?.from
                          ) : (
                            <p className="text-667085 text-xs">
                              Search and Select Item..
                            </p>
                          ),
                          value: row?.from,
                        }}
                        handleChange={(selectedOption, actionMeta) => {
                          setFilters({
                            ...filters,
                            filters: filters?.filters.map((row, idx) =>
                              idx === index
                                ? {
                                    ...row,
                                    from: selectedOption?.value,
                                  }
                                : row
                            ),
                          });
                        }}
                        action={
                          filterOptions?.find(
                            (option) => option.value === row.column
                          )?.action
                        }
                        field={
                          filterOptions?.find(
                            (option) => option.value === row.column
                          )?.field
                        }
                      />

                      <SearchSelectFieldNonFormik
                        value={{
                          label: row?.to ? (
                            row?.to
                          ) : (
                            <p className="text-667085 text-xs">
                              Search and Select Item..
                            </p>
                          ),
                          value: row?.to,
                        }}
                        handleChange={(selectedOption, actionMeta) => {
                          console.log(selectedOption, "dfgfdgddfdg");
                          setFilters({
                            ...filters,
                            filters: filters?.filters.map((row, idx) =>
                              idx === index
                                ? {
                                    ...row,
                                    to: selectedOption?.value,
                                  }
                                : row
                            ),
                          });
                        }}
                        action={
                          filterOptions?.find(
                            (option) => option.value === row.column
                          )?.action
                        }
                        field={
                          filterOptions?.find(
                            (option) => option.value === row.column
                          )?.field
                        }
                      />
                    </>
                  ) : (
                    <>
                      <div
                        style={{ maxWidth: "15rem", height: "2.6rem" }}
                        className={`w-full flex items-center rounded-md input_border `}
                      >
                        <input
                          min={0}
                          placeholder="From"
                          className="table_search_input w-full rounded-md text-xs h-full placeholder:text-xs placeholder:text-787878 px-3"
                          type={"number"}
                          value={row.from}
                          onChange={(e) =>
                            handleFilterRowChange(
                              index,
                              "from",
                              e.target.value,
                              setFilters,
                              filters
                            )
                          }
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "Subtract") {
                              e.preventDefault(); // Prevent minus key
                            }
                          }}
                        />
                      </div>
                      <div
                        style={{ maxWidth: "15rem", height: "2.6rem" }}
                        className={`w-full flex items-center rounded-md input_border`}
                      >
                        <input
                          min={0}
                          placeholder="To"
                          className="table_search_input w-full rounded-md text-xs h-full placeholder:text-xs placeholder:text-787878 px-3"
                          type={"number"}
                          value={row.to}
                          onChange={(e) =>
                            handleFilterRowChange(
                              index,
                              "to",
                              e.target.value,
                              setFilters,
                              filters
                            )
                          }
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "Subtract") {
                              e.preventDefault(); // Prevent minus key
                            }
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : filterOptions?.find((option) => option.value === row.column) // Find the column in filterOptions
                  ?.valueField === "search" ? (
                <>
                  <SearchSelectFieldNonFormik
                    value={{
                      label: row?.value ? (
                        row?.value
                      ) : (
                        <p className="text-667085 text-xs">
                          Search and Select Item..
                        </p>
                      ),
                      value: row?.value ?? row?.value,
                    }}
                    handleChange={(selectedOption, actionMeta) => {
                      setFilters({
                        ...filters,
                        filters: filters?.filters.map((row, idx) =>
                          idx === index
                            ? {
                                ...row,
                                value: selectedOption?.value,
                              }
                            : row
                        ),
                      });
                    }}
                    action={
                      filterOptions?.find(
                        (option) => option.value === row.column
                      )?.action
                    }
                    field={
                      filterOptions?.find(
                        (option) => option.value === row.column
                      )?.field
                    }
                  />
                </>
              ) : filterOptions?.find((option) => option.value === row.column) // Find the column in filterOptions
                  ?.valueField === "dropdown" ? (
                <div
                  style={{ maxWidth: "15rem", height: "2.6rem" }}
                  className={`w-full flex items-center rounded-md input_border`}
                >
                  <Select
                    value={{ label: row?.value, value: row?.value }}
                    className="border-0 w-full text-xs placeholder:text-xs"
                    menuPortalTarget={document.body}
                    styles={customStylesForFilter}
                    options={
                      dropdownOptions[
                        filterOptions?.find(
                          (option) => option.value === row.column
                        )?.field
                      ]
                    }
                    placeholder="Select"
                    onChange={(selectedOption) =>
                      handleFilterRowChange(
                        index,
                        "value",
                        selectedOption?.value,
                        setFilters,
                        filters
                      )
                    }
                  />
                </div>
              ) : (
                <div
                  style={{ maxWidth: "15rem", height: "2.6rem" }}
                  className={`w-full flex items-center rounded-md input_border`}
                >
                  <input
                    onWheel={(e) => e.target.blur()}
                    min={0}
                    placeholder="Enter Values"
                    className="table_search_input w-full rounded-md text-xs h-full placeholder:text-xs placeholder:text-787878 px-3"
                    type={
                      filterOptions?.find((opt) => opt?.value === row?.column)
                        ?.valueField
                    }
                    value={row.value}
                    onChange={(e) =>
                      handleFilterRowChange(
                        index,
                        "value",
                        e.target.value,
                        setFilters,
                        filters
                      )
                    }
                    onKeyDown={(e) => {
                      if (
                        e.key === "Subtract" &&
                        filterOptions?.find((opt) => opt?.value === row?.column)
                          ?.valueField === "number"
                      ) {
                        e.preventDefault(); // Prevent minus key
                      }
                    }}
                  />
                </div>
              )}

              {index === filters?.filters.length - 1 ? (
                <PlusIcon
                  onClick={() => {
                    handleAddFilterRow(setFilters, filters);
                  }}
                  className={`w-5 text-primary cursor-pointer`}
                />
              ) : (
                <div
                  className="mt-3"
                  onClick={() =>
                    handleRemoveFilterRow(index, setFilters, filters)
                  }
                >
                  <DeleteIcon className="cursor-pointer" />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {openMoreFilter ? (
        <div
          className="mt-6 w-full flex bg-[#0000005a] relative z-2"
          style={{ height: overlayHeight }}
          onClick={() => {
            setOpenMoreFilter(!openMoreFilter);
          }}
        ></div>
      ) : (
        <>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div
              className="mt-3 relative"
              style={{
                maxWidth: "100%",
                overflowX: "auto",
                overflowY: "auto",
                maxHeight: `calc(100% - ${heightOfFirstDiv}px)`,
                height: `calc(100% - ${heightOfFirstDiv}px)`,
              }}
            >
              <table className="w-full table-fixed">
                <thead className="bg-F2F4F5 sticky w-full ">
                  <tr>
                    {selectedColumns.map((heading, index) => (
                      <th
                        className={`tab-header-right-not-fixed  min-w-[10rem]  whitespace-nowrap text-sm fw_500 text-475467 bg-F2F4F5 text-start`}
                        key={heading}
                      >
                        {heading}
                      </th>
                    ))}
                  </tr>
                </thead>
                {/* </table> */}
                {/* <table className="w-full table-fixed flex-grow"> */}
                <tbody>
                  {tableData.map((item, index) => {
                    if (
                      finalFilters?.group_by &&
                      index < tableData?.length - 1
                    ) {
                      return (
                        <tr key={index}>
                          {selectedColumns.map((heading, idx) => (
                            <td
                              className="tab-cell-right-not-fixed min-w-[10rem]  text-start  text-xs h-[3rem] fw_400 text-secondary text-nowrap"
                              key={idx}
                            >
                              {item[heading]}
                            </td>
                          ))}
                        </tr>
                      );
                    } else if (!finalFilters?.group_by) {
                      return (
                        <tr key={index}>
                          {selectedColumns.map((heading, idx) => (
                            <td
                              className="tab-cell-right-not-fixed min-w-[10rem]  text-start text-sm fw_400 h-[3rem] text-secondary text-nowrap"
                              key={idx}
                            >
                              {item[heading]}
                            </td>
                          ))}
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
              <div className="absolute bottom-0  w-[100%]">
                <table className=" w-full">
                  {finalFilters?.group_by && (
                    <tfoot className="h-[3rem]">
                      {tableData.map((item, index) => {
                        if (index === tableData?.length - 1)
                          return (
                            <tr key={index} className=" bg-F2F4F5 ">
                              {selectedColumns.map((heading, idx) => (
                                <td
                                  className="min-w-[10rem]   text-left text-sm fw_400 h-[5rem] text-secondary text-nowrap"
                                  key={idx}
                                >
                                  {item[heading]}
                                </td>
                              ))}
                            </tr>
                          );
                      })}
                    </tfoot>
                  )}
                </table>
              </div>
            </div>
          )}
        </>
      )}
    </ReportLayout>
  );
};

const normalHeading = [
  "Payment Mode",
  "Bill Date",
  "Bill Num",
  "Payment Amount",
];
const paymentModeHeading = [
  "Payment Mode",
  "Number of Transactions",
  "% of Total Sales",
  "Total Sales (Amount)",
];

const normalColumns = {
  "Payment Mode": "bn.payment_mode_name",
  "Bill Date": "so.created_date",
  "Bill Num": "so.sot_invoice_number",
  "Payment Amount": "bn.payment_amount",
};
const paymentModeColumn = {
  "Payment Mode": "bn.Payment Mode",
  "Number of Transactions": "so.Number of Transactions",
  "% of Total Sales": "so.% of Total Sales",
  "Total Sales (Amount)": "so.Total Sales (Amount)",
};

const filterOptions = [
  {
    label: "Payment Mode",
    value: "bn.payment_mode_name",
    operators: ["equal_to"],
    valueField: "dropdown",
    field: "payment_mode",
  },
  {
    label: "Bill Num",
    value: "sot_invoice_number",
    operators: ["equal_to"],
    // valueField: "search",
    // action: invoiceTableAction,
    field: "sot_invoice_number",
  },
  {
    label: "Payment Amount",
    value: "bn.payment_amount",
    operators: ["equal_to", "greater_than", "less_than", "range"],
    valueField: "number",
  },
];

export default PaymentModeWiseSalesReport;
