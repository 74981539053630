import React, { useEffect, useState } from "react";
import TextAreaField from "../../forms/textarea-field";
import InputField from "../../forms/input-field";
import { useDispatch } from "react-redux";
import SelectField from "../../forms/select-field";
import { stateListAction } from "../../../redux/actions/general-action";

const StoreShopAddressTab = ({ disabled = false }) => {
  const dispatch = useDispatch();

  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    dispatch(stateListAction(onSuccessState, onErrorState));
  }, []);

  const onSuccessState = (data) => {
    setStateList(
      data.map((item) => {
        return { label: item?.name, value: item?.name };
      })
    );
  };
  const onErrorState = () => {};
  return (
    <div className="w-full 2xl:w-[40%] xl:w-[50%] lg:w-[60%] px-5 xl:px-10">
      <TextAreaField
        isDisabled={disabled}
        label="Address "
        placeholder="Street 1"
        name="shop_address"
        id={"shop_address"}
        isRequired={true}
        labelWidth="8.5rem"
        feildHeight="5rem"
      />
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="text"
        name="shop_area"
        placeholder=""
        label="Area"
        labelWidth="8.5rem"
      />
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="text"
        name="shop_landmark"
        placeholder=""
        label="Landmark"
        labelWidth="8.5rem"
      />
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="text"
        name="shop_town_or_village"
        placeholder=""
        label="Town/ Village"
        labelWidth="8.5rem"
      />
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="text"
        name="shop_city"
        placeholder=""
        label="City"
        labelWidth="8.5rem"
      />
      <SelectField
        isDisabled={disabled}
        placeholder={"Select state"}
        options={stateList}
        label={"State"}
        id={"shop_state"}
        name="shop_state"
        isSearchable={true}
        errMsgContainerClass="ps-2"
        isRequired={true}
        labelWidth="8.5rem"
      />

      <SelectField
        isDisabled={disabled}
        isRequired={true}
        placeholder={"Select country"}
        options={countryList}
        label={"Country/Region"}
        id={"shop_country_region"}
        name="shop_country_region"
        isSearchable={true}
        errMsgContainerClass="ps-2"
        labelWidth="8.5rem"
      />
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="text"
        name="shop_pin_code"
        placeholder=""
        label="Pin Code"
        labelWidth="8.5rem"
      />
    </div>
  );
};

export default StoreShopAddressTab;

const countryList = [{ label: "India", value: "india" }];
