import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";

import { storeIdAction } from "../../../redux/actions/general-action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  calculateSubtotal,
  calculateTotal,
} from "../../invoice-section/invoice-finalBill-table";
import { storeDetailsAction } from "../../../redux/actions/invoices-action";
import successToast from "../../helper-components/success-toast";
import errorToast from "../../helper-components/error-toast";
import {
  purchaseInvoiceEditAction,
  purchaseInvoiceReceiptCreateAction,
} from "../../../redux/actions/purchase-action";
import PurchaseInvoiceBillingTable from "./purchase-invoice-billing-table";
import BatchQuantityExpiryPopoverPurINS from "./batch-qty-expiry-popover";
import PurchaseInvoicePdfContent from "./purchase-invoice-pdf-content";
import moment from "moment";
import SalesPurchaseFinalBillTable from "../../sales-section/sales-purchase-finalBill-table";
import SalesPurchaseHeader from "../../sales-section/sales-purchase-header";
import { useHotkeys } from "react-hotkeys-hook";

const CreateEditViewPurchaseInvoice = ({
  orderDetail = [],
  vendorDetails = [],
  disabled = false,
  type = "",
  page = "",
  data,
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);

  const rowData = useSelector((state) => state.invoiceRowReducer);
  const searchRef = useRef(null);
  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight = document.querySelector(".first-div").clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);
  const initialData = {
    sl_no: 1,
    item_name: "",
    item_generic_name: "",
    item_code: "",
    item_uom: "",
    item_batches: "",
    item_unit_price: 0,
    item_invoice_open_quantity: 0,
    quantity: 0,
    item_total_amount: 0,
    item_id: "",
    item_gst: 0,
    item_sgst: 0,
    item_cgst: 0,
    item_tax_amount: 0,
    pot_id: null,
    item_uom_id: "",
  };
  const initialFormValues =
    rowData?.data?.length >= 1
      ? {
          ...rowData,
          data: [...rowData.data, initialData],
        }
      : {
          cmr_code: vendorDetails?.[0]?.cmr_id
            ? {
                label: vendorDetails?.[0]?.cmr_code ?? "",
                value: vendorDetails?.[0]?.cmr_id ?? "",
              }
            : "",
          cmr_name: vendorDetails?.[0]?.cmr_id
            ? {
                label: vendorDetails?.[0]?.cmr_name,
                value: vendorDetails?.[0]?.cmr_id,
              }
            : "",
          cmr_id: vendorDetails?.[0]?.cmr_id,
          remarks: vendorDetails?.[0]?.remarks ?? "",
          poit_total_discount: vendorDetails?.[0]?.poit_total_discount ?? 0,
          ...(vendorDetails?.[0]?.poit_id
            ? { poit_id: vendorDetails?.[0]?.poit_id }
            : { poit_id: "" }),
          poit_order_date: vendorDetails?.[0]?.poit_order_date
            ? moment(vendorDetails?.[0]?.poit_order_date).format("YYYY-MM-DD")
            : moment(new Date()).format("YYYY-MM-DD"),
          poit_document_date: vendorDetails?.[0]?.poit_document_date
            ? moment(vendorDetails?.[0]?.poit_document_date).format(
                "YYYY-MM-DD"
              )
            : moment(new Date()).format("YYYY-MM-DD"),
          poit_delivery_date: vendorDetails?.[0]?.poit_delivery_date
            ? moment(vendorDetails?.[0]?.poit_delivery_date).format(
                "YYYY-MM-DD"
              )
            : moment(new Date()).format("YYYY-MM-DD"),
          data: [
            ...orderDetail?.map((item, index) => ({
              sl_no: index + 1,
              item_name: item?.item_name,
              item_generic_name: item?.item_generic_name,
              item_code: item?.item_code,
              item_uom: item?.item_uom,
              item_batches:
                type === "purchase"
                  ? []
                  : item?.itemBatchData?.map((batch) => ({
                      item_sellable_quantity: batch?.item_sellable_quantity,
                      item_batch_number: batch?.item_batch_number,
                      item_exp_date: moment(batch?.item_exp_date).format(
                        "YYYY-MM-DD"
                      ),
                      item_id: batch?.item_id,
                    })) ?? [],
              item_unit_price: item?.item_unit_price ?? 0,
              item_invoice_open_quantity: item?.item_to_be_received ?? 0,
              quantity:
                type === "purchase"
                  ? 0
                  : page === "view"
                  ? item?.item_quantity
                  : item?.item_to_be_received,
              item_total_amount:
                type === "purchase" ? 0 : item?.item_total_amount ?? 0,
              item_id: item?.item_id,
              pot_id: item?.pot_id ?? null,
              gort_id: item?.gort_id ?? null,
              item_tax_amount: parseFloat(item?.item_tax_amount),
              item_gst: parseFloat(item?.item_gst),
              item_sgst: parseFloat(item?.item_sgst),
              item_cgst: parseFloat(item?.item_cgst),
              item_uom_id: item?.item_uom_id,
            })),
            ...(type === "purchase" || type === "invoice"
              ? [{ ...initialData }]
              : []),
          ],
        };

  const [initialValue, setInitialValue] = useState(initialFormValues);
  const [reload, setReload] = useState(false);
  const [addbatch, setAddbatch] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [storeId, setStoreId] = useState("");
  const [storeDetails, setStoreDetails] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    cmr_code: Yup.object().required("Customer code number is required"),
    cmr_name: Yup.object().required("Customer name is required"),
  });
  useEffect(() => {
    dispatch(storeIdAction(onSuccessStore, onErrorStore));
    dispatch(storeDetailsAction(onSuccessStoreDetails, onErrorStoreDetails));
  }, []);
  const onSuccessStore = (data) => {
    setStoreId(data?.data?.store_id);
    setReload(!reload);
  };
  const onErrorStore = () => {};
  const onSuccessStoreDetails = (data) => {
    setStoreDetails(data?.data);
  };
  const onErrorStoreDetails = () => {};
  const handleSubmit = (values, actions) => {
    const missingUOM = values?.data
      ?.slice(0, -1)
      .some((item) => !item.item_uom);
    if (page === "edit") {
      setButtonLoading(true);

      dispatch(
        purchaseInvoiceEditAction(
          {
            poit_id: values?.poit_id,
            remarks: values?.remarks,
          },
          onSuccessOrder,
          onErrorOrder
        )
      );
    } else {
      if (
        values.data
          ?.slice(0, -1)
          ?.some((item) => item.item_batches.length === 0)
      ) {
        errorToast("Please add batches for all items");
      } else if (
        values?.data
          ?.slice(0, -1)
          .some((item) => parseInt(item.quantity) === 0 || item.quantity === "")
      ) {
        errorToast("Quantity cannot be zero");
      } else if (missingUOM) {
        errorToast("Please add UOM for all items");
      } else {
        if (values?.data?.length > 1 || type === "goods") {
          setButtonLoading(true);

          dispatch(
            purchaseInvoiceReceiptCreateAction(
              {
                orderData: {
                  store_id: storeId,
                  ...(values?.poit_id ? { poit_id: values?.poit_id } : {}),
                  poit_total_gst: Math.round(
                    (type === "purchase" || type === "invoice"
                      ? values.data?.slice(0, -1)
                      : values.data
                    ).reduce(
                      (total, item) =>
                        total + parseFloat(item?.item_tax_amount),
                      0
                    )
                  ),
                  poit_total_discount: values?.poit_total_discount,
                  poit_payment_status: "paid",
                  poit_transaction_id: "",
                  poit_billing_address: "",
                  poit_total_amount: parseFloat(calculateTotal(values?.data)),
                  //  +
                  //   calculateTotalTax(values?.data)

                  poit_sub_total: parseFloat(calculateSubtotal(values?.data)),
                  cmr_code: values?.cmr_code?.label,
                  cmr_id: values?.cmr_id,
                  cmr_name: values?.cmr_name?.label,
                  remarks: values?.remarks,
                  poit_order_date: values?.poit_order_date,
                  poit_document_date: values?.poit_document_date,
                  poit_delivery_date: values?.poit_delivery_date,
                },
                itemData: (type === "purchase" || type === "invoice"
                  ? values.data?.slice(0, -1)
                  : values.data
                ).map((item, index) => ({
                  item_code: item?.item_code,
                  item_id: item?.item_id,
                  item_generic_name: item?.item_generic_name,
                  item_name: item?.item_name,
                  item_uom: item?.item_uom,
                  item_unit_price: parseFloat(item?.item_unit_price),
                  item_quantity: parseInt(item?.quantity),
                  // item_received_quantity: parseInt(item?.quantity),
                  item_total_amount:
                    parseFloat(item.item_unit_price) * parseInt(item.quantity),
                  //  +
                  // parseFloat(item.item_tax_amount),
                  item_tax_amount: item?.item_tax_amount ?? 0,
                  item_gst: item?.item_gst,
                  item_cgst: item?.item_cgst,
                  item_sgst: item?.item_sgst,
                  ...(item?.pot_id && type === "purchase"
                    ? { pot_id: item?.pot_id }
                    : { pot_id: null }),
                  ...(item?.gort_id && type === "goods"
                    ? { gort_id: item?.gort_id }
                    : { gort_id: null }),
                  item_uom_id: item?.item_uom_id ?? "",
                })),
                itemBatchData: (type === "purchase" || type === "invoice"
                  ? values.data?.slice(0, -1)
                  : values.data
                )
                  .map((item) => item.item_batches)
                  .flat(),
              },
              onSuccessOrder,
              onErrorOrder
            )
          );
        }
      }
    }
  };
  const downloadPdf = async (data) => {
    const fileName = "test.pdf";
    try {
      const blob = await pdf(
        <PurchaseInvoicePdfContent data={data} storeDetails={storeDetails} />
      ).toBlob();
      saveAs(blob, fileName);
    } catch (error) {}
  };
  const onSuccessOrder = (data) => {
    setButtonLoading(false);

    navigate("/purchase-invoice");
    if (formValues?.submittedButton === "print") {
      downloadPdf(data?.data);
    }
    successToast(data?.message);
    setInitialValue({
      cmr_code: "",
      cmr_name: "",
      remarks: "",
      poit_total_discount: 0,
      poit_order_date: null,
      poit_document_date: null,
      poit_delivery_date: null,
      data: [initialData],
    });
  };
  const onErrorOrder = (data) => {
    setButtonLoading(false);

    errorToast(data?.data?.message);
  };

  useHotkeys("escape", () => {
    setAddbatch(false);
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            handleSubmit(values, actions); // Pass values and actions if needed
            actions.setSubmitting(false); // Notify Formik that submission is done
          }, 500);
        }}
      >
        {({ values, setFieldValue }) => {
          setFormValues(values);
          return (
            <Form className="h-full">
              <div className="flex flex-col h-full ">
                <div class="flex flex-wrap justify-between first-div px-3">
                  <SalesPurchaseHeader
                    section="purchase"
                    name="poit"
                    disabled={disabled}
                    searchRef={searchRef}
                  />
                </div>
                {addbatch && (
                  <BatchQuantityExpiryPopoverPurINS
                    setItemId={setItemId}
                    itemId={itemId}
                    setAddbatch={setAddbatch}
                  />
                )}
                <div
                  className="flex flex-col "
                  style={{
                    maxHeight: `calc(100% - ${heightOfFirstDiv}px)`,
                    height: `calc(100% - ${heightOfFirstDiv}px)`,
                  }}
                >
                  <PurchaseInvoiceBillingTable
                    disabled={disabled || type === "goods"}
                    addBatchFn={(id) => {
                      setAddbatch(true);
                      setItemId(id);
                    }}
                    searchRef={searchRef}
                    initialData={initialData}
                  />
                  <div className="h-[15rem] mt-auto">
                    <SalesPurchaseFinalBillTable
                      buttonLoading={buttonLoading}
                      name="poit_total_discount"
                      page={page}
                      disabled={disabled}
                      printFn={() => downloadPdf(data)}
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateEditViewPurchaseInvoice;
