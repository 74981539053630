import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import InputField from "../../forms/input-field";
import * as Yup from "yup";
import { Field, Formik, useFormikContext } from "formik";
import CloseIcon from "../../../assets/icons/close-icon";
import {
  uomGroupFindOneAction,
  uomGroupListAction,
} from "../../../redux/actions/general-action";
import { useDispatch } from "react-redux";
import errorToast from "../../helper-components/error-toast";
import CheckBox from "../../forms/check-box-field";
import {
  productBarcodeAction,
  productBarcodesAction,
} from "../../../redux/actions/product-action";
import AddButton, { DraftButton } from "../../buttons/button";
import SelectField from "../../forms/select-field";
import successToast from "../../helper-components/success-toast";

const BarcodeModal = ({
  isModalOpen,
  setIsModalOpen,
  product,
  disabled = false,
}) => {
  const init = {
    item_code: product?.item_code,
    item_name: product?.item_name,
    uom_group: "",
    iugt_id: null,
    uomList: [].map((item, index) => ({
      uom_name: item.base_uom?.name,
      uom_id: item?.base_uom?.uom_id,
      iut_id: null,
      barcode: Array.from({ length: 7 }, () => ({
        item_barcode: "",
        free_text: "",
        iubt: null,
        is_default: false,
      })),
    })),
  };
  const [selectedUomId, setselectedUomId] = useState("");
  const { setFieldValue } = useFormikContext();
  const [uomGroupList, setUomGroupList] = useState([]);
  const [itemBarcode, setItemBarcode] = useState({});
  const [barcodeIndex, setBarcodeIndex] = useState(null);
  const [uomGroupFindOneList, setUomGroupFindOneList] = useState([]);
  const [initialValues, setinitialValues] = useState(init);
  const validationSchema = Yup.object().shape({});
  const uomIndex = initialValues.uomList.findIndex(
    (uom) => uom.uom_id === selectedUomId
  );
  const dispatch = useDispatch();
  function updateInitValFn(init, itemBarcode) {
    console.log(itemBarcode, "fdsfdssdfsdf");
    if (itemBarcode && itemBarcode.uomList) {
      const updatedUomList = init.uomList.map((initUom) => {
        const newUom = itemBarcode.uomList.find(
          (barcodeUom) => barcodeUom.uom_id === initUom.uom_id
        );
        if (newUom) {
          const updatedBarcodes = newUom.barcode.map((barcodeItem) => ({
            item_barcode: barcodeItem.item_barcode || "",
            iubt: barcodeItem.iubt || null,
            free_text: barcodeItem.free_text || "",
            is_default: barcodeItem.is_default || false,
          }));
          while (updatedBarcodes.length < 7) {
            updatedBarcodes.push({
              item_barcode: "",
              free_text: "",
              is_default: false,
            });
          }

          return {
            ...initUom,
            iut_id: newUom?.iut_id || null,
            barcode: updatedBarcodes,
          };
        }
        return initUom;
      });

      setinitialValues({
        ...init,
        iugt_id: itemBarcode.iugt_id || null,
        uomList: updatedUomList,
      });
    } else {
      setinitialValues({ ...init, iugt_id: itemBarcode.iugt_id || null });
    }
  }
  function uomGroupFindOneFn(selectedoption, itemBarcode) {
    dispatch(
      uomGroupFindOneAction(
        selectedoption?.value,
        (data) => {
          setUomGroupFindOneList(data?.data);
          const init = {
            item_code: product?.item_code,
            item_name: product?.item_name,
            uom_group: data?.data?.[0]?.group_id,
            uomList: data?.data?.map((item, index) => ({
              uom_name: item.base_uom?.name,
              uom_id: item?.base_uom?.uom_id,
              barcode: Array.from({ length: 7 }, () => ({
                item_barcode: "",
                free_text: "",
                is_default: false,
              })),
            })),
          };
          updateInitValFn(init, itemBarcode);
        },
        onUomFindoneError
      )
    );
  }

  const onUomFindoneError = (data) => {
    errorToast(data?.message);
  };

  useEffect(() => {
    if (isModalOpen) {
      dispatch(uomGroupListAction(onUomGrpSuccess, onUomGrpError));
      dispatch(
        productBarcodesAction(product?.item_id, onBarSuccess, onBarError)
      );
    }
  }, [isModalOpen]);

  const onUomGrpSuccess = (data) => {
    setUomGroupList(
      data?.data?.map((item) => {
        return { label: item?.name, value: item?.group_id };
      })
    );
  };
  const onUomGrpError = (data) => {
    errorToast(data?.data?.message);
  };

  const onBarSuccess = (data) => {
    setItemBarcode(data?.data);
    uomGroupFindOneFn({ value: data?.data?.uom_group_id }, data?.data);
  };
  console.log(itemBarcode, "fsdfdsfsdfs");
  const onBarError = (data) => {};

  const innerOnSubmit = (values) => {
    const filteredUomList = values.uomList
      .filter((uom) =>
        uom.barcode.some((barcode) => barcode.item_barcode !== "")
      )
      .map((uom) => ({
        ...uom,
        barcode: uom.barcode.filter((barcode) => barcode.item_barcode !== ""),
      }));
    dispatch(
      productBarcodeAction(
        {
          item_id: product?.item_id,
          uom_group_name: uomGroupList?.find(
            (item) => item?.value === uomGroupFindOneList?.[0]?.group_id
          )?.label,
          uom_group_id: uomGroupFindOneList?.[0]?.group_id,
          iugt_id: values?.iugt_id || null,
          uomList: filteredUomList,
        },
        onBarcodeSuccess,
        onBarcodeError
      )
    );
  };
  const onBarcodeSuccess = (data) => {
    setIsModalOpen(false);
    successToast(data?.data?.message);
  };

  const onBarcodeError = (data) => {
    errorToast(data?.message);
  };
  return (
    <Transition show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => setIsModalOpen(false)}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
          </Transition.Child>

          {/* This is your modal container */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div className="mx-auto w-full  max-w-4xl my-8  text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={innerOnSubmit}
              >
                {({ handleSubmit, values }) => {
                  return (
                    <>
                      <div className="w-full">
                        <div className="flex justify-between items-start w-full px-6 pt-8">
                          <div className="w-[50%]">
                            <InputField
                              isDisabled={true}
                              type="text"
                              name="item_code"
                              placeholder="item code"
                              label="Item Code"
                              isRequired={false}
                              labelWidth="7rem"
                            />
                            <InputField
                              isDisabled={true}
                              type="text"
                              name="item_name"
                              placeholder="product name"
                              label="Product Name"
                              isRequired={false}
                              labelWidth="7rem"
                            />
                          </div>

                          <button
                            className="close-button bg-transparent border-none p-0 hover:bg-787878"
                            onClick={() => setIsModalOpen(false)}
                          >
                            <CloseIcon />
                          </button>
                        </div>
                        <hr />
                        <div className="grid grid-cols-2 pb-5 pt-3 px-6 gap-4">
                          <div>
                            <h2 className="text-secondary fw_600 text-sm">
                              Setup
                            </h2>
                            <div className="w-[70%]">
                              <SelectField
                                isDisabled={disabled}
                                handleSelected={(selectedoption) =>
                                  uomGroupFindOneFn(selectedoption, {
                                    iugt_id: itemBarcode?.iugt_id || null,
                                  })
                                }
                                isFormikControlled={true}
                                menuPosition="relative"
                                placeholder="Select item"
                                options={uomGroupList}
                                label="uom group"
                                id="uom_group"
                                name="uom_group"
                                isSearchable={true}
                                errMsgContainerclassName="ps-2"
                                isRequired={false}
                                labelWidth="7rem"
                              />
                            </div>
                            <table className="w-[90%] border-D0D5DD border border-1 rounded-lg">
                              <thead>
                                <tr className="bg-F2F4F5">
                                  <th className="py-2 px-3  text-secondary fw_400 text-sm">
                                    UOM
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {values?.uomList.map((item, index) => (
                                  <tr key={index}>
                                    <td
                                      style={{
                                        padding: "2px",
                                      }}
                                      className="border-D0D5DD border border-1 text-secondary fw_400 text-sm"
                                    >
                                      <input
                                        type="radio"
                                        name="uomSelection"
                                        value={item.uom_name}
                                        onChange={(e) =>
                                          setselectedUomId(item?.uom_id)
                                        }
                                        id={`uomRadio-${index}`}
                                        className="peer hidden"
                                      />
                                      <label
                                        htmlFor={`uomRadio-${index}`}
                                        className="block cursor-pointer select-none rounded-md p-1 text-center hover:bg-slate-100 peer-checked:bg-blue-100 peer-checked:font-bold peer-checked:outline-none peer-checked:ring-2 peer-checked:ring-blue-500"
                                      >
                                        {item.uom_name}
                                      </label>
                                    </td>
                                  </tr>
                                ))}
                                {[
                                  ...Array(
                                    Math.max(7 - uomGroupFindOneList.length, 0)
                                  ),
                                ].map((_, index) => (
                                  <tr key={uomGroupFindOneList.length + index}>
                                    <td className="border-D0D5DD border border-1 py-5 px-4 text-secondary fw_400 text-sm"></td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="">
                            <h2 className="text-secondary fw_600 text-sm">
                              Bar Code
                            </h2>
                            <div
                              className="flex items-center"
                              style={{
                                height: "4.6rem",
                              }}
                            >
                              <CheckBox
                                isDisabled={disabled}
                                onChange={(name) => {
                                  if (
                                    name ===
                                    `uomList[${uomIndex}].barcode[${barcodeIndex}].is_default`
                                  ) {
                                    values?.uomList[uomIndex].barcode.forEach(
                                      (barcode, index) => {
                                        if (index !== barcodeIndex) {
                                          setFieldValue(
                                            `uomList[${uomIndex}].barcode[${index}].is_default`,
                                            false
                                          );
                                          barcode.is_default = false;
                                        }
                                      }
                                    );
                                  }
                                }}
                                items={[
                                  {
                                    label: "Set as default",
                                    name: `uomList[${uomIndex}].barcode[${barcodeIndex}].is_default`,
                                    isRequired: false,
                                  },
                                ]}
                              />
                            </div>
                            <div className="w-full mx-auto">
                              <table
                                style={{
                                  width: "100%",
                                }}
                                className=" border-D0D5DD border border-1 rounded-lg text-secondary fw_400 text-sm"
                              >
                                <thead>
                                  <tr className="bg-F2F4F5">
                                    <th className="py-2 border-D0D5DD border border-1 text-secondary fw_400 text-sm px-3">
                                      #
                                    </th>
                                    <th className="py-2 border-D0D5DD border border-1 text-secondary fw_400 text-sm px-3">
                                      Barcode
                                    </th>
                                    <th className="py-2 border-D0D5DD border border-1 text-secondary fw_400 text-sm px-3">
                                      Free text
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {values?.uomList
                                    .find(
                                      (item) => item.uom_id === selectedUomId
                                    )
                                    ?.barcode?.map((item, index) => {
                                      const uomIndex = values.uomList.findIndex(
                                        (uom) => uom.uom_id === selectedUomId
                                      );
                                      return (
                                        <tr key={index}>
                                          <td className="border-D0D5DD border border-1 py-2 px-4">
                                            {index + 1}
                                          </td>
                                          <td className="border-D0D5DD border border-1 py-2 ">
                                            <Field
                                              name={`uomList[${uomIndex}].barcode[${index}].item_barcode`}
                                            >
                                              {({ field, meta, form }) => (
                                                <input
                                                  disabled={disabled}
                                                  onFocus={() =>
                                                    setBarcodeIndex(index)
                                                  }
                                                  {...field}
                                                  type="text"
                                                  className={`${
                                                    values?.uomList?.[uomIndex]
                                                      ?.barcode?.[index]
                                                      ?.is_default
                                                      ? "text-secondary fw_700"
                                                      : ""
                                                  } px-2 w-full focus_outline__none `}
                                                  onChange={(e) => {
                                                    form.setFieldValue(
                                                      field.name,
                                                      e.target.value
                                                    );
                                                    setBarcodeIndex(index);
                                                    form.setFieldValue(
                                                      field.name.replace(
                                                        ".item_barcode",
                                                        ".is_default"
                                                      ),
                                                      true
                                                    );
                                                    values.uomList[
                                                      uomIndex
                                                    ].barcode.forEach(
                                                      (barcode, idx) => {
                                                        if (idx !== index) {
                                                          form.setFieldValue(
                                                            `uomList[${uomIndex}].barcode[${idx}].is_default`,
                                                            false
                                                          );
                                                        }
                                                      }
                                                    );
                                                  }}
                                                />
                                              )}
                                            </Field>
                                          </td>
                                          <td className="border-D0D5DD border border-1 py-2 px-4">
                                            <Field
                                              disabled={disabled}
                                              type="text"
                                              className="px-2 w-full focus_outline__none"
                                              name={`uomList[${uomIndex}].barcode[${index}].free_text`}
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        {!disabled && (
                          <div className="px-6 flex gap-4 py-5">
                            <DraftButton
                              onclkFn={() => setIsModalOpen(false)}
                              text="Cancel"
                            />
                            <AddButton
                              isDisabled={
                                values.uomList
                                  .filter((uom) =>
                                    uom.barcode.some(
                                      (barcode) => barcode.item_barcode !== ""
                                    )
                                  )
                                  .map((uom) => ({
                                    ...uom,
                                    barcode: uom.barcode.filter(
                                      (barcode) => barcode.item_barcode !== ""
                                    ),
                                  }))?.length > 0
                                  ? false
                                  : true
                              }
                              onclkFn={handleSubmit}
                              type="submit"
                              className="bg-primary text-white px-8 py-2 rounded flex justify-center fw_500 text-sm"
                              text="Done"
                              prefixIcon={false}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  );
                }}
              </Formik>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default BarcodeModal;
export const serialBatchesOptns = [
  { label: "Manual", value: "manual" },
  { label: "Each", value: "each" },
];
