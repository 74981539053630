import React, { useCallback, useEffect, useRef, useState } from "react";
import ClearIcon from "../../assets/icons/clear-icon";
import FilterIcon from "../../assets/icons/filter-icon";
import SelectOption from "../helper-components/select-option";
import search from "../../assets/icons/searchIcon.svg";
import AddButton from "../buttons/button";
import Select from "react-select";
import { customStyles } from "../tables/filtering-section";
import SalesPurchaseFilter from "./sales-purchase-filter";
import { useHotkeys } from "react-hotkeys-hook";
import { capitalizeWords } from "../helper-components/helper-function";

const SalesPurchaseTableHeader = ({
  filterData = {},
  setFilterData = () => {},
  initialFilter,
  module = "sales_order",
  tableHeading = [],
  options = [],
  handleSelect = () => {},
  paginationProps = {},
  invoiceItems,
  permissionFn = () => {},
  newFn = () => {},
  setCurrentPage,
  usePropFiltComp = false,
  FilterComponent = () => {},
}) => {
  const filterPopupRef = useRef(null);
  const [showFilter, setShowFilter] = useState(false);
  const searchRef = useRef();
  const [isFocused, setIsFocused] = useState(false);
  const handleSelectChange = (event) => {
    setFilterData({ ...filterData, selectColumn: event?.value });
  };
  const handleFilterClick = useCallback(() => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  }, []);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        showFilter &&
        filterPopupRef.current &&
        !filterPopupRef.current.contains(event.target)
      ) {
        handleFilterClick();
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleFilterClick, showFilter]);
  useHotkeys("escape", () => {
    setShowFilter(false);
  });
  return (
    <div className="flex items-center gap-2   flex-wrap justify-between pt-3 px-4">
      <div
        style={{ maxWidth: "30rem", height: "2.6rem" }}
        className={`w-full  flex items-center rounded-md   ${
          isFocused ? "border border-primary" : "input_border"
        }`}
      >
        <Select
          menuPortalTarget={document.body}
          styles={customStyles}
          value={
            filterData?.selectColumn
              ? tableHeading?.find(
                  (item) => item?.value === filterData?.selectColumn
                )
                ? {
                    ...tableHeading?.find(
                      (item) => item?.value === filterData?.selectColumn
                    ),
                    label: capitalizeWords(
                      tableHeading?.find(
                        (item) => item?.value === filterData?.selectColumn
                      )?.label
                    ),
                    // Capitalize first letter of each word
                  }
                : null
              : null
          }
          onChange={(e) => {
            handleSelectChange(e);
            setIsFocused(true);
            if (searchRef && searchRef?.current) {
              searchRef?.current?.focus();
            }
          }}
          options={tableHeading
            ?.filter((item) => item?.column)
            ?.map((item) => ({
              ...item,
              label: capitalizeWords(item?.label),
              // Capitalize first letter
            }))}
          placeholder="Select Column"
          isSearchable={false}
          className="text-xs placeholder:text-xs"
        />{" "}
        <div
          className={`h-full w-0.5 ${
            isFocused ? "border-r border-r-primary" : "border-r border-r-D0D5DD"
          }`}
        ></div>{" "}
        <input
          ref={searchRef}
          autoFocus={true}
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
          placeholder={`Search`}
          className=" table_search_input w-full rounded-md text-xs h-full placeholder:text-text-xs placeholder:text-787878 pl-3"
          type="text"
          value={filterData?.inputValue}
          onChange={(e) => {
            setFilterData({
              ...filterData,
              inputValue: e.target.value,
            });
            paginationProps.setCurrentPage(1);
          }}
        />
        <span className="px-2">
          <img
            style={{
              height: "25px",
              width: "25px",
            }}
            src={search}
            alt="search icon"
          />
        </span>
      </div>
      <div className={"flex items-center gap-3"}>
        <div
          style={{
            width: "fit-content",
            height: "2.6rem",
          }}
          className="input_border rounded-md text-344054 fw_600 flex items-center md:flex-wrap px-2"
        >
          <div className="px-1 flex items-center text-xs">
            <span className="text-xs">From:</span>
            <input
              style={{
                width: "7rem",
              }}
              placeholder="DD-MM-YYYY"
              className=" table_search_input w-full rounded-md mr-4 ml-1 text-xs placeholder:text-text-sm"
              type="date"
              value={filterData?.from}
              onChange={(e) => {
                setFilterData({ ...filterData, from: e.target.value });
                paginationProps.setCurrentPage(1);
              }}
            />
            <span className="text-xs">To:</span>
            <input
              style={{
                width: "7rem",
              }}
              placeholder="DD-MM-YYYY"
              className=" table_search_input w-full rounded-md ml-1 text-xs placeholder:text-text-sm"
              type="date"
              value={filterData?.to}
              onChange={(e) => {
                setFilterData({ ...filterData, to: e.target.value });
                paginationProps.setCurrentPage(1);
              }}
            />
          </div>
        </div>
        <button
          style={{
            height: "2.6rem",
          }}
          onClick={() => {
            setFilterData(initialFilter);
            paginationProps.setCurrentPage(1);
            if (searchRef && searchRef?.current) {
              searchRef?.current?.focus();
            }
          }}
          className={`px-4 whitespace-nowrap text-sm  fw_600 rounded-md input_border flex items-center gap-2  h-full ${
            JSON.stringify(filterData) === JSON.stringify(initialFilter) &&
            "bg-disabled-color"
          }`}
        >
          <ClearIcon />
          Clear All
        </button>
        <div ref={filterPopupRef} className="relative">
          <button
            onClick={handleFilterClick}
            style={{
              height: "2.6rem",
            }}
            className="flex items-center whitespace-nowrap text-sm input_border rounded-md px-3 gap-2 text-344054 fw_600"
          >
            <FilterIcon />
            Filter
          </button>
          {showFilter && usePropFiltComp ? (
            FilterComponent()
          ) : showFilter ? (
            <SalesPurchaseFilter
              filterData={filterData}
              setFilterData={setFilterData}
              setCurrentPage={setCurrentPage}
            />
          ) : null}
        </div>
        {invoiceItems?.length > 0 && (
          <SelectOption options={options} handleSelect={handleSelect} />
        )}
        {permissionFn(module)?.create && (
          <AddButton onclkFn={newFn} text="New" />
        )}
      </div>
    </div>
  );
};

export default SalesPurchaseTableHeader;
