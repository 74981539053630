import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EditIcon from "../../../assets/icons/editIconPencil.svg";
import errorToast from "../../helper-components/error-toast";
import { Formik } from "formik";
import DocumentNumberingEditModal from "../../modals/settings/document-numbering-modal";
import { documentNumberingListAction } from "../../../redux/actions/settings-action";
import LoadingSpinner from "../../helper-components/loading-spinner";
import InnerLayout from "../../layouts/inner-layout";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { formatString } from "../../helper-components/helper-function";


const DocumentNumbering = () => {
    const tableHeading = ["Document", "Default Series", "Prefix", "Suffix", "First No.", "Last No.", "Increment", "Include Year", "Include Month", "Include Date", "Separotor", "Leading Spaces", ""];
    const [initialData, setDocumentSeriesData] = useState([]);
    const [editingData, setEditingData] = useState(null);
    const [title, setTitle] = useState('')
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const onCLickEdit = (id) => {
        setEditingData(id);
        setIsDrawerOpen(true); // Open the drawer
    };
    useEffect(() => {
        dispatch(documentNumberingListAction({ pageSize: 20, pageNumber: 1 }, onFetchSuccess, onFetchError));
        setLoading(true)
    }, []);


    const reloadData = () => {
        dispatch(documentNumberingListAction({ pageSize: 20, pageNumber: 1 }, onFetchSuccess, onFetchError));
    }

    const onFetchSuccess = (data) => {
        setDocumentSeriesData(data?.data);
        setLoading(false)
    }

    const onFetchError = (data) => {
        
        errorToast(data?.message)
    }

    return (
        <InnerLayout
            list={true}
            title="Settings"
            module="document-numbering-series"
        // permissionFn={permissionFn}
        >
            <>
                <div className='flex justify-between pt-3'>
                    <div className="pl-2 pb-4 flex items-center gap-2">
                        <button
                            className="flex items-center gap-2"
                            onClick={() => {
                                navigate('/settings')
                            }}
                        >
                            <ChevronLeftIcon className="h-5 w-5 inline-block" />
                            Settings /
                        </button>
                        <span className="cursor-pointer" >{" " + "Document numbering series"}</span>
                    </div>
                </div>
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <Formik
                            enableReinitialize={true}
                            initialValues={{ data: initialData }}
                        >
                            {({ values }) => (
                                <div className="pt-1 pl-2">
                                    <div
                                        className="flex flex-col justify-between"
                                        style={{ height: "80vh" }}
                                    >
                                        <div className="overflow-auto h-full">
                                            <table className="mt-4 w-full">
                                                <thead>
                                                    <tr>
                                                        {tableHeading.map((heading, index) => (
                                                            <th
                                                                className={`tab-header whitespace-nowrap text-sm fw_500 text-475467 bg-F2F4F5 text-start`}
                                                                key={heading}
                                                            >
                                                                {heading}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody style={{ position: "relative" }}>
                                                    {values?.data?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className={`tab-cell text-sm fw_400 text-secondary w-36`}>
                                                                {formatString(item?.transaction_document_name)}
                                                            </td>
                                                            <td className={`tab-cell text-sm fw_400 text-secondary`}>
                                                                {formatString(item?.series_name)}
                                                            </td>
                                                            <td className={`tab-cell text-sm fw_400 text-secondary w-26`}>
                                                                {item?.series_prefix}
                                                            </td>
                                                            <td className={`tab-cell text-sm fw_400 text-secondary w-26`}>
                                                                {item?.series_suffix}
                                                            </td>
                                                            <td className={`tab-cell text-sm fw_400 text-secondary w-26 text-center`}>
                                                                {item?.series_first_digit}
                                                            </td>
                                                            <td className={`tab-cell text-sm fw_400 text-secondary w-26 text-center`}>
                                                                {item?.series_last_digit}
                                                            </td>
                                                            <td className={`tab-cell text-sm fw_400 text-secondary w-26 text-center`}>
                                                                {item?.series_increment}
                                                            </td>
                                                            <td className={`tab-cell text-sm fw_400 text-secondary w-26`}>
                                                                <input type="checkbox" className="mx-5 border-[1px] focus:outline-none h-[18px] w-[18px] rounded-[6px] px-1 py-2" checked={item?.series_include_year} readOnly />
                                                            </td>
                                                            <td className={`tab-cell text-sm fw_400 text-secondary w-26`}>
                                                                <input type="checkbox" className="mx-5 border-[1px] focus:outline-none h-[18px] w-[18px] rounded-[6px] px-1 py-2" checked={item?.series_include_month} readOnly />
                                                            </td>
                                                            <td className={`tab-cell text-sm fw_400 text-secondary w-26`}>
                                                                <input type="checkbox" className="mx-5 border-[1px] focus:outline-none h-[18px] w-[18px] rounded-[6px] px-1 py-2" checked={item?.series_include_date} readOnly />
                                                            </td>
                                                            <td className={`tab-cell text-sm fw_400 text-secondary w-26 text-center`}>
                                                                {item?.series_separator}
                                                            </td>
                                                            <td className={`tab-cell text-sm fw_400 text-secondary w-26 text-center`}>
                                                                {item?.series_leading_spaces}
                                                            </td>
                                                            <td className="tab-cell text-sm fw_400 text-secondary p-8">
                                                                <button onClick={() => { onCLickEdit(item?.transaction_doc_id); setTitle(item?.transaction_document_name) }}>
                                                                    <img src={EditIcon} alt="Edit Icon" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Formik>
                        <DocumentNumberingEditModal isModalOpen={isDrawerOpen} setIsModalOpen={() => setIsDrawerOpen(!isDrawerOpen)} docId={editingData} title={title} reloadData={reloadData} />
                    </>
                )
                }
            </>
        </InnerLayout>

    );
};

export default DocumentNumbering;
