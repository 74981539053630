import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SortingIcons from "../../assets/icons/arrowup-down";
import { invoiceRowAction } from "../../redux/actions/invoices-action";
import AddButton from "../buttons/button";
import Pagination from "./pagination";
import "./table.css";
import { useNavigate } from "react-router-dom";
const PastOrderDynamicTable = ({
  tableHeading,
  tableBodyData = [],
  paginationProps,
  filterData,
  setFilterData,
  pastorders,
  label = "Invoice",
  id = "",
}) => {
  const [checkedIndexes, setCheckedIndexes] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function sortFn(item) {
    let direction = "ASC";
    if (
      filterData != null &&
      filterData?.sort_by === item &&
      filterData?.sort_order === "ASC"
    ) {
      direction = "DESC";
    }
    setFilterData({ ...filterData, sort_by: item, sort_order: direction });
  }

  const rowData = useSelector((state) => state.invoiceRowReducer);

  function checkboxChecker(index, all) {
    if (all === "all" && checkedIndexes.length === tableBodyData.length) {
      setCheckedIndexes([]);
    } else if (all === "all") {
      setCheckedIndexes(
        Array.from({ length: tableBodyData.length }, (_, i) => i)
      );
    } else {
      if (checkedIndexes.includes(index)) {
        setCheckedIndexes((prevIndexes) =>
          prevIndexes.filter((item) => item !== index)
        );
      } else {
        setCheckedIndexes((prevIndexes) => [...prevIndexes, index]);
      }
    }
  }

  return (
    <>
      <div className="flex justify-between pt-5 px-5">
        <div className="flex gap-5">
          {pastorders?.[0]?.cmr_first_name && (
            <div>
              <span className="text-000000 fw_400 text-sm">Name : </span>
              <span className="fw_600 text-sm">
                {pastorders?.[0]?.cmr_first_name +
                  " " +
                  pastorders?.[0]?.cmr_last_name}
              </span>
            </div>
          )}
          {pastorders?.[0]?.cmr_phone_number && (
            <div>
              <span className="text-000000 fw_400 text-sm">Mobile : </span>
              <span className="fw_600 text-sm">
                {pastorders?.[0]?.cmr_phone_number}
              </span>
            </div>
          )}
        </div>
        <div>
          <AddButton
            onclkFn={() => {
              dispatch(
                invoiceRowAction({
                  ...rowData,
                  phone: id ? { label: id, value: id } : null,
                  data: checkedIndexes.map((index) => {
                    const item = pastorders[index];
                    return {
                      sl_no: 1,
                      item_code: item.item_code,
                      item_generic_name: item?.item_generic_name,
                      item_name: item.item_name,
                      total_sellable_quantity: item?.total_sellable_quantity,
                      quantity: 0,
                      item_uom: item.item_uom,
                      item_batch_number: "",
                      item_exp_date: "",
                      item_mfg_date: item.item_mfg_date,
                      item_rack_location: item.item_rack_location,
                      item_schedule: item.item_schedule,
                      item_unit_price: item?.item_unit_price,
                      item_price_wiithout_tax: 0,
                      item_discount_amount: 0,
                      item_discount_percentage: 0,
                      item_tax_amount: 0,
                      item_total_amount: 0,
                      item_id: item?.item_id,
                      instock: true,
                    };
                  }),
                })
              );
              navigate(-1);
            }}
            prefixIcon={false}
            text="Add to order"
            type="submit"
          />
        </div>
      </div>
      <div className="flex flex-col justify-between h-full">
        <div className="tab-container">
          <table className="my-tab h-full">
            <thead>
              <tr>
                <th
                  className={`whitespace-nowrap fs_12 fw_500 text-475467 bg-F2F4F5 tab-header-past-orders`}
                >
                  <input
                    checked={
                      checkedIndexes.length === tableBodyData.length &&
                      checkedIndexes.length > 0
                    }
                    onChange={() => checkboxChecker(null, "all")}
                    style={{ transform: "scale(1.1)" }}
                    type="checkbox"
                    id="myCheckbox"
                    className="appearance-none sap w-6 h-6 border-2 border-787878 relative transition-all duration-300 ease-in-out cursor-pointer checked:border-[#7F56D9] rounded-md"
                  />
                </th>
                {tableHeading.map((heading, index) => (
                  <th
                    key={index}
                    className={`${"tab-header"} whitespace-nowrap fs_12 fw_500 text-475467 bg-F2F4F5`}
                  >
                    <span
                      className={`flex items-center gap-2 ${heading?.className}`}
                    >
                      {heading?.label}
                      {heading?.display && (
                        <button onClick={() => sortFn(heading?.value)}>
                          {" "}
                          <SortingIcons
                            inc={
                              filterData?.sort_order === "ASC" &&
                              heading?.value === filterData?.sort_by
                                ? "#475467"
                                : "#9A9FA6"
                            }
                            dec={
                              filterData?.sort_order === "DESC" &&
                              heading?.value === filterData?.sort_by
                                ? "#475467"
                                : "#9A9FA6"
                            }
                          />
                        </button>
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="h-full">
              {tableBodyData?.length > 0 ? (
                <>
                  {tableBodyData.map((rowData, rowIndex) => (
                    <tr
                      className={`${
                        checkedIndexes.includes(rowIndex) ? "bg-C4EAB7" : ""
                      }`}
                      key={rowIndex}
                    >
                      <td className="text-sm tab-cell-past-orders fw_400 text-secondary">
                        <input
                          checked={checkedIndexes.includes(rowIndex)}
                          onChange={() => checkboxChecker(rowIndex)}
                          type="checkbox"
                          className="checkBoxShortcut appearance-none sap w-6 h-6 border-2 border-787878 relative transition-all duration-300 ease-in-out cursor-pointer checked:border-[#7F56D9] rounded-md"
                        />
                      </td>
                      {Object.values(rowData).map((cellData, cellIndex) => (
                        <>
                          {cellIndex !== 0 && (
                            <td
                              key={cellIndex}
                              className={`tab-cell-past-orders text-sm fw_400 text-secondary`}
                            >
                              {cellData}
                            </td>
                          )}
                        </>
                      ))}
                    </tr>
                  ))}
                  <tr style={{ height: "10rem" }}></tr>
                </>
              ) : (
                <tr
                  style={{
                    height: "20rem",
                  }}
                >
                  <td
                    colSpan={tableHeading.length + 1}
                    className="text-center text-primary"
                  >
                    No {label + "s"} found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination paginationProps={paginationProps} />
      </div>
    </>
  );
};

export default PastOrderDynamicTable;
