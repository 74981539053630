import { Field, useFormikContext } from "formik";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "../../assets/icons/delete-icon";
import { productListAction } from "../../redux/actions/general-action";
import { url } from "../../redux/config";
import "../forms/form.css";
import SearchAddFeild from "../forms/search-add-field";
import errorToast from "../helper-components/error-toast";
import BatchNumberEdit from "../modals/invoice-modals/edit-batch-modal";
import "./invoice.css";
import { api } from "../../redux/apis/api";
import useBarcodeScanner, {
  formatString,
  truncateString,
} from "../helper-components/helper-function";
import { invoiceScannedItemAction } from "../../redux/actions/invoices-action";

const InvoiceBillingTable = ({
  initialData,
  disabled = false,
  setAlternativeID,
  searchRef,
  isbarcodeScanning,
  setIsbarcodeScanning,
  heightOfSecDiv = "",
}) => {
  const inputRefs = useRef(null);
  const { values, setFieldValue } = useFormikContext();
  const isPaymentDone = values?.paymentData?.some((item) => item?.transaction);
  const dispatch = useDispatch();
  const [localData, setLocalData] = useState([]);
  const tableHeading = [
    { label: "S No.", value: "sl_no" },
    { label: "Item code", value: "item_code" },
    { label: "Generic Name", value: "item_Generic_name" },
    { label: "Product Name", value: "item_product_name" },
    { label: "Avail. Qty", value: "total_sellable_quantity" },
    { label: "Qty", value: "item_quantity" },
    { label: "Free Qty", value: "item_free_quantity" },
    { label: "UOM", value: "item_uom" },
    { label: "Batch No.", value: "item_batch_no" },
    { label: "Exp. Date", value: "item_exp_date" },
    { label: "Loc.", value: "item_location" },
    { label: "Mfg By.", value: "item_mfg_by" },
    { label: "SCH", value: "item_schedule" },
    { label: "HSN", value: "item_hsn_code" },
    { label: "MRP", value: "item_price_wiithout_tax" },
    { label: "Price (₹)", value: "item_unit_price" },
    { label: "Disc. (₹)", value: "item_discount_amount" },
    { label: "Disc. (%)", value: "item_discount_percentage" },
    { label: "Tax (₹)", value: "item_tax_amount" },
    { label: "Final Price", value: "item_final_price" },
    { label: "Total (₹)", value: "item_total" },
    ...(disabled || isPaymentDone ? [] : [{ label: "", value: "" }]),
  ];
  const fetchData = async (inputValue, callback, field) => {
    const onSuccess = (data) => {
      const options =
        data?.data === null
          ? []
          : data.data
              .map((item, index) => ({
                value: item.item_id,
                label: (
                  <div>
                    {index === 0 && (
                      <div
                        className="px-7 py-2 text-sm bg-F2F4F5"
                        style={{
                          position: "sticky", // Make the header sticky
                          top: 0, // Stick to the top
                          zIndex: 10, // Ensure the header is above other content
                          display: "flex",
                          justifyContent: "space-between",
                          fontWeight: "bold",
                          borderBottom: "1px solid #ddd",
                          marginBottom: "5px",
                        }}
                      >
                        <span style={{ width: "40%" }}>
                          {formatString(field)}
                        </span>
                        <span style={{ width: "30%", textAlign: "center" }}>
                          Available Qty
                        </span>
                        <span style={{ width: "30%", textAlign: "center" }}>
                          Batches
                        </span>
                      </div>
                    )}
                    {/* Table Row */}
                    <div
                      className="text-sm px-7 py-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ width: "40%" }}>{item[field]}</span>
                      <span style={{ width: "30%", textAlign: "center" }}>
                        {item?.total_sellable_quantity ?? "N/A"}
                      </span>
                      <span style={{ width: "30%", textAlign: "center" }}>
                        {item?.batches?.length ?? "N/A"}
                      </span>
                    </div>
                  </div>
                ),
                item_name: item?.item_name,
                availableQty: item?.total_sellable_quantity,
                batches: item?.batches?.length,
              }))
              .filter(
                (option) =>
                  !values?.data?.some((item) => item?.item_id === option.value)
              );
      setLocalData(data.data);
      callback(options);
    };
    const onError = (data) => {};
    if (inputValue?.length > 0) {
      dispatch(
        productListAction(
          {
            field: field,
            value: inputValue,
          },
          onSuccess,
          onError
        )
      );
    }
  };
  const removeRow = (rowIndex) => {
    const updatedData = [...values.data];
    updatedData.splice(rowIndex, 1);
    setFieldValue("data", updatedData);
  };


  // const handleQuantityChange = async (event, rowIndex) => {
  //   const updatedData = values.data.map((row, index) =>
  //     index === rowIndex
  //       ? { ...row, quantity: parseFloat(event.target.value) }
  //       : row
  //   );
  //   setFieldValue("data", updatedData);
  //   const totalAmount =
  //     parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
  //     parseFloat(event.target.value);
  //   const item = values.data[rowIndex];
  //   const stockApiUrl = `${url}/v1/inventory/stock/items?item_id=${item.item_id}&item_quantity=${event.target.value}`;
  //   if (parseFloat(event?.target?.value) > 0) {
  //     try {
  //       const stockApiResponse = await api.get(stockApiUrl);
  //       const batchDetail = stockApiResponse?.data?.data?.itemDetails;
  //       if (
  //         stockApiResponse?.data?.code === 200 &&
  //         stockApiResponse?.data?.data?.itemDetails?.length > 0
  //       ) {
  //         try {
  //           const response = await api.get(
  //             `${url}/discount/get?totalAmountOfItem=${
  //               event.target.value *
  //               parseFloat(values?.data?.[rowIndex]?.item_unit_price)
  //             }&item_quantity=${event.target.value}&item_id=${
  //               values?.data?.[rowIndex]?.item_id
  //             }&cmr_phone_number=${values?.phone?.label}`,
  //             JSON.stringify(totalAmount)
  //           );

  //           for (
  //             let itemDetailIndex = 0;
  //             itemDetailIndex <
  //             stockApiResponse?.data?.data?.itemDetails?.length;
  //             itemDetailIndex++
  //           ) {
  //             const totalBatchQuantity = batchDetail.reduce(
  //               (sum, batch) => sum + batch.quantity,
  //               0
  //             );
  //             const proportion =
  //               batchDetail?.[itemDetailIndex]?.quantity / totalBatchQuantity;
  //             const itemDetail = batchDetail?.[itemDetailIndex];
  //             const itemBatchList = stockApiResponse.data.data?.itemBatchList;
  //             const discount =
  //               response.data?.code === 200
  //                 ? proportion * response.data.data.item_discount_amount
  //                 : 0;
  //             const tax =
  //               response.data?.code === 200
  //                 ? proportion * response.data.data.item_tax_amount
  //                 : 0;
  //             const item_discount_percentage =
  //               response.data?.code === 200
  //                 ? proportion * response.data.data.item_discount_percentage
  //                 : 0;
  //             const updatedRowIndex = rowIndex + itemDetailIndex;
  //             const updatedDataRow = {
  //               ...updatedData[rowIndex],
  //               item_discount_amount: discount,
  //               item_tax_amount: tax.toFixed(2),
  //               item_discount_percentage: item_discount_percentage,
  //               item_price_wiithout_tax: (
  //                 parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
  //                   parseFloat(itemDetail?.quantity) -
  //                   parseFloat(discount) -
  //                   parseFloat(tax) || 0
  //               ).toFixed(2),
  //               item_batch_number: itemDetail?.batchNumber,
  //               item_rack_location: itemDetail?.item_rack_location,
  //               item_exp_date: moment(itemDetail?.expiryDate).format(
  //                 "YYYY-MM-DD"
  //               ),
  //               to_bin_id: itemDetail?.to_bin_id,
  //               quantity: itemDetail?.quantity,
  //               instock: true,
  //               item_gst:
  //                 response.data?.code === 200
  //                   ? response.data.data.item_total_tax_percentage
  //                   : 0,
  //               item_sgst:
  //                 response.data?.code === 200
  //                   ? (parseFloat(tax) / 2).toFixed(2)
  //                   : 0,
  //               item_cgst:
  //                 response.data?.code === 200
  //                   ? (parseFloat(tax) / 2).toFixed(2)
  //                   : 0,
  //               item_igst: 0,
  //               item_final_price:
  //                 (parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
  //                   parseFloat(itemDetail?.quantity) -
  //                   parseFloat(discount)) /
  //                 parseFloat(itemDetail?.quantity),
  //               item_total_amount: (
  //                 parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
  //                   parseFloat(itemDetail?.quantity) -
  //                 parseFloat(discount)
  //               )?.toFixed(2),
  //               itemBatchList: itemBatchList ?? [],
  //               item_total_tax_percentage:
  //                 response.data?.code === 200
  //                   ? response.data.data.item_total_tax_percentage
  //                   : 0,
  //             };
  //             if (rowIndex === updatedRowIndex) {
  //               updatedData[updatedRowIndex] = updatedDataRow;
  //             } else {
  //               updatedData.splice(updatedRowIndex, 0, updatedDataRow);
  //             }
  //             const lastObject = updatedData[updatedData.length - 1];
  //             setFieldValue("data", [...updatedData]);

  //             setFieldValue("paymentData", [
  //               {
  //                 payment_mode_name: "",
  //                 payment_amount: null,
  //                 tender_check: false,
  //               },
  //               {
  //                 payment_mode_name: "",
  //                 payment_amount: null,
  //                 tender_check: false,
  //               },
  //               {
  //                 payment_mode_name: "",
  //                 payment_amount: null,
  //                 tender_check: false,
  //               },
  //             ]);
  //           }
  //           // if (searchRef && searchRef.current) {
  //           //   setTimeout(() => {
  //           //     searchRef.current.focus();
  //           //   }, 0);
  //           // }
  //         } catch (error) {
  //           onErrorDis(error);
  //         }
  //       } else {
  //         const updatedDataWithInstock = updatedData.map((row, index) =>
  //           index === rowIndex
  //             ? {
  //                 ...row,
  //                 instock: false,
  //                 item_discount_amount: 0,
  //                 item_tax_amount: 0,
  //                 item_discount_percentage: 0,
  //               }
  //             : row
  //         );
  //         setFieldValue("data", updatedDataWithInstock);
  //         errorToast(
  //           `Product ${item.item_name} with quantity ${event.target.value} is not available`
  //         );
  //       }
  //     } catch (error) {
  //       const updatedDataWithError = updatedData.map((row, index) =>
  //         index === rowIndex
  //           ? {
  //               ...row,
  //               instock: false,
  //               item_discount_amount: 0,
  //               item_tax_amount: 0,
  //               item_discount_percentage: 0,
  //               item_price_wiithout_tax: 0,
  //               item_batch_number: "",
  //               item_rack_location: "",
  //               item_exp_date: "",
  //               item_gst: 0,
  //               item_sgst: 0,
  //               item_cgst: 0,
  //               item_igst: 0,
  //               item_total_amount: 0,
  //               itemBatchList: [],
  //             }
  //           : row
  //       );
  //       setFieldValue("data", updatedDataWithError);
  //       if (error?.response?.data?.data?.itemDetails?.item_sellable_quantity) {
  //         errorToast(
  //           `${item.item_name} available quantity is ${error?.response?.data?.data?.itemDetails?.item_sellable_quantity}`
  //         );
  //       } else {
  //         errorToast(`${item.item_name} is out of stock`);
  //       }
  //     }
  //   } else {
  //     const updatedData = values.data.map((row, index) =>
  //       index === rowIndex
  //         ? {
  //             ...row,
  //             quantity: 0,
  //             instock: false,
  //             item_discount_amount: 0,
  //             item_tax_amount: 0,
  //             item_discount_percentage: 0,
  //             item_price_wiithout_tax: 0,
  //             item_total_amount: 0,
  //             item_gst: 0,
  //             item_sgst: 0,
  //             item_cgst: 0,
  //             item_igst: 0,
  //           }
  //         : row
  //     );
  //     setFieldValue("data", updatedData);
  //   }
  // };
  const handleQuantityChange = async (event, rowIndex) => {
    const updatedData = values.data.map((row, index) =>
      index === rowIndex
        ? { ...row, quantity: parseFloat(event.target.value) }
        : row
    );
    setFieldValue("data", updatedData);
    const item = values.data[rowIndex];
    const stockApiUrl = `${url}/v1/inventory/stock/items?item_id=${item.item_id}&item_quantity=${event.target.value}`;
    if (parseFloat(event?.target?.value) > 0) {
      try {
        const stockApiResponse = await api.get(stockApiUrl);
        const batchDetail = stockApiResponse?.data?.data?.itemDetails;
        if (
          stockApiResponse?.data?.code === 200 &&
          stockApiResponse?.data?.data?.itemDetails?.length > 0
        ) {
          for (
            let itemDetailIndex = 0;
            itemDetailIndex < stockApiResponse?.data?.data?.itemDetails?.length;
            itemDetailIndex++
          ) {
            const totalBatchQuantity = batchDetail.reduce(
              (sum, batch) => sum + batch.quantity,
              0
            );
            const proportion =
              batchDetail?.[itemDetailIndex]?.quantity / totalBatchQuantity;
            const itemDetail = batchDetail?.[itemDetailIndex];
            const itemBatchList = stockApiResponse.data.data?.itemBatchList;
            const updatedRowIndex = rowIndex + itemDetailIndex;
            const updatedDataRow = {
              ...updatedData[rowIndex],
              item_discount_amount: itemDetail?.item_discount_amount,
              item_tax_amount: itemDetail?.item_tax_amount.toFixed(2),
              item_discount_percentage: itemDetail?.item_discount_percentage,
              item_unit_price: itemDetail?.item_unit_price,
              item_price_wiithout_tax: (
                parseFloat(itemDetail?.item_unit_price) *
                  parseFloat(itemDetail?.quantity) -
                  parseFloat(itemDetail?.item_discount_amount) -
                  parseFloat(itemDetail?.item_tax_amount) || 0
              ).toFixed(2),
              item_batch_number: itemDetail?.batchNumber,
              item_rack_location: itemDetail?.item_rack_location,
              item_exp_date: moment(itemDetail?.expiryDate).format(
                "YYYY-MM-DD"
              ),
              to_bin_id: itemDetail?.to_bin_id,
              quantity: itemDetail?.quantity,
              instock: true,
              item_gst: itemDetail?.item_total_tax_percentage,
              item_sgst: (parseFloat(itemDetail?.item_tax_amount) / 2).toFixed(
                2
              ),
              item_cgst: (parseFloat(itemDetail?.item_tax_amount) / 2).toFixed(
                2
              ),
              item_igst: 0,
              item_final_price:
                (parseFloat(itemDetail?.item_unit_price) *
                  parseFloat(itemDetail?.quantity) -
                  parseFloat(itemDetail?.item_discount_amount)) /
                parseFloat(itemDetail?.quantity),
              item_total_amount: (
                parseFloat(itemDetail?.item_unit_price) *
                parseFloat(itemDetail?.quantity)
              )?.toFixed(2),
              itemBatchList: itemBatchList ?? [],
              item_total_tax_percentage: itemDetail?.item_total_tax_percentage,
            };
            if (rowIndex === updatedRowIndex) {
              updatedData[updatedRowIndex] = updatedDataRow;
            } else {
              updatedData.splice(updatedRowIndex, 0, updatedDataRow);
            }
            const lastObject = updatedData[updatedData.length - 1];
            setFieldValue("data", [...updatedData]);

            setFieldValue("paymentData", [
              {
                payment_mode_name: "",
                payment_amount: null,
                tender_check: false,
              },
              {
                payment_mode_name: "",
                payment_amount: null,
                tender_check: false,
              },
              {
                payment_mode_name: "",
                payment_amount: null,
                tender_check: false,
              },
            ]);
          }
          // if (searchRef && searchRef.current) {
          //   setTimeout(() => {
          //     searchRef.current.focus();
          //   }, 0);
          // }
        } else {
          const updatedDataWithInstock = updatedData.map((row, index) =>
            index === rowIndex
              ? {
                  ...row,
                  instock: false,
                  item_discount_amount: 0,
                  item_tax_amount: 0,
                  item_discount_percentage: 0,
                }
              : row
          );
          setFieldValue("data", updatedDataWithInstock);
          errorToast(
            `Product ${item.item_name} with quantity ${event.target.value} is not available`
          );
        }
      } catch (error) {
        const updatedDataWithError = updatedData.map((row, index) =>
          index === rowIndex
            ? {
                ...row,
                instock: false,
                item_discount_amount: 0,
                item_tax_amount: 0,
                item_discount_percentage: 0,
                item_price_wiithout_tax: 0,
                item_batch_number: "",
                item_rack_location: "",
                item_exp_date: "",
                item_gst: 0,
                item_sgst: 0,
                item_cgst: 0,
                item_igst: 0,
                item_final_price: 0,
                item_total_amount: 0,
                itemBatchList: [],
              }
            : row
        );
        setFieldValue("data", updatedDataWithError);
        if (error?.response?.data?.data?.itemDetails?.item_sellable_quantity) {
          errorToast(
            `${item.item_name} available quantity is ${error?.response?.data?.data?.itemDetails?.item_sellable_quantity}`
          );
        } else {
          errorToast(`${item.item_name} is out of stock`);
        }
      }
    } else {
      const updatedData = values.data.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              quantity: 0,
              instock: false,
              item_discount_amount: 0,
              item_tax_amount: 0,
              item_discount_percentage: 0,
              item_price_wiithout_tax: 0,
              item_total_amount: 0,
              item_gst: 0,
              item_sgst: 0,
              item_cgst: 0,
              item_igst: 0,
            }
          : row
      );
      setFieldValue("data", updatedData);
    }
  };

  const handleDisChange = (event, rowIndex) => {
    if (event?.target?.value >= 0) {
      const item_discount_amount = event.target.value;
      const totalAmountwithoutDis =
        parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
        parseFloat(values?.data?.[rowIndex]?.quantity);
      const item_discount_percentage = (
        (item_discount_amount / totalAmountwithoutDis) *
        100
      ).toFixed(2);
      const updatedData = values.data.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              item_discount_amount: item_discount_amount,
              item_discount_percentage: item_discount_percentage,
            }
          : row
      );
      setFieldValue("data", updatedData);
      const totalAmount =
        parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
          parseFloat(values?.data?.[rowIndex]?.quantity) -
        parseFloat(event.target.value)?.toFixed(2);
      const itemTaxAmt = itemTotalTax(
        values?.data?.[rowIndex],
        item_discount_amount
      );
      const updatedDataWithTotal = updatedData.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              item_total_amount: totalAmount,
              item_price_wiithout_tax: (totalAmount - itemTaxAmt).toFixed(2),
              item_tax_amount: itemTaxAmt,
              item_final_price:
                (parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
                  parseFloat(values?.data?.[rowIndex]?.quantity) -
                  parseFloat(item_discount_amount)) /
                parseFloat(values?.data?.[rowIndex]?.quantity),
            }
          : row
      );
      setFieldValue("data", updatedDataWithTotal);
    }
  };

  const handleDisPerChange = (event, rowIndex) => {
    if (event?.target?.value >= 0) {
      const item_discount_percentage =
        event.target.value <= 100 ? event.target.value : 0;

      const totalAmountwithoutDis =
        parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
        parseFloat(values?.data?.[rowIndex]?.quantity);
      const item_discount_amount =
        (totalAmountwithoutDis * item_discount_percentage) / 100;

      const updatedData = values.data.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              item_discount_amount: item_discount_amount,
              item_discount_percentage: item_discount_percentage,
            }
          : row
      );
      setFieldValue("data", updatedData);
      const totalAmount = (
        parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
          parseFloat(values?.data?.[rowIndex]?.quantity) -
        parseFloat(item_discount_amount)
      )?.toFixed(2);

      const itemTaxAmt = itemTotalTax(
        values?.data?.[rowIndex],
        item_discount_amount
      );

      const updatedDataWithTotal = updatedData.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              item_total_amount: totalAmount,
              item_price_wiithout_tax: (totalAmount - itemTaxAmt).toFixed(2),
              item_tax_amount: itemTaxAmt,
              item_final_price:
                (parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
                  parseFloat(values?.data?.[rowIndex]?.quantity) -
                  parseFloat(item_discount_amount)) /
                parseFloat(values?.data?.[rowIndex]?.quantity),
            }
          : row
      );
      setFieldValue("data", updatedDataWithTotal);
    }
  };

  const hasDuplicateItemId = (itemId, currentIndex) => {
    const itemIdsExceptCurrent = values.data
      .filter((_, index) => index !== currentIndex)
      .map((item) => item.item_id);

    return itemIdsExceptCurrent.includes(itemId);
  };

  useBarcodeScanner((barcode) => {
    if (barcode) {
      setIsbarcodeScanning(true);
      dispatch(
        invoiceScannedItemAction(barcode, onSuccessScanned, onErrorScanned)
      );
    }
  });

  // const onSuccessScanned = (data) => {
  //   const itemData = data?.data?.item_information;
  //   if (
  //     !values?.data?.some(
  //       (item) => item?.item_id === data?.data?.item_information?.item_id
  //     )
  //   ) {
  //     const newdata = {
  //       sl_no: 1,
  //       item_code: itemData?.item_code,
  //       item_generic_name: itemData?.item_generic_name,
  //       item_name: itemData?.item_name,
  //       quantity: 1,
  //       item_uom: itemData?.item_uom,
  //       item_batch_number:
  //         data?.data?.item_fullfilled_Batches?.[0]?.batchNumber,
  //       item_exp_date: data?.data?.item_fullfilled_Batches?.[0]?.expiryDate,
  //       item_mfg_date: itemData?.item_mfg_date,
  //       item_rack_location:
  //         itemData?.item_rack_location === null
  //           ? ""
  //           : itemData?.item_rack_location,
  //       item_schedule:
  //         itemData?.item_schedule === null ? "" : itemData?.item_schedule,
  //       item_unit_price: itemData?.item_unit_price,
  //       item_price_wiithout_tax:
  //         parseFloat(itemData?.item_unit_price) -
  //         parseFloat(data.data.discount_details?.item_tax_amount),
  //       item_discount_amount: data.data.discount_details?.item_discount_amount,
  //       item_discount_percentage: parseFloat(
  //         data.data.discount_details?.item_discount_percentage
  //       )?.toFixed(2),
  //       item_tax_amount: data.data.discount_details?.item_tax_amount,
  //       item_total_amount:
  //         parseFloat(itemData?.item_unit_price) * 1 -
  //         data.data.discount_details?.item_discount_amount,
  //       item_id: itemData?.item_id,
  //       instock: true,
  //       itemBatchList: data?.data?.itemBatchList,
  //       item_gst: data.data.discount_details?.item_total_tax_percentage,
  //       item_sgst: (
  //         (parseFloat(itemData?.item_unit_price) -
  //           parseFloat(data.data.discount_details?.item_discount_amount)) *
  //         (data.data.discount_details?.sgstRate / 100)
  //       )?.toFixed(2),
  //       item_cgst: (
  //         (parseFloat(itemData?.item_unit_price) -
  //           parseFloat(data.data.discount_details?.item_discount_amount)) *
  //         (data.data.discount_details?.cgstRate / 100)
  //       )?.toFixed(2),
  //       item_igst: (
  //         (parseFloat(itemData?.item_unit_price) -
  //           parseFloat(data.data.discount_details?.item_discount_amount)) *
  //         (data.data.discount_details?.igstRate / 100)
  //       )?.toFixed(2),
  //     };
  //     const oldData = [...values?.data];
  //     oldData.pop();
  //     setFieldValue("data", [...oldData, newdata, initialData]);
  //   } else {
  //     errorToast(
  //       "Item already added to invoice. Please adjust quantity if you wish to add more."
  //     );
  //   }
  // };

  const onSuccessScanned = (data) => {
    const itemData = data?.data?.item_information;
    const existingItemIndex = values?.data?.findIndex(
      (item) => item?.item_id === data?.data?.item_information?.item_id
    );

    if (existingItemIndex === -1) {
      const newdata = {
        sl_no: 1,
        item_code: itemData?.item_code,
        item_generic_name: itemData?.item_generic_name,
        item_name: itemData?.item_name,
        total_sellable_quantity: itemData?.total_sellable_quantity,
        quantity: 1,
        item_free_quantity: 0,
        item_uom: itemData?.item_uom,
        item_batch_number:
          data?.data?.item_fullfilled_Batches?.[0]?.batchNumber,
        item_exp_date: data?.data?.item_fullfilled_Batches?.[0]?.expiryDate,
        item_rack_location: itemData?.item_rack_location ?? "",
        item_mfg_by: itemData?.item_mfg_by,
        item_schedule: itemData?.item_schedule ?? "",
        item_hsn_code: itemData?.item_hsn_code ?? "",
        item_unit_price: itemData?.item_unit_price,
        item_price_without_tax:
          parseFloat(itemData?.item_unit_price) -
          parseFloat(data.data.discount_details?.item_tax_amount),
        item_discount_amount: data.data.discount_details?.item_discount_amount,
        item_discount_percentage: parseFloat(
          data.data.discount_details?.item_discount_percentage
        ),
        item_tax_amount: data.data.discount_details?.item_tax_amount,
        item_final_price: 0,
        item_total_amount:
          parseFloat(itemData?.item_unit_price) * 1 -
          data.data.discount_details?.item_discount_amount,
        item_id: itemData?.item_id,
        instock: true,
        itemBatchList: data?.data?.itemBatchList,
        item_gst: data.data.discount_details?.item_total_tax_percentage,
        item_sgst: (
          (parseFloat(itemData?.item_unit_price) -
            parseFloat(data.data.discount_details?.item_discount_amount)) *
          (data.data.discount_details?.sgstRate / 100)
        )?.toFixed(2),
        item_cgst: (
          (parseFloat(itemData?.item_unit_price) -
            parseFloat(data.data.discount_details?.item_discount_amount)) *
          (data.data.discount_details?.cgstRate / 100)
        )?.toFixed(2),
        item_igst: (
          (parseFloat(itemData?.item_unit_price) -
            parseFloat(data.data.discount_details?.item_discount_amount)) *
          (data.data.discount_details?.igstRate / 100)
        )?.toFixed(2),
        to_bin_id: itemData?.to_bin_id ?? "",
      };

      const oldData = [...values?.data];
      oldData.pop();
      setFieldValue("data", [...oldData, newdata, initialData]);
    } else {
      const existingItem = values.data[existingItemIndex];
      const syntheticEvent = {
        target: {
          value: (existingItem.quantity + 1).toString(),
        },
      };

      handleQuantityChange(syntheticEvent, existingItemIndex);
    }
    setIsbarcodeScanning(false);
  };
  const onErrorScanned = (data) => {
    setIsbarcodeScanning(false);
    errorToast(data?.data?.message);
  };

  return (
    <>
      <div
        className="table-container mt-3"
        style={{
          height: `calc(100% - ${heightOfSecDiv}px)`,
        }}
      >
        <table className="w-full">
          <thead className="sticky top-0 z-10">
            <tr>
              {tableHeading.map((heading, index) => (
                <th
                  key={index}
                  className={`bg-F2F4F5 ${
                    index === tableHeading?.length - 1
                      ? "table-header w-[4rem]"
                      : "table-header"
                  } whitespace-nowrap text-sm fw_500 text-475467 ${
                    index === 16 ||
                    index === 20 ||
                    index === 18 ||
                    index === 19 ||
                    index === 17 ||
                    index === 12 ||
                    index === 14 ||
                    index === 9 ||
                    index === 15
                      ? "text-right w-[6rem]"
                      : "text-left"
                  } ${index === 0 && "w-[2rem]"}
                   ${index === 7 && "w-[4rem]"}
                   ${
                     (index === 4 || index === 5) &&
                     "min-w-[4.5rem] w-[4.5rem] text-right"
                   }
                   `}
                >
                  {heading?.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {values?.data?.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {Object?.values(rowData)?.map((cellData, colIndex) => {
                  if (
                    colIndex < 21
                    // colIndex !== 1 &&
                    // colIndex !== 2
                  ) {
                    return (
                      <td
                        key={colIndex}
                        className={`table-cell text-sm fw_400 text-secondary  text-nowrap ${
                          colIndex === 12 ||
                          colIndex === 14 ||
                          colIndex === 15 ||
                          colIndex === 4 ||
                          colIndex === 18 ||
                          colIndex === 20 ||
                          colIndex === 16 ||
                          colIndex === 17 ||
                          colIndex === 19
                            ? "text-end"
                            : ""
                        } ${colIndex === 3 ? "w-full min-w-[20rem]" : ""}
                        ${colIndex === 10 ? "text-nowrap" : ""}`}
                      >
                        {colIndex === 1 ||
                        colIndex === 2 ||
                        colIndex === 3 ||
                        colIndex === 5 ||
                        colIndex === 6 ? (
                          colIndex !== 5 && colIndex !== 6 ? (
                            <div className="w-full">
                              <SearchAddFeild
                                optionPadding={0}
                                menuWidth="35rem"
                                searchRef={colIndex === 3 ? searchRef : {}}
                                isDisabled={
                                  values?.data?.length - 1 !== rowIndex ||
                                  isPaymentDone ||
                                  isbarcodeScanning ||
                                  disabled
                                    ? true
                                    : false
                                }
                                errorClass="hidden"
                                isRequired={false}
                                value={
                                  values?.data[rowIndex][
                                    Object.keys(rowData)[colIndex]
                                  ]
                                    ? {
                                        label:
                                          values?.data[rowIndex][
                                            Object.keys(rowData)[colIndex]
                                          ],
                                        value: "",
                                      }
                                    : {}
                                }
                                name={`data[${rowIndex}].${
                                  Object.keys(rowData)[colIndex]
                                }`}
                                field={Object.keys(rowData)[colIndex]}
                                isLabelReq={false}
                                defaultSelectClass=""
                                placeholder="Search..."
                                fetchData={fetchData}
                                onChange={async (selectedOption) => {
                                  const filteredData = localData
                                    ?.filter(
                                      (item) =>
                                        item?.item_id === selectedOption?.value
                                    )
                                    .map((item) => ({
                                      sl_no: 1,
                                      item_code: item.item_code,
                                      item_generic_name:
                                        item?.item_generic_name,
                                      item_name: item.item_name,
                                      total_sellable_quantity:
                                        item.total_sellable_quantity,
                                      quantity: "",
                                      item_free_quantity: 0,
                                      item_uom: item.item_uom,
                                      item_batch_number: "",
                                      item_exp_date: "",
                                      item_rack_location:
                                        item?.item_rack_location === null
                                          ? ""
                                          : item?.item_rack_location,
                                      item_mfg_by: item.item_mfg_by,
                                      item_schedule:
                                        item?.item_schedule === null
                                          ? ""
                                          : item?.item_schedule,
                                      item_hsn_code: item?.item_hsn_code ?? "",
                                      item_unit_price: 0.0,
                                      item_price_wiithout_tax: 0.0,
                                      item_discount_amount: 0.0,
                                      item_discount_percentage: 0,
                                      item_tax_amount: 0.0,
                                      item_final_price: 0.0,
                                      item_total_amount: 0.0,
                                      item_id: item?.item_id,
                                      item_total_tax_percentage: 0,
                                      instock: true,
                                    }));
                                  const oldData = [...values?.data];
                                  oldData.pop();
                                  setFieldValue("data", [
                                    ...oldData,
                                    ...filteredData,
                                    initialData,
                                  ]);
                                  inputRefs.current?.focus();
                                }}
                              />
                            </div>
                          ) : colIndex === 5 ? (
                            <Field name={`data[${rowIndex}].quantity`}>
                              {({ field }) => (
                                <input
                                  min={0}
                                  ref={inputRefs}
                                  {...field}
                                  type="number"
                                  step="1"
                                  placeholder=""
                                  // value={rowData.quantity}
                                  readOnly={
                                    values?.data?.length - 1 === rowIndex ||
                                    hasDuplicateItemId(
                                      rowData.item_id,
                                      rowIndex
                                    ) ||
                                    isbarcodeScanning ||
                                    disabled
                                    // ||
                                    // parseFloat(
                                    //   values?.paymentData?.[0]?.payment_amount
                                    // ) > 0
                                  }
                                  onKeyDown={(event) => {
                                    if (
                                      event.key === "-" ||
                                      event.key === "e" ||
                                      event.key === "."
                                    ) {
                                      event.preventDefault();
                                    }
                                    if (
                                      event.key === "Tab" ||
                                      event.key === "Enter"
                                    ) {
                                      if (searchRef && searchRef.current) {
                                        setTimeout(() => {
                                          searchRef.current.focus();
                                        }, 0);
                                      }
                                    }
                                  }}
                                  onBlur={(event) => {
                                    if (
                                      values?.data?.length - 1 === rowIndex ||
                                      hasDuplicateItemId(
                                        rowData.item_id,
                                        rowIndex
                                      ) ||
                                      isbarcodeScanning ||
                                      disabled
                                    ) {
                                      event?.preventDefault();
                                    } else {
                                      handleQuantityChange(event, rowIndex);
                                    }
                                    // if (searchRef && searchRef.current) {
                                    //   setTimeout(() => {
                                    //     searchRef.current.focus();
                                    //   }, 0);
                                    // }
                                  }}
                                  className="focus-within:ring-2 text-right px-2 w-full py-3 rounded focus-within:outline-none focus-within:shadow-[0px_0px_0px_4px_#EBF2FF input-field-no-spin-buttons"
                                />
                              )}
                            </Field>
                          ) : (
                            <Field
                              name={`data[${rowIndex}].item_free_quantity`}
                            >
                              {({ field }) => (
                                <input
                                  min={0}
                                  {...field}
                                  type="number"
                                  step="1"
                                  placeholder=""
                                  readOnly={
                                    values?.data?.length - 1 === rowIndex ||
                                    isbarcodeScanning ||
                                    disabled
                                  }
                                  onKeyDown={(event) => {
                                    if (
                                      event.key === "-" ||
                                      event.key === "e" ||
                                      event.key === "."
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                  className="focus-within:ring-2 text-right px-2 w-full py-3 rounded focus-within:outline-none focus-within:shadow-[0px_0px_0px_4px_#EBF2FF input-field-no-spin-buttons"
                                />
                              )}
                            </Field>
                          )
                        ) : colIndex === 0 ? (
                          <div>
                            <input
                              style={{}}
                              type="radio"
                              name="SerialNumberRadioGroup"
                              disabled={disabled}
                              value={rowIndex}
                              className="peer hidden"
                              id={`alt-${rowIndex}`}
                              onChange={(event) => {
                                setAlternativeID({
                                  item_id: rowData?.item_id,
                                  index: rowIndex,
                                });
                              }}
                            />
                            <label
                              htmlFor={`alt-${rowIndex}`}
                              className="block cursor-pointer select-none rounded-xl p-3 text-center hover:bg-slate-100 peer-checked:bg-blue-100 peer-checked:font-bold peer-checked:outline-none peer-checked:ring-2 peer-checked:ring-blue-500"
                            >
                              {rowIndex + 1}
                            </label>
                          </div>
                        ) : colIndex === 8 ? (
                          <div style={{ minWidth: "10rem" }}>
                            {values.data[rowIndex]?.item_batch_number && (
                              <BatchNumberEdit
                                rowIndex={rowIndex}
                                disabled={disabled}
                              />
                            )}
                          </div>
                        ) : colIndex === 9 ? (
                          cellData ? (
                            moment(cellData).format("DD-MM-YYYY")
                          ) : (
                            ""
                          )
                        ) : colIndex === 11 ? (
                          <p title={cellData}>
                            {truncateString(cellData ?? "", 6)}
                          </p>
                        ) : colIndex === 18 ? (
                          cellData ?? 0
                        ) : colIndex === 16 ? (
                          <>
                            <div>
                              <input
                                style={{
                                  textAlign: "right",
                                }}
                                readOnly={
                                  values?.data?.length - 1 === rowIndex
                                    ? true
                                    : false
                                }
                                disabled={false}
                                type="text"
                                onChange={(event) => {
                                  handleDisChange(event, rowIndex);
                                }}
                                name={`data[${rowIndex}].item_discount_amount`}
                                value={
                                  values.data[rowIndex].item_discount_amount < 0
                                    ? 0
                                    : values.data[rowIndex].item_discount_amount
                                }
                                className="border-0 focus_outline__none w-full "
                              />
                            </div>
                          </>
                        ) : colIndex === 17 ? (
                          <>
                            <div>
                              <input
                                disabled={false}
                                style={{
                                  textAlign: "right",
                                }}
                                readOnly={
                                  values?.data?.length - 1 === rowIndex
                                    ? true
                                    : false
                                }
                                type="number"
                                min="0"
                                max="100"
                                onChange={(event) => {
                                  handleDisPerChange(event, rowIndex);
                                }}
                                name={`data[${rowIndex}].item_discount_percentage`}
                                value={
                                  values.data[rowIndex]
                                    .item_discount_percentage < 0
                                    ? 0
                                    : values.data[rowIndex]
                                        .item_discount_percentage
                                }
                                className="border-0 focus_outline__none w-full input-field-no-spin-buttons"
                              />
                            </div>
                          </>
                        ) : colIndex === 14 ||
                          colIndex === 18 ||
                          colIndex === 19 ? (
                          parseFloat(cellData)?.toFixed(2)
                        ) : (
                          cellData
                        )}
                      </td>
                    );
                  }
                  return null;
                })}
                {!disabled && !isPaymentDone && (
                  <td className="table-cell w-[3rem]">
                    {rowIndex !== values?.data?.length - 1 && (
                      <DeleteIcon
                        onClickFn={() => {
                          setFieldValue("paymentData[0].payment_amount", "");
                          setFieldValue("paymentData[1].payment_amount", "");
                          setFieldValue(
                            "paymentData[2].payment_amount",
                            "null"
                          );
                          removeRow(rowIndex);
                        }}
                      />
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InvoiceBillingTable;

export const itemTotalTax = (item, item_discount_amount) => {
  const totalamt =
    parseInt(item?.quantity) * parseFloat(item?.item_unit_price) -
    parseFloat(item_discount_amount);
  const totalTaxamt =
    parseFloat(totalamt) -
    parseFloat(totalamt) * (100 / (100 + parseFloat(item?.item_gst)));
  return parseFloat(totalTaxamt)?.toFixed(2);
};
