import React, { useEffect, useState } from "react";
import ReportsLayout from "../../../components/reports/reports-layout";
import { productWiseSalesReportAction } from "../../../redux/actions/report-action";
import { useDispatch } from "react-redux";
import moment from "moment";

const GstItcMisMatchReport = () => {
  const [dateRangeFilter, setDateRangeFilterValue] = useState({
    from: null,
    to: null,
  });
  const dispatch = useDispatch();
  const [groupByOptions, setgroupByOptions] = useState([
    { label: "None", value: "" },
    { label: "Drug Code", value: "oi.item_code" },
    { label: "Drug Name", value: "oi.item_name" },
    { label: "Bill Date", value: "so.created_date" },
    { label: "Drug Code & Bill Date", value: "oi.item_code,so.created_date" },
    { label: "Drug Name & Bill Date", value: "oi.item_name,so.created_date" },
    { label: "Drug Code & Branch", value: "oi.item_code,si.firm_name" },
    { label: "Drug Code & Customer", value: "oi.item_code,c.cmr_first_name" },
    { label: "Drug Code & UOM", value: "oi.item_code,oi.item_uom" },
    {
      label: "Drug Code & Batch No",
      value: "oi.item_code,oi.item_batch_number",
    },
    { label: "Drug Code & Qty", value: "oi.item_code,oi.item_quantity" },
    { label: "Drug Code & Rate", value: "oi.item_code,oi.item_unit_price" },
  ]);

  const [loading, setLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState(
    Object.entries(tableColoumsn).map(([label, value]) => ({
      label,
      value,
    }))
  );
  const [selectedColumns, setSelectedColumns] = useState(tableHeading);
  const [filters, setFilters] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [dateRange, setDateRange] = useState("");
  const [groupBy, setGroupBy] = useState("");

  useEffect(() => {
    const selectedColumnValues = selectedColumns.map(
      (column) => tableColoumsn[column]
    );
    const requestData = {
      selectedColumns: selectedColumnValues,
      filters: filters.map(({ id, ...rest }) => rest),
      filter_by: dateRange,
      group_by: groupBy,
      from: dateRangeFilter?.from,
      to: dateRangeFilter?.to,
    };
    dispatch(productWiseSalesReportAction(requestData, onSuccess, onError));
    setLoading(true);
  }, [selectedColumns, filters, dateRange, groupBy]);

  const isIso8601Date = (dateString) => {
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return iso8601Regex.test(dateString);
  };

  const formatDate = (value) => {
    return moment(value).format("DD-MM-YYYY");
  };

  const transformData = (data) => {
    return data.map((item) => {
      let transformedItem = {};
      for (let [key, value] of Object.entries(tableColoumsn)) {
        const rawValue = item[value.split(".").pop()];
        transformedItem[key] = isIso8601Date(rawValue)
          ? formatDate(rawValue)
          : rawValue;
      }
      return transformedItem;
    });
  };

  const onSuccess = (data) => {
    console.log("data is", data);
    const transformedData = transformData(data?.data?.result);
    setTableData(transformedData);
    setLoading(false);
  };
  const onError = (data) => {
    console.log("error is", data);
    setLoading(false);
  };

  return (
    <ReportsLayout
      reportType={"gst"}
      title={"ITC Mismatch Report"}
      tableHeading={selectedColumns}
      tableData={tableData}
      groupByOptions={groupByOptions}
      filterOptions={filterOptions}
      availableColumns={tableHeading}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      setFilters={setFilters}
      loading={loading}
      setDateRange={setDateRange}
      setGroupBy={setGroupBy}
      setDateRangeFilterValue={setDateRangeFilterValue}
    />
  );
};
const tableHeading = [
  "Financial Year", // New entry
  "Aggregate Turnover (Audited)", // New entry
  "Turnover Declared in GSTR-9", // New entry
  "Differences", // New entry
  "Taxable Value Differences", // New entry
  "GST Liability Differences", // New entry
  "ITC Claimed (Audited)", // New entry
  "ITC Claimed in GSTR-9", // New entry
  "ITC Differences", // New entry
];

const tableColoumsn = {
  "Financial Year": "so.financial_year", // New entry for financial year
  "Aggregate Turnover (Audited)": "so.aggregate_turnover_audited", // New entry for audited turnover
  "Turnover Declared in GSTR-9": "so.turnover_declared_gstr_9", // New entry for turnover declared in GSTR-9
  Differences: "so.differences", // New entry for general differences
  "Taxable Value Differences": "so.taxable_value_differences", // New entry for taxable value differences
  "GST Liability Differences": "so.gst_liability_differences", // New entry for GST liability differences
  "ITC Claimed (Audited)": "so.itc_claimed_audited", // New entry for ITC claimed (audited)
  "ITC Claimed in GSTR-9": "so.itc_claimed_gstr_9", // New entry for ITC claimed in GSTR-9
  "ITC Differences": "so.itc_differences", // New entry for ITC differences
};

export default GstItcMisMatchReport;
