import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { invoiceAlternativeAction } from "../../redux/actions/invoices-action";
import LoadingSpinner from "../helper-components/loading-spinner";
import AlternativesTable from "../tables/alternatives-table";

const AlternativesPopover = ({ alternativeID, setInvoiceType }) => {
  const dispatch = useDispatch();
  const [productName, setProductName] = useState("");
  const [alternatives, setAlternatives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage] = useState(1);
  const [pageSize] = useState(20);
  const onSuccess = (data) => {
    setError(false);
    setLoading(false);
    setAlternatives(data?.data?.alternativeProducts ?? []);
    setProductName(data?.data?.main_item_name);
  };
  const onError = (data) => {
    setError(true);
    setLoading(false);
  };

  useEffect(() => {
    if (alternativeID?.item_id !== undefined) {

      dispatch(
        invoiceAlternativeAction(
          {
            pageNumber: currentPage,
            pageSize: pageSize,
            alternative_id: alternativeID.item_id,
          },
          onSuccess,
          onError
        )
      );
    }
  }, [currentPage, alternativeID]);

  const tableBodyData = alternatives?.map((item) => {
    return {
      item_code: item?.item_code ?? "--",
      item_generic_name: item?.item_generic_name ?? "--",
      item_name: item?.item_name,
      item_unit_price: parseFloat(item?.item_unit_price).toFixed(2),
      sellable_quantity: item?.total_sellable_quantity,
      id: item?.alternative_item_id,
    };
  });

  return (
    <div className="absolute w-[-webkit-fill-available]  z-50">
      <div className="  h-[65vh] bg-white ">
        <div className="bg-[#F4F6F9] w-full p-4 font-semibold text-xl -mb-5">
          Alternatives - {productName}
        </div>

        {loading ? (
          <LoadingSpinner />
        ) : error ? (
          <div className="w-full h-full flex justify-center items-center">
            <p className="text-center text-F94B4B -mt-10 text-lg font-semibold">No Alternatives Found</p>
          </div>
        ) : (
          <>
            <AlternativesTable
              setInvoiceType={setInvoiceType}
              alternatives={alternatives}
              tableHeading={tableHeading}
              tableBodyData={tableBodyData}
              mainItemIndex={alternativeID?.index}
            />
          </>
        )}
      </div>
      <div
        className="bg-black h-[24.55vh] opacity-50"
        onClick={() => setInvoiceType("")}
      ></div>
    </div>
  );
};

export default AlternativesPopover;

const tableHeading = [
  {
    label: "Item Code",
    value: "item_code",
  },
  {
    label: "Generic Name",
    value: "generic_name",
  },
  {
    label: "Product Name",
    value: "product_name",
  },
  {
    label: "Price",
    value: "sot_total_price",
  },
  {
    label: "Quantity",
    value: "sot_total_quantity",
  },
  {
    label: "Action",
    value: "action",
  },
];
