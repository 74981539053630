import React, { useContext, useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import search from "../../../assets/icons/searchIcon.svg";
import Select from "react-select";
import { discustomStyles } from "../../../components/discounts/discount-group/item-group-tab";
import { useDispatch } from "react-redux";
import { priceListAction } from "../../../redux/actions/general-action";
import AddButton from "../../../components/buttons/button";
import {
  perDisItemsListAction,
  perVolCreateAction,
} from "../../../redux/actions/discount-action";
import KibabMenu from "../../../assets/icons/kibab-menu";
import EditIcon, { VoidIcon } from "../../../assets/icons/action-icons";
import PeriodVolumeModal from "../../../components/modals/discounts-modals/period-volume-modal";
import errorToast from "../../../components/helper-components/error-toast";
import successToast from "../../../components/helper-components/success-toast";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import moment from "moment";
import PeriodVolumePopover from "../../../components/discounts/period-volume-discount/period-volume-popover";
import { DataContext } from "../../../components/layouts/main-layout";
import { useHotkeys } from "react-hotkeys-hook";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import BulkPeriodVolumePopover from "../../../components/discounts/period-volume-discount/period-volume-popover-bulk";

const PeriodVolumeDiscounts = () => {
  const { permissionFn } = useContext(DataContext);
  const [bulkPopoverOpen, setBulkPopoverOpen] = useState(false);
  const [checkedId, setCheckedId] = useState([]);
  const [showPerVolumePopover, setShowPerVolumePopover] = useState(false);
  const [periodDiscountItemId, setPeriodDiscountItemId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [action, setAction] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  console.log(checkedId, "ggfgfdgdfg");
  const navigate = useNavigate();
  const [priceList, setPriceList] = useState([]);
  const [initialData, setInitialData] = useState({});
  useEffect(() => {
    dispatch(priceListAction(onpriceSuccess, onpriceError));
  }, []);
  const onpriceSuccess = (data) => {
    setPriceList(
      data?.data?.map((item) => {
        return { label: item?.price_list_name, value: item?.price_list_id };
      })
    );
  };
  const onpriceError = () => {};

  const initialValues = {
    price_list_id: initialData?.price_list_id,
    price_list_name: initialData?.price_list_id,
    from_date: initialData?.from_date
      ? moment(initialData?.from_date).format("YYYY-MM-DD")
      : "",
    to_date: initialData?.from_date
      ? moment(initialData?.to_date).format("YYYY-MM-DD")
      : "",
    ItemsData:
      initialData?.items?.length > 0
        ? initialData?.items?.map((item) => ({
            item_id: item?.item_id,
            period_discount_item_id: item?.period_discount_item_id,
            item_code: item?.item_code ?? "",
            item_name: item?.item_name ?? "",
            base_price_list: item?.base_price_list ?? "",
            item_base_price: item?.item_base_price ?? "",
            item_selling_price: item?.item_selling_price ?? "",
            discount_percentage: item?.discount_percentage ?? "",
            default_factor: item?.default_factor ?? "",
            item_pricing_uom: item?.item_pricing_uom ?? null,
          }))
        : [],
  };
  const validationSchema = Yup.object().shape({});

  function perDisItemsListFn(price_list_id) {
    setLoading(true);
    dispatch(
      perDisItemsListAction(
        {
          price_list_id: price_list_id,
        },
        onSuccess,
        (data) => {
          setInitialData({ price_list_id: price_list_id });
          setLoading(false);
          // errorToast(data?.data?.message);
        }
      )
    );
  }

  const onSuccess = (data) => {
    setInitialData(data?.data?.[0]);
    // successToast(data?.message);
    setLoading(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (action && currentItem != null && !event.target.closest(".relative")) {
        setAction(false);
        setCurrentItem(null);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [action, currentItem]);

  const onSubmit = (values) => {
    dispatch(
      perVolCreateAction(
        {
          priceListData: {
            price_list_id: values?.price_list_id,
            from_date: values?.from_date,
            to_date: values?.to_date,
          },
          ItemsData: values?.ItemsData?.map((item) => ({
            item_id: item?.item_id,
          })),
        },
        (data) => {
          perDisItemsListFn(values?.price_list_id);
          successToast(data?.message);
        },
        onCreateError
      )
    );
  };

  const onCreateError = (data) => {
    errorToast("Please select from and to date");
  };

  useHotkeys("escape", () => {
    setShowPerVolumePopover(false);
    setIsModalOpen(false);
  });

  function checkboxChecker(e, all) {
    console.log(e?.target?.id, "ggfgfdgdfg");
    if (all === "all" && checkedId.length === e.length) {
      setCheckedId([]);
    } else if (all === "all") {
      setCheckedId(e);
    } else {
      if (checkedId.includes(e?.target?.id)) {
        const checkedVAlues = [...checkedId];
        const findIndex = checkedVAlues.findIndex(
          (item) => item === e.target?.id
        );
        checkedVAlues.splice(findIndex, 1);
        setCheckedId(checkedVAlues);
      } else {
        setCheckedId((previous) => {
          return [...previous, e.target.id];
        });
      }
    }
  }
  return (
    <>
      <InnerLayout title="Period and volume discounts">
        <Formik
          key={loading}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, values, setFieldValue }) => {
            return (
              <>
                <PeriodVolumeModal
                  itemData={values}
                  setFieldValue={setFieldValue}
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
                />
                {showPerVolumePopover === true && (
                  <PeriodVolumePopover
                    periodDiscountItemId={periodDiscountItemId}
                    item={values?.ItemsData?.find(
                      (item) => item?.period_discount_item_id === currentItem
                    )}
                    setShowPerVolumePopover={setShowPerVolumePopover}
                  />
                )}
                {/* {bulkPopoverOpen && (
                  <BulkPeriodVolumePopover
                    periodDiscountItemId={periodDiscountItemId}
                    items={values?.ItemsData?.filter((item) =>
                      checkedId?.includes(item?.item_id)
                    )}
                    setBulkPopoverOpen={setBulkPopoverOpen}
                  />
                )} */}
                <div className="pl-4 py-3 flex items-center gap-2">
                  <button
                    className="flex items-center gap-2"
                    onClick={() => {
                      navigate("/settings");
                    }}
                  >
                    <ChevronLeftIcon className="h-5 w-5 inline-block" />
                    Settings /
                  </button>
                  <span className="cursor-pointer">
                    {" " + "Period and volume discounts"}
                  </span>
                </div>
                <Form className="h-full flex flex-col mt-2">
                  <div className="grow">
                    <div className="flex gap-1 flex-wrap  justify-between px-4">
                      <div
                        style={{ width: "22rem", height: "2.5rem" }}
                        className={`input_border flex items-center rounded-md pb-1`}
                      >
                        <span className="px-2">
                          {" "}
                          <img width={"16px"} src={search} alt="search icon" />
                        </span>
                        <input
                          placeholder={`Search here`}
                          className=" table_search_input w-full rounded-md fs_12 placeholder:text-fs_12"
                          type="text"
                          value={inputValue}
                          onChange={(e) => {
                            setInputValue(e?.target?.value);
                          }}
                        />
                      </div>
                      <div className="flex gap-4">
                        {/* {checkedId?.length > 0 && (
                          <button
                            onClick={() => {
                              setBulkPopoverOpen(true);
                            }}
                            type="button"
                            className="dis-border flex items-center gap-2 p-2 rounded-lg text-344054 fw_600"
                          >
                            {" "}
                            <EditIcon />
                            Period and Volume
                          </button>
                        )} */}
                        <div
                          style={{
                            width: "fit-content",
                          }}
                          className="dis-border h-[2.65rem] rounded-md text-344054 fw_600 flex items-center md:flex-wrap"
                        >
                          <button
                            type="button"
                            onClick={() => {
                              if (values?.price_list_id) {
                                setIsModalOpen(true);
                              } else {
                                errorToast("Please select price list");
                              }
                            }}
                            className="px-2"
                          >
                            Add Items
                          </button>
                          <div className="h-[2.6rem] w-0.5 border-r-D0D5DD border-r-2 mx-2"></div>

                          <Field name="price_list_id">
                            {({ field }) => (
                              <Select
                                {...field}
                                styles={discustomStyles}
                                options={priceList}
                                value={
                                  priceList.find(
                                    (option) =>
                                      option.value === values.price_list_id
                                  ) || ""
                                }
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "price_list_id",
                                    selectedOption.value
                                  );
                                  perDisItemsListFn(
                                    selectedOption?.value,
                                    setFieldValue
                                  );
                                  setFieldValue(
                                    "price_list_name",
                                    selectedOption.label
                                  );
                                }}
                                placeholder="Select Price list"
                                isSearchable={false}
                              />
                            )}
                          </Field>
                          <div className="h-[2.6rem] w-0.5 border-r-D0D5DD border-r-2 mx-2"></div>
                          <span className="mr-2">From :{"  "}</span>
                          <Field name={`from_date`}>
                            {({ field }) => (
                              <input
                                placeholder="  "
                                {...field}
                                type="date"
                                className={`disPerInput w-[7rem]  focus_outline__none input-field-no-spin-buttons`}
                              />
                            )}
                          </Field>
                          <div className="h-[2.6rem] w-0.5 border-r-D0D5DD border-r-2 mx-2"></div>
                          <span className="mr-2">to :{"  "}</span>
                          <Field name={`to_date`}>
                            {({ field }) => (
                              <input
                                placeholder="  "
                                {...field}
                                type="date"
                                className={`disPerInput w-[7rem]  focus_outline__none input-field-no-spin-buttons`}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>

                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <div className="price-tab-container">
                        <table className="price-my-tab h-full">
                          <thead>
                            <tr>
                              <th
                                className={`${"price-tab-header"} whitespace-nowrap fs_12 fw_500 text-475467 bg-F2F4F5`}
                              >
                                <input
                                  checked={
                                    checkedId?.length ===
                                      values?.ItemsData?.map(
                                        (item) => item?.item_id
                                      ).length && checkedId.length > 0
                                  }
                                  onChange={() =>
                                    checkboxChecker(
                                      values?.ItemsData.map(
                                        (item) => item?.item_id
                                      ),
                                      "all"
                                    )
                                  }
                                  style={{ transform: "scale(1.1)" }}
                                  type="checkbox"
                                  id="myCheckbox"
                                  className="custom-checkbox form-check-input cursor-pointer"
                                />
                              </th>

                              {tableHeading.map((heading, index) => (
                                <th
                                  key={index}
                                  className={`${"price-tab-header"} ${
                                    heading?.label === "Action" &&
                                    "flex justify-end pe-10"
                                  } whitespace-nowrap fs_12 fw_500 text-475467 bg-F2F4F5`}
                                >
                                  {heading?.label}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="h-full">
                            {values?.ItemsData?.length > 0 ? (
                              <>
                                {values?.ItemsData?.filter((item) =>
                                  item?.item_name
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase())
                                )?.map((rowData, rowIndex) => (
                                  <tr
                                    className={`${
                                      checkedId.includes(rowData?.item_id) &&
                                      "bg-D5E6Ff hover:bg-D5E6Ff"
                                    }  hover:bg-f3f8ff     `}
                                    key={rowIndex}
                                  >
                                    <td className="text-sm tab-cell fw_400 text-secondary">
                                      <input
                                        checked={checkedId.includes(
                                          rowData?.item_id
                                        )}
                                        id={rowData?.item_id}
                                        onChange={(e) => checkboxChecker(e)}
                                        style={{ transform: "scale(1.2)" }}
                                        type="checkbox"
                                        className="custom-checkbox form-check-input cursor-pointer"
                                      />
                                    </td>
                                    <td
                                      className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                                    >
                                      {rowData?.item_code}
                                    </td>
                                    <td
                                      className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                                    >
                                      {rowData?.item_name ?? "--"}
                                    </td>
                                    <td
                                      className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                                    >
                                      {rowData?.base_price_list ?? "--"}
                                    </td>
                                    <td
                                      className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                                    >
                                      {rowData?.item_base_price ?? "--"}
                                    </td>
                                    <td
                                      className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                                    >
                                      {rowData?.item_selling_price ?? "--"}
                                    </td>
                                    <td
                                      className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                                    >
                                      {rowData?.discount_percentage ?? "--"}
                                    </td>
                                    <td
                                      className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                                    >
                                      {rowData?.default_factor ?? "--"}
                                    </td>
                                    <td
                                      className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                                    >
                                      {rowData?.item_pricing_uom == null
                                        ? "--"
                                        : rowData?.item_pricing_uom}
                                    </td>
                                    <td
                                      className={`price-tab-cell   text-sm fw_400 text-secondary whitespace-nowrap`}
                                    >
                                      <div className="relative">
                                        {rowData?.period_discount_item_id && (
                                          <button
                                            type="button"
                                            onClick={() => {
                                              setPeriodDiscountItemId(
                                                rowData?.period_discount_item_id
                                              );
                                              setAction(!action);
                                              setCurrentItem(
                                                rowData?.period_discount_item_id
                                              );
                                            }}
                                          >
                                            <KibabMenu />
                                          </button>
                                        )}

                                        {action &&
                                        currentItem ===
                                          rowData?.period_discount_item_id ? (
                                          <div
                                            style={{
                                              minWidth: "13rem",
                                              zIndex: 999,
                                              top: "0px",
                                              right: "-20px",
                                            }}
                                            className="absolute  border border-EAECF0 border-solid border-1 top-4 right-0 mt-8  py-4 px-4  bg-white  rounded shadow-lg z-10 flex flex-col gap-3"
                                          >
                                            <button
                                              type="button"
                                              onClick={() => {
                                                setPeriodDiscountItemId(
                                                  rowData?.period_discount_item_id
                                                );
                                                setAction(!action);
                                                setShowPerVolumePopover(true);
                                              }}
                                              className="flex items-center gap-2 fw_500 text-sm"
                                            >
                                              <EditIcon />
                                              Period and Volume
                                            </button>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                setFieldValue(
                                                  "ItemsData",
                                                  values.ItemsData.filter(
                                                    (_, i) => i !== rowIndex
                                                  )
                                                );
                                              }}
                                              className="flex items-center gap-2 fw_500 text-sm"
                                            >
                                              <VoidIcon />
                                              Delete
                                            </button>
                                          </div>
                                        ) : null}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                                <tr style={{ height: "10rem" }}></tr>
                              </>
                            ) : (
                              <tr
                                style={{
                                  height: "20rem",
                                }}
                              >
                                <td
                                  colSpan={tableHeading.length + 1}
                                  className="text-center text-primary"
                                >
                                  No Items found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      bottom: "0",
                    }}
                    className="flex items-start justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                  >
                    <AddButton
                      onclkFn={handleSubmit}
                      prefixIcon={false}
                      text="Save"
                      type="button"
                    />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </InnerLayout>
    </>
  );
};

export default PeriodVolumeDiscounts;
const tableHeading = [
  {
    label: "ITEM CODE",
  },
  {
    label: "PRODUCT NAME",
  },
  {
    label: "BASE PRICE LIST",
  },
  {
    label: "BASE PRICE",
  },
  {
    label: "SELLING PRICE",
  },
  {
    label: "DISCOUNT %",
  },
  {
    label: "% RELATED TO BASE PRICE",
  },
  {
    label: "UNIT",
  },
  {
    label: "",
  },
];
