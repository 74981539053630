import React, { useContext, useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SortingIcons from "../../../assets/icons/arrowup-down";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import moment from "moment";
import { DataContext } from "../../../components/layouts/main-layout";
import { Fragment } from "react";
import Pagination from "../../../components/tables/pagination";
import PlusIcon from "../../../assets/icons/plus-icon";
import IntoIcon from "../../../assets/icons/into-icon";
import EditIcon, {
  PrintIcon,
  ShareIcon,
  VoidIcon,
} from "../../../assets/icons/action-icons";
import KibabMenu from "../../../assets/icons/kibab-menu";
import {
  goodsTableAction,
  goodsViewAction,
  goodsVoidAction,
} from "../../../redux/actions/purchase-action";
import GoodsPdfContent from "../../../components/purchase-section/goods-receipt/goods-pdf-content";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { storeDetailsAction } from "../../../redux/actions/invoices-action";
import successToast from "../../../components/helper-components/success-toast";
import errorToast from "../../../components/helper-components/error-toast";
import SalesPurchaseTableHeader from "../../../components/sales-section/sales-purchase-table-header";
const GoodsTable = () => {
  const { permissionFn } = useContext(DataContext);
  const dispatch = useDispatch();
  const [currentOrder, setCurrentOrder] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [orders, setOrders] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [action, setAction] = useState(false);
  const [itemLoading, setItemLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [storeDetails, setStoreDetails] = useState({});
  const [reload, setReload] = useState(false);

  useEffect(() => {
    dispatch(storeDetailsAction(onSuccessStoreDetails, onErrorStoreDetails));
  }, []);
  const onSuccessStoreDetails = (data) => {
    setStoreDetails(data?.data);
  };
  const onErrorStoreDetails = () => {};
  const [filterData, setFilterData] = useState({
    sort_by: "",
    sort_order: "",
    selectColumn: "gort_invoice_number",
    from: "",
    to: "",
    inputValue: "",
    orderStatus: "",
  });

  useEffect(() => {
    dispatch(
      goodsTableAction(
        {
          pageNumber: currentPage,
          pageSize: pageSize,
          filterData,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, filterData, reload]);
  const onSuccess = (data) => {
    setError(false);
    setLoading(false);
    setOrders(data?.data?.orderList ?? []);
    setDataCount(data?.data?.totalCount);
  };
  const onError = (data) => {
    setError(true);
    setLoading(false);
  };
  const navigate = useNavigate();
  const paginationProps = {
    pageSize: pageSize,
    dataCount: dataCount,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };
  const initialFilter = {
    sort_by: "",
    sort_order: "",
    selectColumn: "gort_invoice_number",
    from: "",
    to: "",
    inputValue: "",
    orderStatus: "",
  };
  function sortFn(item) {
    let direction = "ASC";
    if (
      filterData != null &&
      filterData?.sort_by === item &&
      filterData?.sort_order === "ASC"
    ) {
      direction = "DESC";
    }
    setFilterData({ ...filterData, sort_by: item, sort_order: direction });
  }

  const isItemChecked = (rowData) => {
    return invoiceItems.some(
      (item) =>
        item.gort_id === rowData.id &&
        item.cmr_id === rowData.cmr_id &&
        Object.keys(item).length === 2
    );
  };
  const handleCheckboxChange = (e, rowData) => {
    if (e.target.checked) {
      setInvoiceItems((prevItems) => [
        ...prevItems,
        {
          gort_id: rowData.id,
          cmr_id: rowData.cmr_id,
        },
      ]);
    } else {
      setInvoiceItems((prevItems) =>
        prevItems.filter(
          (item) =>
            !(
              item.gort_id === rowData.id &&
              item.cmr_id === rowData.cmr_id &&
              Object.keys(item).length === 2
            )
        )
      );
    }
  };

  function itemsChecker(e, rowData) {
    const id = e.target.id;
    if (e.target.checked) {
      setInvoiceItems((prevItems) => [
        ...prevItems,
        {
          gort_id: rowData.id,
          item_id: id,
          cmr_id: rowData.cmr_id,
        },
      ]);
    } else {
      setInvoiceItems((prevItems) =>
        prevItems.filter(
          (item) =>
            !(
              item.gort_id === rowData.id &&
              item.item_id === id &&
              item.cmr_id === rowData.cmr_id
            )
        )
      );
    }
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        action &&
        currentIndex != null &&
        !event.target.closest(".relative")
      ) {
        setAction(false);
        setCurrentIndex(null);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [action, currentIndex]);
  const toggleRow = (index, id) => {
    if (
      invoiceItems?.some((item) => item?.gort_id === id) &&
      Object.keys(invoiceItems?.find((item) => item?.gort_id === id)).length ===
        2
    ) {
      setInvoiceItems(invoiceItems?.filter((item) => item?.gort_id !== id));
    }
    if (
      invoiceItems?.some((item) => item?.gort_id === id) &&
      Object.keys(invoiceItems?.find((item) => item?.gort_id === id))
        ?.length === 3
    ) {
      setInvoiceItems(invoiceItems?.filter((item) => item?.gort_id !== id));
    }
    setItemLoading(true);
    if (expandedRows.includes(index)) {
      setExpandedRows((prevRows) => prevRows.filter((row) => row !== index));
    } else {
      setExpandedRows((prevRows) => [...prevRows, index]);
      dispatch(
        goodsViewAction(
          id,
          (data) => {
            setOrders((prevOrders) =>
              prevOrders.map((order) =>
                order.gort_id === id
                  ? { ...order, itemData: data?.data?.[0]?.itemData }
                  : order
              )
            );
            setItemLoading(false);
          },
          onErrPur
        )
      );
    }
  };

  const tableBodyData = orders?.map((item) => {
    return {
      id: item?.gort_id,
      gort_invoice_number:
        (
          <span className="fw_500 cursor-pointer">
            {item?.gort_invoice_number ?? "--"}
          </span>
        ) ?? "--",
      update_date: moment(item?.update_date).format("MMM D, YYYY"),
      order_status:
        item?.gort_order_status === "open"
          ? "Open"
          : item?.gort_order_status === "closed"
          ? "Closed"
          : "Cancelled",

      cmr_first_name: item?.cmr_name ?? "--",
      gort_total_amount: (
        <span className="flex justify-end pe-5">
          {"₹ " + item?.gort_total_amount}
        </span>
      ),
      itemData: item?.itemData?.length > 0 ? item?.itemData : [],
      is_draft_order: item?.is_draft_order,
      cmr_id: item?.cmr_id ?? "--",
    };
  });

  const onErrPur = (data) => {
    setItemLoading(false);
  };
  const removeItemData = (id) => {
    const order = orders.find((item) => item.gort_id === id);
    if (order) {
      const updatedOrders = orders.map((item) => {
        if (item.gort_id === id) {
          return {
            ...item,
            itemData: [],
          };
        }
        return item;
      });
      setOrders(updatedOrders);
    }
  };
  const downloadPdf = async (data) => {
    const fileName = "test.pdf";

    try {
      const blob = await pdf(
        <GoodsPdfContent data={data} storeDetails={storeDetails} />
      ).toBlob();
      saveAs(blob, fileName);
    } catch (error) {}
  };
  function printFn(id) {
    dispatch(
      goodsViewAction(
        id,
        (data) => {
          downloadPdf(data?.data?.[0]);
        },
        onErrPur
      )
    );
  }
  function voidFn(id) {
    dispatch(
      goodsVoidAction(
        {
          gort_id: id,
        },
        (data) => {
          setReload(!reload);
          successToast(data?.message);
          setItemLoading(false);
        },
        (error) => {
          errorToast(
            <p>
              {error?.data?.message}
              {error?.data?.data?.map((invoice) => (
                <div>
                  {invoice?.poit_invoice_number ?? invoice?.prt_invoice_number}
                </div>
              ))}
            </p>
          );
        }
      )
    );
  }


  return (
    <InnerLayout
      permissionFn={permissionFn}
      module="invoice"
      list={true}
      onclkFn={() => {
        navigate(
          `/goods-receipt/${encodeURIComponent(JSON.stringify([]))}/goods`
        );
      }}
      title="Goods Receipt"
    >
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <></>
      ) : (
        <>
          <SalesPurchaseTableHeader
            setCurrentPage={setCurrentPage}
            tableHeading={tableHeading}
            filterData={filterData}
            setFilterData={setFilterData}
            initialFilter={initialFilter}
            module="goods_receipt_note"
            permissionFn={permissionFn}
            invoiceItems={invoiceItems}
            options={options}
            paginationProps={paginationProps}
            newFn={() =>
              navigate(
                `/goods-receipt/${encodeURIComponent(JSON.stringify([]))}/goods`
              )
            }
            handleSelect={(item) => {
              if (item?.value === "purchase_return") {
                navigate(
                  `/add-purchase-return/${encodeURIComponent(
                    JSON.stringify(invoiceItems)
                  )}/goods`
                );
              } else {
                navigate(
                  `/add-purchase-invoice/${encodeURIComponent(
                    JSON.stringify(invoiceItems)
                  )}/goods`
                );
              }
            }}
          />
          <div className="flex flex-col justify-between h-full">
            <div className="tab-container">
              <table className="my-tab h-full">
                <thead>
                  <tr>
                    <th
                      className={`whitespace-nowrap text-xs fw_500 text-475467 bg-F2F4F5 tab-header w-[5rem]`}
                    ></th>
                    {tableHeading.map((heading, index) => (
                      <th
                        key={index}
                        className={`${"tab-header"} whitespace-nowrap text-xs fw_500 text-475467 bg-F2F4F5`}
                      >
                        <span
                          className={`flex items-center gap-2 ${heading?.className}`}
                        >
                          {heading?.label}
                          {heading?.display && (
                            <button onClick={() => sortFn(heading?.value)}>
                              {" "}
                              <SortingIcons
                                inc={
                                  filterData?.sort_order === "ASC" &&
                                  heading?.value === filterData?.sort_by
                                    ? "#475467"
                                    : "#9A9FA6"
                                }
                                dec={
                                  filterData?.sort_order === "DESC" &&
                                  heading?.value === filterData?.sort_by
                                    ? "#475467"
                                    : "#9A9FA6"
                                }
                              />
                            </button>
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="h-full">
                  {tableBodyData?.length > 0 ? (
                    <>
                      {tableBodyData.map((rowData, rowIndex) => (
                        <>
                          <tr
                            className={`hover:bg-f3f8ff ${
                              isItemChecked(rowData) &&
                              "bg-D5E6Ff hover:bg-D5E6Ff"
                            } ${
                              expandedRows.includes(rowIndex) &&
                              "bg-C4EAB7 bg-opacity-50"
                            } `}
                            key={rowIndex}
                          >
                            <td className="text-sm tab-cell fw_400 text-secondary flex gap-5">
                              <input
                                disabled={
                                  invoiceItems.some(
                                    (invoice) =>
                                      invoice.cmr_id !== rowData.cmr_id
                                  ) ||
                                  expandedRows.includes(rowIndex) ||
                                  rowData?.order_status === "Closed" ||
                                  rowData?.order_status === "Cancelled"
                                }
                                checked={isItemChecked(rowData)}
                                id={rowData?.id}
                                onChange={(e) =>
                                  handleCheckboxChange(e, rowData)
                                }
                                style={{ transform: "scale(1.2)" }}
                                type="checkbox"
                                className="custom-checkbox form-check-input"
                              />
                              {expandedRows.includes(rowIndex) ? (
                                <button
                                  onClick={() => {
                                    removeItemData(rowData?.id);
                                    toggleRow(rowIndex, rowData?.id);
                                  }}
                                  className={`border-D0D5DD border-2 rounded-md p-2 ${
                                    expandedRows.includes(rowIndex) &&
                                    "bg-AECAFF"
                                  }`}
                                >
                                  <IntoIcon fill="black" />
                                </button>
                              ) : (
                                <button
                                  disabled={
                                    invoiceItems.some(
                                      (invoice) =>
                                        invoice.cmr_id !== rowData.cmr_id
                                    ) ||
                                    rowData?.order_status === "Closed" ||
                                    rowData?.order_status === "Cancelled"
                                  }
                                  onClick={() => {
                                    toggleRow(rowIndex, rowData?.id);
                                    setCurrentOrder(rowIndex);
                                  }}
                                  className={`border-D0D5DD border-2 rounded-md p-2 ${
                                    invoiceItems.some(
                                      (invoice) =>
                                        invoice.cmr_id !== rowData.cmr_id
                                    ) ||
                                    rowData?.order_status === "Closed" ||
                                    (rowData?.order_status === "Cancelled" &&
                                      "bg-F4F4F4")
                                  }`}
                                >
                                  <PlusIcon fill="black" />
                                </button>
                              )}
                            </td>
                            {Object.values(rowData).map(
                              (cellData, cellIndex) => (
                                <Fragment key={cellIndex}>
                                  {cellIndex !== 0 && cellIndex < 6 && (
                                    <td
                                      onClick={() => {
                                        navigate(`/view-goods/${rowData?.id}`);
                                      }}
                                      className={`tab-cell cursor-pointer text-sm fw_400 text-secondary whitespace-nowrap`}
                                    >
                                      {cellIndex === 3 ? (
                                        <span
                                          className={`${
                                            cellData === "Open"
                                              ? "border-344054 border text-344054"
                                              : cellData === "Closed"
                                              ? "text-027A48 border-027A48 border "
                                              : "border-f51d00 border text-F51D00"
                                          } px-2 py-1 rounded-full text-xs whitespace-nowrap`}
                                        >
                                          {cellData}
                                        </span>
                                      ) : (
                                        cellData
                                      )}
                                    </td>
                                  )}
                                </Fragment>
                              )
                            )}
                            <td
                              className={`text-sm tab-cell fw_400 text-secondary`}
                            >
                              {(permissionFn("goods_receipt_note")?.update ||
                                permissionFn("goods_receipt_note")?.delete ||
                                permissionFn("goods_receipt_note")?.print ||
                                permissionFn("goods_receipt_note")?.send) && (
                                <div className="relative">
                                  <button
                                    onClick={() => {
                                      setAction(!action);
                                      setCurrentIndex(rowIndex);
                                    }}
                                  >
                                    <KibabMenu />
                                  </button>
                                  {action && currentIndex === rowIndex ? (
                                    <div
                                      style={{
                                        minWidth: "13rem",
                                        zIndex: 999,
                                        top: "0px",
                                        right: "0px",
                                      }}
                                      className="absolute  border border-EAECF0 border-solid border-1 top-4 right-0 mt-8  py-4 px-4  bg-white  rounded shadow-lg z-10 flex flex-col gap-3"
                                    >
                                      {permissionFn("goods_receipt_note")
                                        ?.update &&
                                        rowData?.order_status === "Open" && (
                                          <button
                                            onClick={() => {
                                              navigate(
                                                `/edit-goods/${rowData?.id}`
                                              );
                                            }}
                                            className="flex items-center text-nowrap gap-2 fw_500 text-sm"
                                          >
                                            <EditIcon />
                                            Edit goods receipt
                                          </button>
                                        )}

                                      {permissionFn("goods_receipt_note")
                                        ?.delete &&
                                        rowData?.order_status === "Open" && (
                                          <button
                                            onClick={() => {
                                              voidFn(rowData?.id);
                                            }}
                                            className="flex items-center text-nowrap gap-2 fw_500 text-sm"
                                          >
                                            <VoidIcon />
                                            Void goods receipt
                                          </button>
                                        )}
                                      {permissionFn("goods_receipt_note")
                                        ?.send && (
                                        <button className="flex items-center gap-2 fw_500 text-sm">
                                          <ShareIcon />
                                          Share goods receipt
                                        </button>
                                      )}
                                      {permissionFn("goods_receipt_note")
                                        ?.print && (
                                        <button
                                          onClick={() => printFn(rowData?.id)}
                                          className="flex items-center gap-2 fw_500 text-sm"
                                        >
                                          <PrintIcon />
                                          Print goods receipt
                                        </button>
                                      )}
                                    </div>
                                  ) : null}
                                </div>
                              )}
                            </td>
                          </tr>

                          {expandedRows.includes(rowIndex) && (
                            <>
                              {itemLoading && rowIndex === currentOrder ? (
                                <tr>
                                  <td colSpan={tableHeading.length + 1}>
                                    <LoadingSpinner />
                                  </td>
                                </tr>
                              ) : (
                                <>
                                  {rowData?.itemData?.length > 0 && (
                                    <tr>
                                      <td colSpan={1}></td>
                                      <td colSpan={tableHeading.length - 1}>
                                        <div className="table-container overflow-x-auto w-full">
                                          <table className="w-full">
                                            <thead>
                                              <tr>
                                                <th
                                                  className={`whitespace-nowrap w-[3rem] text-center text-xs fw_500 text-475467 bg-F2F4F5 table-header`}
                                                ></th>
                                                {tableHeading1.map(
                                                  (heading, index) => (
                                                    <th
                                                      key={index}
                                                      className={` table-header  whitespace-nowrap text-xs fw_500 text-475467 bg-F2F4F5 ${
                                                        index === 4 ||
                                                        index === 5 ||
                                                        index === 6 ||
                                                        index === 7
                                                          ? "w-[7rem] text-right"
                                                          : "text-left"
                                                      } ${
                                                        (index === 2 ||
                                                          index === 3) &&
                                                        "w-[7rem]"
                                                      }
                                                      ${
                                                        index === 1 &&
                                                        "w-[8rem]"
                                                      }
                                                      `}
                                                    >
                                                      {heading?.label}
                                                    </th>
                                                  )
                                                )}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {rowData?.itemData?.map(
                                                (row_item_data, rowIndex) => (
                                                  <tr
                                                    className={`${
                                                      invoiceItems.some(
                                                        (item) =>
                                                          item.gort_id ===
                                                            rowData.id &&
                                                          item.item_id ===
                                                            row_item_data.item_id &&
                                                          item.cmr_id ===
                                                            rowData.cmr_id
                                                      )
                                                        ? "bg-C4EAB7 bg-opacity-60"
                                                        : ""
                                                    }`}
                                                    key={rowIndex}
                                                  >
                                                    <td
                                                      className={`table-cell text-center text-sm fw_400 text-secondary `}
                                                    >
                                                      <input
                                                        checked={invoiceItems.some(
                                                          (item) =>
                                                            item.gort_id ===
                                                              rowData.id &&
                                                            item.item_id ===
                                                              row_item_data.item_id &&
                                                            item.cmr_id ===
                                                              rowData.cmr_id
                                                        )}
                                                        id={
                                                          row_item_data?.item_id
                                                        }
                                                        onChange={(e) => {
                                                          itemsChecker(
                                                            e,
                                                            rowData
                                                          );
                                                        }}
                                                        style={{
                                                          transform:
                                                            "scale(1.2)",
                                                        }}
                                                        type="checkbox"
                                                        className="custom-checkbox form-check-input"
                                                      />
                                                    </td>

                                                    <td
                                                      style={{
                                                        maxWidth: "10rem",
                                                      }}
                                                      className={`table-cell text-left text-sm fw_400 text-secondary `}
                                                    >
                                                      {row_item_data?.item_name}
                                                    </td>
                                                    <td
                                                      style={{
                                                        maxWidth: "10rem",
                                                      }}
                                                      className={`table-cell text-left text-sm fw_400 text-secondary `}
                                                    >
                                                      {
                                                        row_item_data?.item_generic_name
                                                      }
                                                    </td>
                                                    <td
                                                      style={{
                                                        maxWidth: "10rem",
                                                      }}
                                                      className={`table-cell text-left text-sm fw_400 text-secondary `}
                                                    >
                                                      {row_item_data?.item_code}
                                                    </td>

                                                    <td
                                                      className={`table-cell text-start text-sm fw_400 text-secondary `}
                                                    >
                                                      {row_item_data?.item_uom ??
                                                        "--"}
                                                    </td>
                                                    <td
                                                      className={`table-cell text-right text-sm fw_400 text-secondary `}
                                                    >
                                                      {row_item_data?.item_unit_price ??
                                                        "--"}
                                                    </td>
                                                    <td
                                                      className={`table-cell text-right text-sm fw_400 text-secondary `}
                                                    >
                                                      {row_item_data?.item_quantity ??
                                                        "--"}
                                                    </td>
                                                    <td
                                                      className={`table-cell-last-column text-right text-sm fw_400 text-secondary `}
                                                    >
                                                      {row_item_data?.item_total_amount ??
                                                        "--"}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </td>
                                      <td colSpan={1}></td>
                                    </tr>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      ))}
                      <tr style={{ height: "10rem" }}></tr>
                    </>
                  ) : (
                    <tr
                      style={{
                        height: "20rem",
                      }}
                    >
                      <td
                        colSpan={tableHeading.length + 1}
                        className="text-center text-primary"
                      >
                        No {"Goods Receipt" + "s"} found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination paginationProps={paginationProps} />
          </div>
        </>
      )}
    </InnerLayout>
  );
};

export default GoodsTable;
const tableHeading = [
  {
    label: "GOODS RECEIPT NUMBER",
    value: "gort_invoice_number",
    display: true,
    column: true,
  },
  { label: "DATE", value: "update_date", display: true, column: false },
  {
    label: "ORDER STATUS",
    value: "order_status",
    display: false,
    column: false,
  },
  {
    label: "CUSTOMER NAME",
    value: "cmr_name",
    display: true,
    column: true,
  },
  {
    label: <span className="flex justify-end">AMOUNT</span>,
    value: "sodtt_total_amount",
    display: true,
    column: false,
    className: "justify-end pe-5",
  },
  { label: "", value: "", column: false },
];

const tableHeading1 = [
  { label: "PRODUCT NAME", value: "item_product_name" },
  { label: "GENERIC NAME", value: "item_generic_name" },
  { label: "ITEM CODE", value: "item_code" },
  { label: "UOM", value: "item_pack_size" },
  { label: "PRICE (₹)", value: "item_unit_price" },
  { label: "QTY", value: "item_quantity" },
  { label: "TOTAL (₹)", value: "item_total" },
];

const options = [
  { value: "purchase_return", label: "Purchase Return" },
  { value: "purchase_invoice", label: "Purchase Invoice" },
];
