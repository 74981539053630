import { useFormikContext } from "formik";
import React, { Fragment } from "react";
import UploadIcon from "../../assets/icons/upload-icon";
import CreateSelect from "../forms/create-select-field";
import SelectField from "../forms/select-field";
import "./invoice.css";

const InvoiceFinalBillTable = ({ docList, payModesList, disabled = false }) => {
  const tableHeading = [
    { label: "Doctor's Name", value: "sl_no" },
    { label: "GST & Tax Information", value: "item_code" },
    { label: "Upload Prescription", value: "item_generic_name" },
    { label: "Payment Mode", value: "item_product_name" },
    { label: "Sub Total", value: "item_pack_size" },
  ];
  const { values } = useFormikContext();

  return (
    <></>
    // <div className="table-container">
    //   <table className="my-table">
    //     <thead>
    //       <tr>
    //         {tableHeading.map((heading, index) => (
    //           <Fragment key={index}>
    //             {heading?.label === "Sub Total" ? (
    //               <th
    //                 style={{
    //                   width: "25rem",
    //                   minWidth: "13rem",
    //                 }}
    //                 key={index}
    //                 className={`${"table-header-billing-subtotal"} whitespace-nowrap text-sm fw_400 text-secondary`}
    //               >
    //                 <div className="flex justify-between">
    //                   <span>Sub Total</span>{" "}
    //                   <span>₹{calculateSubtotal(values?.data).toFixed(2)}</span>
    //                 </div>
    //               </th>
    //             ) : (
    //               <th
    //                 key={index}
    //                 className={`${"table-header-billing"} whitespace-nowrap text-sm fw_500 text-475467`}
    //               >
    //                 {heading?.label}
    //               </th>
    //             )}
    //           </Fragment>
    //         ))}
    //       </tr>
    //     </thead>
    //     <tbody>
    //       <tr>
    //         <td
    //           style={{
    //             width: "13rem",
    //             minWidth: "11rem",
    //           }}
    //           className={`table-cell-billing doctorSelect text-sm fw_400 text-secondary`}
    //         >
    //           <CreateSelect
    //             disabled={disabled}
    //             options={docList}
    //             name="doctor"
    //             placeholder="Select a doctor or add new"
    //           />
    //         </td>
    //         <td
    //           style={{
    //             width: "16rem",
    //             minWidth: "13rem",
    //           }}
    //           className={`table-cell-billing text-sm fw_400 text-secondary`}
    //         >
    //           <div className="flex flex-col xl:flex-row gap-4 whitespace-nowrap">
    //             <>
    //               <span>
    //                 <span className="fw_700">CGST</span>- ₹
    //                 {calculateTotalCGST(values?.data)}
    //               </span>
    //               <span>
    //                 {" "}
    //                 <span className="fw_700">SGST</span>-₹
    //                 {calculateTotalSGST(values?.data)}
    //               </span>
    //               <span>
    //                 {" "}
    //                 <span className="fw_700">IGST</span>- ₹
    //                 {calculateTotalIGST(values?.data)}
    //               </span>
    //             </>
    //           </div>
    //         </td>
    //         <td
    //           style={{
    //             width: "13rem",
    //             minWidth: "13rem",
    //           }}
    //           className={`table-cell-billing text-sm fw_400 text-secondary`}
    //         >
    //           <div
    //             style={{
    //               maxWidth: "13rem",
    //             }}
    //             className="border border-primary  border-solid cursor-pointer rounded-md text-primary flex whitespace-nowrap gap-3 items-center py-2 justify-center px-2"
    //           >
    //             <UploadIcon />
    //             Upload Prescription
    //           </div>
    //         </td>
    //         <td
    //           style={{
    //             width: "12rem",
    //             minWidth: "10rem",
    //           }}
    //           className={`table-cell-billing-payment text-sm fw_400 text-secondary`}
    //         >
    //           <div className="mt-5">
    //             <SelectField
    //               isDisabled={disabled}
    //               errMsgContainerClass="flex justify-start"
    //               id={"payment_method"}
    //               isContainer={false}
    //               options={payModesList}
    //               name={"payment_method"}
    //               placeholder={"Select Payment Method"}
    //             />
    //           </div>
    //           {/* <ErrorMsg containerClass={""} name={"payment_method"} /> */}
    //         </td>

    //         <td className="flex flex-col">
    //           <div
    //             className={` text-sm fw_400 text-secondary table-header-billing-last`}
    //           >
    //             <div className="flex justify-between">
    //               <span>Tax</span>{" "}
    //               <span>₹{calculateTotalTax(values?.data).toFixed(2)}</span>
    //             </div>
    //           </div>
    //           <div
    //             className={` text-sm fw_400 text-secondary table-header-billing-last`}
    //           >
    //             <div className="flex justify-between">
    //               <span>Discount</span>{" "}
    //               <span>
    //                 ₹{calculateTotalDiscount(values?.data).toFixed(2)}
    //               </span>
    //             </div>
    //           </div>
    //           <div
    //             className={` text-sm fw_400 text-secondary table-header-billing-total`}
    //           >
    //             <div className="flex justify-between">
    //               <span className="fw_600">Total</span>{" "}
    //               <span className="fw_600">
    //                 ₹
    //                 {values.data
    //                   ?.reduce(
    //                     (total, item) =>
    //                       total + parseFloat(item.item_total_amount || 0),
    //                     0
    //                   )
    //                   .toFixed(2)}
    //               </span>
    //             </div>
    //           </div>
    //         </td>
    //       </tr>
    //     </tbody>
    //   </table>
    // </div>
  );
};

export default InvoiceFinalBillTable;

export const calculateTotal = (data) => {
  let overallTotal = 0;

  data.forEach((item) => {
    const unitPrice = parseFloat(item.item_unit_price) || 0;
    const quantity = parseInt(item.quantity, 10) || 0;
    const dis = parseFloat(item.item_discount_amount) || 0;
    const total = unitPrice * quantity - dis;
    overallTotal += total;
  });

  return overallTotal;
};
export const calculateTotalTax = (data) => {
  let totalTax = 0;
  data?.forEach((rowData) => {
    totalTax += parseFloat(rowData.item_tax_amount) || 0;
  });

  return totalTax;
};

export const calculateSubtotal = (data) => {
  return data.reduce((subtotal, item) => {
    const unitPrice = parseFloat(item.item_unit_price || 0);
    const quantity = parseInt(item.quantity, 10) || 0;
    return subtotal + unitPrice * quantity;
  }, 0);
};

// const calculateTotalDiscount = (data) => {
//   return data.reduce((totalDiscount, item) => {
//     const item_discount_amount = parseFloat(item.item_discount_amount || 0);
//     return totalDiscount + item_discount_amount;
//   }, 0);
// };

// export const calculateTotalCGST = (data) => {
//   let totalCGST = 0;

//   data?.forEach((rowData) => {
//     totalCGST += parseFloat(rowData.item_cgst) || 0;
//   });

//   return totalCGST;
// };

// export const calculateTotalSGST = (data) => {
//   let totalSGST = 0;

//   data?.forEach((rowData) => {
//     totalSGST += parseFloat(rowData.item_sgst) || 0;
//   });

//   return totalSGST;
// };
// export const calculateTotalIGST = (data) => {
//   let totalIGST = 0;

//   data?.forEach((rowData) => {
//     totalIGST += parseFloat(rowData.item_igst) || 0;
//   });

//   return totalIGST;
// };
