import React, { useCallback, useContext, useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import emptyInvoice from "../../../assets/images/empty-invoice.png";
import AddButton from "../../../components/buttons/button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DynamicTable from "../../../components/tables/dynamic-table";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import { customerDeleteAction } from "../../../redux/actions/customer-action";
import CustomerFilter from "../../../components/tables/table-filter/customer-filter";
import { DataContext } from "../../../components/layouts/main-layout";
import {
  priceListDeleteAction,
  priceListTableAction,
} from "../../../redux/actions/discount-action";
import {
  roundingMethodoptions,
  roundingRuleoptions,
} from "../../../components/helper-components/data";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import StatusIcons from "../../../assets/icons/check-icon";

const PriceListTable = () => {
  const { permissionFn } = useContext(DataContext);

  const dispatch = useDispatch();
  const [priceList, setPriceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [pageSize] = useState(10);
  const [filterData, setFilterData] = useState({
    sort_by: "",
    sort_order: "",
    selectColumn: "",
    from: "",
    to: "",
    inputValue: "",
    customer_status: "",
  });
  useEffect(() => {
    dispatch(
      priceListTableAction(
        {
          pageNumber: currentPage,
          pageSize: pageSize,
          filterData,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, filterData, reload]);
  const onSuccess = (data) => {
    setError(false);
    setLoading(false);
    setPriceList(data?.data?.pricelist);
    setDataCount(data?.data?.totalCount);
  };
  const onError = (data) => {
    setError(true);
    setLoading(false);
  };

  const navigate = useNavigate();

  const tableBodyData = priceList?.map((item) => {
    return {
      id: item?.price_list_id,
      price_list_name: (
        <span className="flex items-center gap-5">
          {item?.price_list_name ?? "--"}{" "}
          {item?.default_price_list && (
            <span
              style={{
                width: "fit-content",
                backgroundColor: item?.default_price_list
                  ? "var(--success-color-opacity)" // Fully opaque success color
                  : "var(--danger-color-opacity)", // F51D00 with approximately 15% opacity
              }}
              className={`fs_12 ${
                item?.default_price_list ? "text-027A48" : "text-F51D00"
              } fw_500 flex items-center gap-2 px-3 py-1 rounded-full`}
            >
              {"Default"}
            </span>
          )}
        </span>
      ),
      default_base_price_list_id: item?.default_base_price_list ?? "--",
      rounding_method:
        roundingMethodoptions?.find(
          (meth) => meth?.value === item?.rounding_method
        )?.label ?? "--",
      rounding_rule:
        roundingRuleoptions?.find((rule) => rule?.value === item?.rounding_rule)
          ?.label ?? "--",
      default_factor: item?.default_factor ?? "--",
    };
  });
  const paginationProps = {
    pageSize: pageSize,
    dataCount: dataCount,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };
  const handleFilterClick = useCallback(() => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  }, []);

  const filterby = showFilter ? (
    <CustomerFilter
      filterData={filterData}
      setFilterData={setFilterData}
      setShowFilter={setShowFilter}
    />
  ) : null;

  return (
    <InnerLayout
      list={true}
      onclkFn={() => navigate("/add-price-list")}
      title="Price List"
      module="customer"
      permissionFn={permissionFn}
    >
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <></>
      ) : priceList?.length === 0 ? (
        <div className="flex flex-col justify-center items-center h-full">
          <div>
            <img src={emptyInvoice} alt="empty-invoice" />
            <h3 className="flex justify-center text-000000 fw_600 fs_18">
              No priceList found{" "}
            </h3>
            <p className="flex justify-center text-667085 fw_400 mt-4 text-sm">
              Let’s start by adding your first customer.{" "}
            </p>
            <div className="flex justify-center mt-3">
              <AddButton
                text="Add Customer"
                onclkFn={() => navigate("/add-customer")}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="pl-4 pt-3 flex items-center gap-2">
            <button
              className="flex items-center gap-2"
              onClick={() => {
                navigate("/settings");
              }}
            >
              <ChevronLeftIcon className="h-5 w-5 inline-block" />
              Settings /
            </button>
            <span className="cursor-pointer">{" " + "Price List"}</span>
          </div>
          <DynamicTable
            id_name="price_list_id"
            list={true}
            onclkFn={() => {
              navigate("/add-price-list");
            }}
            filterSec={false}
            permissionFn={permissionFn}
            module={"price_list"}
            path="price-list"
            reload={reload}
            setReload={setReload}
            loading={loading}
            setLoading={setLoading}
            deleteAction={priceListDeleteAction}
            label="price-list"
            showFilter={showFilter}
            filterFn={handleFilterClick}
            setFilterData={setFilterData}
            filterData={filterData}
            paginationProps={paginationProps}
            tableHeading={tableHeading}
            tableBodyData={tableBodyData}
            placeholder="Search"
            filterBy={filterby}
            initialFilter={{
              sort_by: "",
              sort_order: "",
              selectColumn: "",
              from: "",
              to: "",
              inputValue: "",
              customer_status: "",
            }}
          />
        </>
      )}
    </InnerLayout>
  );
};

export default PriceListTable;
const tableHeading = [
  {
    label: "NAME",
    value: "price_list_name",
    display: false,
    column: true,
  },
  {
    label: "BASE PRICE LIST",
    value: "cmr_first_name",
    display: true,
    column: true,
  },
  {
    label: "ROUNDING METHOD",
    value: "sot_payment_status",
    display: false,
    column: false,
  },
  {
    label: "ROUNDING RULE",
    value: "total_order_value",
    display: true,
    column: false,
    className: "",
  },
  {
    label: "DEFAULT FACTOR",
    value: "total_order_value",
    display: true,
    column: false,
    className: "",
  },
  {
    label: "",
    value: "",
    column: false,
  },
];
