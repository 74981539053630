import {
  BOUNCE_REPORT,
  BRANCH_WISE_SALES_REPORT,
  DAILY_SALES_REPORT,
  DISCOUNT_ANALYSIS_REPORT,
  DOCTOR_WISE_SALES_REPORT,
  EXPIRY_LOSS_REPORT,
  GSTR1_REPORT,
  GSTR20_REPORT,
  GSTR2A_REPORT,
  GSTR3B_REPORT,
  GSTR9C_REPORT,
  GSTR9_REPORT,
  GSTR_HSN_CODE_SUMMARY_REPORT,
  GST_E_WAY_BILL_REPORT,
  GST_ITC_AGING_REPORT,
  GST_ITC_MISMATCH_REPORT,
  GST_PAYMENT_SUMMARY_REPORT,
  INVENTORY_REPORT,
  INVENTORY_SALES_REPORT,
  OVERALL_SALES_REPORT,
  PARTY_WISE_PROFIT_REPORT,
  PARTY_WISE_SALES_REPORT,
  PAYMENT_MODE_WISE_SALES_REPORT,
  PRODUCT_WISE_SALES_REPORT,
  PURCHASE_BEHAVIOR_REPORT,
  PURCHASE_FREQ_REPORT,
  SALES_RETURN_REPORT,
  SCHEDULED_HDRUG_REG_REPORT,
  SLOW_FAST_MOVING_PRODUCTS,
} from "../types";

/**Sales Reports */
export const overallSalesReportAction = (data, onSuccess, onError) => {
  return {
    type: OVERALL_SALES_REPORT,
    data,
    onSuccess,
    onError,
  };
};

export const dailySalesReportAction = (data, onSuccess, onError) => {
  return {
    type: DAILY_SALES_REPORT,
    data,
    onSuccess,
    onError,
  };
};

export const branchWiseSalesReportAction = (data, onSuccess, onError) => {
  return {
    type: BRANCH_WISE_SALES_REPORT,
    data,
    onSuccess,
    onError,
  };
};

export const productWiseSalesReportAction = (data, onSuccess, onError) => {
  return {
    type: PRODUCT_WISE_SALES_REPORT,
    data,
    onSuccess,
    onError,
  };
};

export const doctorWiseSalesReportAction = (data, onSuccess, onError) => {
  return {
    type: DOCTOR_WISE_SALES_REPORT,
    data,
    onSuccess,
    onError,
  };
};

export const partyWiseSalesReportAction = (data, onSuccess, onError) => {
  return {
    type: PARTY_WISE_SALES_REPORT,
    data,
    onSuccess,
    onError,
  };
};

export const paymentModeWiseSalesReportAction = (data, onSuccess, onError) => {
  return {
    type: PAYMENT_MODE_WISE_SALES_REPORT,
    data,
    onSuccess,
    onError,
  };
};

/**Financial and Revenue */
export const partyWiseProfitReportAction = (data, onSuccess, onError) => {
  return {
    type: PARTY_WISE_PROFIT_REPORT,
    data,
    onSuccess,
    onError,
  };
};

export const discountAnalysisReportAction = (data, onSuccess, onError) => {
  return {
    type: DISCOUNT_ANALYSIS_REPORT,
    data,
    onSuccess,
    onError,
  };
};

/**Sales Return Reports */

export const salesReturnReportAction = (data, onSuccess, onError) => {
  return {
    type: SALES_RETURN_REPORT,
    data,
    onSuccess,
    onError,
  };
};

/**Customer */
export const purchaseFreqReportAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_FREQ_REPORT,
    data,
    onSuccess,
    onError,
  };
};

export const purchaseBehaviorReportAction = (data, onSuccess, onError) => {
  return {
    type: PURCHASE_BEHAVIOR_REPORT,
    data,
    onSuccess,
    onError,
  };
};

/**Inventory and Slaes Reports */

export const bounceReportAction = (data, onSuccess, onError) => {
  return {
    type: BOUNCE_REPORT,
    data,
    onSuccess,
    onError,
  };
};

export const inventorySalesReportAction = (data, onSuccess, onError) => {
  return {
    type: INVENTORY_SALES_REPORT,
    data,
    onSuccess,
    onError,
  };
};

export const inventoryReportAction = (data, onSuccess, onError) => {
  return {
    type: INVENTORY_REPORT,
    data,
    onSuccess,
    onError,
  };
};

export const scheduledHDrugRegReportAction = (data, onSuccess, onError) => {
  return {
    type: SCHEDULED_HDRUG_REG_REPORT,
    data,
    onSuccess,
    onError,
  };
};

export const expiryLossReportAction = (data, onSuccess, onError) => {
  return {
    type: EXPIRY_LOSS_REPORT,
    data,
    onSuccess,
    onError,
  };
};

export const slowAndFastMovingProductsReportAction = (
  data,
  onSuccess,
  onError
) => {
  return {
    type: SLOW_FAST_MOVING_PRODUCTS,
    data,
    onSuccess,
    onError,
  };
};
// GST report

export const gstR1ReportAction = (data, onSuccess, onError) => {
  return {
    type: GSTR1_REPORT,    
    data,
    onSuccess,
    onError,
  };
};
export const gstr2AReportAction = (data, onSuccess, onError) => {
  return {
    type: GSTR2A_REPORT,
    data,
    onSuccess,
    onError,
  };
};

export const gstr3bReportAction = (data, onSuccess, onError) => {
  return {
    type: GSTR3B_REPORT,
    data,
    onSuccess,
    onError,
  };
};
export const gstRHsnCodeSummaryReportAction = (data, onSuccess, onError) => {
  return {
    type: GSTR_HSN_CODE_SUMMARY_REPORT,
    data,
    onSuccess,
    onError,
  };
};

export const gstr9ReportAction = (data, onSuccess, onError) => {
  return {
    type: GSTR9_REPORT,
    data,
    onSuccess,
    onError,
  };
};
export const gstr9cReportAction = (data, onSuccess, onError) => {
  return {
    type: GSTR9C_REPORT,
    data,
    onSuccess,
    onError,
  };
};
export const gstItcMismatchReportAction = (data, onSuccess, onError) => {
  return {
    type: GST_ITC_MISMATCH_REPORT,
    data,
    onSuccess,
    onError,
  };
};
export const gstEWayBillReportAction = (data, onSuccess, onError) => {
  return {
    type: GST_E_WAY_BILL_REPORT,
    data,
    onSuccess,
    onError,
  };
};
export const gstPaymentSummaryReportAction = (data, onSuccess, onError) => {
  return {
    type: GST_PAYMENT_SUMMARY_REPORT,
    data,
    onSuccess,
    onError,
  };
};
export const gstItcAgingReportAction = (data, onSuccess, onError) => {
  return {
    type: GST_ITC_AGING_REPORT,
    data,
    onSuccess,
    onError,
  };
};
