import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  uomCreateAction,
  uomListAction,
  uomUpdateAction,
} from "../../../../redux/actions/settings-action";
import successToast from "../../../helper-components/success-toast";
import errorToast from "../../../helper-components/error-toast";
import AddButton from "../../../buttons/button";
import search from "../../../../assets/icons/searchIcon.svg";
import LoadingSpinner from "../../../helper-components/loading-spinner";
import InnerLayout from "../../../layouts/inner-layout";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { customStyles } from "../../../tables/filtering-section";
import Select from "react-select";
import DeleteIcon from "../../../../assets/icons/delete-icon";

const UnitOfMeasure = () => {
  const searchRef = useRef();
  const addRef = useRef();
  const tableHeading = [
    "UOM Code",
    "UOM Name",
    "EWB Unit",
    "Length",
    "Width",
    "Height",
    "Volume",
    "Volume UOM",
    "Weight",
  ];
  const searchFilter = [
    {
      label: "UOM code",
      value: "code",
    },
    {
      label: "UOM Name",
      value: "name",
    },
  ];
  const [reload, setReload] = useState(false);
  const [isFocused, setIsFocused] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filterData, setFilterData] = useState({
    searchColumn: "name",
    searchValue: "",
  });
  const [disableArrayFields, setDisableArrayFields] = useState(false);
  const [disableNewFields, setDisableNewFields] = useState(false);
  const [initialData, setUomListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleFieldChange = (index, field, value, setFieldValue) => {
    setFieldValue(`${index}.${field}`, value);
  };
  useEffect(() => {
    dispatch(
      uomListAction(filterData, onFetchUomLIstSuccess, onFetchUomLIstError)
    );
  }, [filterData, reload]);
  const onFetchUomLIstSuccess = (data) => {
    setUomListData(data?.data?.uomList);
    setLoading(false);
  };
  const onFetchUomLIstError = (data) => {
    setUomListData([]);
    setLoading(false);
    errorToast(data?.message);
  };
  const initialValues = {
    data: initialData,
    newLine: {
      code: "",
      name: "",
      ewb_unit: "",
      length: "",
      width: "",
      height: "",
      volume: "",
      volume_uom: "",
      weight: "",
    },
  };
  console.log(filterData, "dfgsdfdsfdsfs");
  const onSubmit = (values, { setSubmitting }) => {
    const changedObjects = [];
    let newLineObject = null;

    // Collect changed array data
    initialData.forEach((item, index) => {
      if (JSON.stringify(values.data[index]) !== JSON.stringify(item)) {
        changedObjects.push(values.data[index]);
      }
    });
    // Collect new line data if it is altered
    if (
      JSON.stringify(values.newLine) !== JSON.stringify(initialValues.newLine)
    ) {
      newLineObject = values.newLine;
    }
    if (newLineObject !== null) {
      dispatch(uomCreateAction(newLineObject, onCreateSuccess, onCreateError));
    }
    if (changedObjects.length > 0) {
      dispatch(
        uomUpdateAction(
          { updatingData: changedObjects },
          onUpdateSuccess,
          onUpdateError
        )
      );
    }
    setSubmitting(false);
  };
  const onCreateSuccess = (data) => {
    if (addRef && addRef?.current) {
      addRef?.current?.focus();
    }
    setReload(!reload);
    setDisableArrayFields(false);
    setDisableNewFields(false);
    successToast(data?.message);
  };
  const onCreateError = (data) => {
    setDisableArrayFields(false);
    setDisableNewFields(false);
    errorToast(data?.data?.message);
  };
  const onUpdateSuccess = (data) => {
    // reloadData();
    setReload(!reload);
    if (addRef && addRef?.current) {
      addRef?.current?.focus();
    }
    setDisableArrayFields(false);
    setDisableNewFields(false);
    successToast(data?.message);
  };
  const onUpdateError = (data) => {
    setDisableArrayFields(false);
    setDisableNewFields(false);
    errorToast(data?.data?.message);
  };
  const handleSelectChange = (event) => {
    setFilterData({ ...filterData, searchColumn: event?.value });
  };

  function deleteFn() {

  }
  return (
    <InnerLayout
      list={true}
      title="Settings"
      module="uom-setup"
      // permissionFn={permissionFn}
    >
      <div className="pl-4">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, dirty, handleSubmit }) => (
            <Form className="pt-1">
              <div className="flex flex-col" style={{ height: "85vh" }}>
                <div className="flex justify-between py-4 items-center px-2">
                  <div className="  flex items-center gap-2 text-sm">
                    <button
                      className="flex items-center gap-2"
                      onClick={() => {
                        navigate("/settings");
                      }}
                    >
                      <ChevronLeftIcon className="h-5 w-5 inline-block" />
                      Settings /
                    </button>
                    <span className="cursor-pointer">{" " + "UOM Setup"}</span>
                  </div>
                  <div
                    style={{ maxWidth: "30rem", height: "2.6rem" }}
                    className={`w-full  flex items-center rounded-md   ${
                      isFocused ? "border border-primary" : "input_border"
                    }`}
                  >
                    <Select
                      className="z-30  text-xs placeholder:text-xs"
                      // menuPortalTarget={document.body}
                      styles={customStyles}
                      value={
                        filterData?.searchColumn
                          ? searchFilter?.find(
                              (item) => item?.value === filterData?.searchColumn
                            )
                          : null
                      }
                      onChange={(e) => {
                        handleSelectChange(e);
                        setIsFocused(true);
                        if (searchRef && searchRef?.current) {
                          searchRef?.current?.focus();
                        }
                      }}
                      options={searchFilter}
                      placeholder="Select Column"
                      isSearchable={false}
                    />{" "}
                    <div
                      className={`h-full w-0.5 ${
                        isFocused
                          ? "border-r border-r-primary"
                          : "border-r border-r-D0D5DD"
                      }`}
                    ></div>{" "}
                    <input
                      ref={searchRef}
                      autoFocus={true}
                      onBlur={() => setIsFocused(false)}
                      onFocus={() => setIsFocused(true)}
                      placeholder={`Search`}
                      className=" table_search_input w-full rounded-md text-xs placeholder:text-xs h-full placeholder:text-text-sm placeholder:text-787878 pl-3"
                      type="text"
                      value={filterData?.searchValue}
                      onChange={(e) => {
                        setFilterData({
                          ...filterData,
                          searchValue: e.target.value,
                        });
                      }}
                    />
                    <span className="px-2">
                      <img
                        style={{
                          height: "25px",
                          width: "25px",
                        }}
                        src={search}
                        alt="search icon"
                      />
                    </span>
                  </div>
                </div>
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <div className="overflow-auto mt-1">
                    <table className="mt-2 w-full">
                      <thead className="sticky top-0 z-10 bg-F2F4F5">
                        <tr>
                          {tableHeading.map((heading, index) => (
                            <th
                              className={`tab-header whitespace-nowrap text-xs fw_500 text-475467 bg-F2F4F5 text-start`}
                              key={heading}
                            >
                              {heading}
                            </th>
                          ))}
                          {/* <th
                            className={`tab-header  sticky right-0 whitespace-nowrap text-sm fw_500 text-475467 bg-F2F4F5 text-center`}
                          >
                            Action
                          </th> */}
                        </tr>
                      </thead>
                      <tbody className="">
                        {values.data.map((item, index) => (
                          <tr key={index}>
                            <td
                              className={`tab-cell text-sm fw_400 text-secondary`}
                            >
                              <Field
                                name={`data[${index}].code`}
                                placeholder="Code"
                                disabled={disableArrayFields}
                                onChange={(e) =>
                                  handleFieldChange(
                                    `data[${index}]`,
                                    "code",
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                                className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2 w-28"
                              />
                            </td>
                            <td
                              className={`tab-cell text-sm fw_400 text-secondary`}
                            >
                              <Field
                                name={`data[${index}].name`}
                                placeholder="Name"
                                disabled={disableArrayFields}
                                onChange={(e) =>
                                  handleFieldChange(
                                    `data[${index}]`,
                                    "name",
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                                className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-32"
                              />
                            </td>
                            <td
                              className={`tab-cell text-sm fw_400 text-secondary`}
                            >
                              <Field
                                name={`data[${index}].ewb_unit`}
                                type="number"
                                placeholder="Unit"
                                disabled={disableArrayFields}
                                onChange={(e) =>
                                  handleFieldChange(
                                    `data[${index}]`,
                                    "ewb_unit",
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                                className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-20"
                              />
                            </td>
                            <td
                              className={`tab-cell text-sm fw_400 text-secondary`}
                            >
                              <Field
                                name={`data[${index}].length`}
                                type="number"
                                placeholder="Length"
                                disabled={disableArrayFields}
                                onChange={(e) =>
                                  handleFieldChange(
                                    `data[${index}]`,
                                    "length",
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                                className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-20"
                              />
                            </td>
                            <td
                              className={`tab-cell text-sm fw_400 text-secondary`}
                            >
                              <Field
                                name={`data[${index}].width`}
                                type="number"
                                placeholder="Width"
                                disabled={disableArrayFields}
                                onChange={(e) =>
                                  handleFieldChange(
                                    `data[${index}]`,
                                    "width",
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                                className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-20"
                              />
                            </td>
                            <td
                              className={`tab-cell text-sm fw_400 text-secondary`}
                            >
                              <Field
                                name={`data[${index}].height`}
                                type="number"
                                placeholder="Height"
                                disabled={disableArrayFields}
                                onChange={(e) =>
                                  handleFieldChange(
                                    `data[${index}]`,
                                    "height",
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                                className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-20"
                              />
                            </td>
                            <td
                              className={`tab-cell text-sm fw_400 text-secondary`}
                            >
                              <Field
                                name={`data[${index}].volume`}
                                type="number"
                                placeholder="Volume"
                                disabled={disableArrayFields}
                                onChange={(e) =>
                                  handleFieldChange(
                                    `data[${index}]`,
                                    "volume",
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                                className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-20"
                              />
                            </td>
                            <td
                              className={`tab-cell text-sm fw_400 text-secondary`}
                            >
                              <Field
                                name={`data[${index}].volume_uom`}
                                type="text"
                                placeholder="volume uom"
                                disabled={disableArrayFields}
                                onChange={(e) =>
                                  handleFieldChange(
                                    `data[${index}]`,
                                    "volume_uom",
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                                className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-28"
                              />
                            </td>
                            <td
                              className={`tab-cell text-sm fw_400 text-secondary`}
                            >
                              <Field
                                name={`data[${index}].weight`}
                                type="number"
                                placeholder="Weight"
                                disabled={disableArrayFields}
                                onChange={(e) =>
                                  handleFieldChange(
                                    `data[${index}]`,
                                    "weight",
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                                className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-20"
                              />
                            </td>

                            {/* <td
                              className={`tab-cell text-center text-sm fw_400 bg-white text-secondary sticky right-0`}
                            >
                              <button
                                onClick={() => {
                                  deleteFn();
                                }}
                              >
                                {" "}
                                <DeleteIcon />
                              </button>
                            </td> */}
                          </tr>
                        ))}

                        <tr>
                          <td
                            className={`tab-cell text-sm fw_400 text-secondary`}
                          >
                            <Field
                              ref={addRef}
                              name="newLine.code"
                              placeholder="Code"
                              disabled={disableNewFields}
                              onChange={(e) =>
                                handleFieldChange(
                                  "newLine",
                                  "code",
                                  e.target.value,
                                  setFieldValue
                                )
                              }
                              className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-28"
                              // autoFocus={addRef?.current?.focus()}
                              autoFocus={false}
                            />
                          </td>
                          <td
                            className={`tab-cell text-sm fw_400 text-secondary`}
                          >
                            <Field
                              name="newLine.name"
                              placeholder="Name"
                              disabled={disableNewFields}
                              onChange={(e) =>
                                handleFieldChange(
                                  "newLine",
                                  "name",
                                  e.target.value,
                                  setFieldValue
                                )
                              }
                              className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-32"
                            />
                          </td>
                          <td
                            className={`tab-cell text-sm fw_400 text-secondary`}
                          >
                            <Field
                              name="newLine.ewb_unit"
                              type="number"
                              placeholder="Unit"
                              disabled={disableNewFields}
                              onChange={(e) =>
                                handleFieldChange(
                                  "newLine",
                                  "ewb_unit",
                                  e.target.value,
                                  setFieldValue
                                )
                              }
                              className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-20"
                            />
                          </td>
                          <td
                            className={`tab-cell text-sm fw_400 text-secondary`}
                          >
                            <Field
                              name="newLine.length"
                              type="number"
                              placeholder="Length"
                              disabled={disableNewFields}
                              onChange={(e) =>
                                handleFieldChange(
                                  "newLine",
                                  "length",
                                  e.target.value,
                                  setFieldValue
                                )
                              }
                              className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-20"
                            />
                          </td>
                          <td
                            className={`tab-cell text-sm fw_400 text-secondary`}
                          >
                            <Field
                              name="newLine.width"
                              type="number"
                              placeholder="Width"
                              disabled={disableNewFields}
                              onChange={(e) =>
                                handleFieldChange(
                                  "newLine",
                                  "width",
                                  e.target.value,
                                  setFieldValue
                                )
                              }
                              className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-20"
                            />
                          </td>
                          <td
                            className={`tab-cell text-sm fw_400 text-secondary`}
                          >
                            <Field
                              name="newLine.height"
                              type="number"
                              placeholder="Height"
                              disabled={disableNewFields}
                              onChange={(e) =>
                                handleFieldChange(
                                  "newLine",
                                  "height",
                                  e.target.value,
                                  setFieldValue
                                )
                              }
                              className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-20"
                            />
                          </td>
                          <td
                            className={`tab-cell text-sm fw_400 text-secondary`}
                          >
                            <Field
                              name="newLine.volume"
                              type="number"
                              placeholder="Volume"
                              disabled={disableNewFields}
                              onChange={(e) =>
                                handleFieldChange(
                                  "newLine",
                                  "volume",
                                  e.target.value,
                                  setFieldValue
                                )
                              }
                              className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-20"
                            />
                          </td>
                          <td
                            className={`tab-cell text-sm fw_400 text-secondary`}
                          >
                            <Field
                              name="newLine.volume_uom"
                              type="text"
                              placeholder="Volume UOM"
                              disabled={disableNewFields}
                              onChange={(e) =>
                                handleFieldChange(
                                  "newLine",
                                  "volume_uom",
                                  e.target.value,
                                  setFieldValue
                                )
                              }
                              className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-28"
                            />
                          </td>
                          <td
                            className={`tab-cell text-sm fw_400 text-secondary`}
                          >
                            <Field
                              name="newLine.weight"
                              type="number"
                              placeholder="Weight"
                              disabled={disableNewFields}
                              onChange={(e) =>
                                handleFieldChange(
                                  "newLine",
                                  "weight",
                                  e.target.value,
                                  setFieldValue
                                )
                              }
                              className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-20"
                            />
                          </td>
                          {/* <td
                            className={`tab-cell text-sm fw_400 bg-white text-secondary sticky right-0`}
                          ></td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              <div className="flex justify-end w-full bg-white py-4 px-6 invoice-bottom-container">
                <AddButton
                  onclkFn={handleSubmit}
                  prefixIcon={false}
                  text="Save"
                  type="submit"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </InnerLayout>
  );
};

export default UnitOfMeasure;
