import React, { useEffect, useRef, useState } from "react";
import { customStylesForFilter } from "../../../components/reports/reports-layout";
import { inventoryReportAction } from "../../../redux/actions/report-action";
import { useDispatch } from "react-redux";
import ReportLayout from "../../../components/reports/report-layout";
import DeleteIcon from "../../../assets/icons/delete-icon";
import Select from "react-select";
import { PlusIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import SearchSelectFieldNonFormik from "../../../components/forms/search-select-field";
import {
  customerListAction,
  productListAction,
} from "../../../redux/actions/general-action";
import useItemHelper from "../../../components/helper-components/item-helper-function";
import {
  conditionsOptions,
  handleAddFilterRow,
  handleFilterRowChange,
  handleRemoveFilterRow,
  transformData,
} from "../report-helper-function";

const InventoryReport = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [overlayHeight, setOverlayHeight] = useState("85vh");
  const filterContainerRef = useRef(null);
  const [groupByOptions, setgroupByOptions] = useState([
    { label: "None", value: "" },
    { label: "Item Code", value: "oi.item_code" },
    { label: "Item Name", value: "oi.item_name" },
    { label: "Generic Name", value: "oi.generic_name" },
    { label: "Batch No", value: "oi.batch_no" },
    { label: "HSN Code", value: "oi.hsn_code" },
    { label: "Reorder Level", value: "oi.reorder_level" },
    { label: "Branch Name", value: "oi.branch_name" },
    { label: "Location Name", value: "oi.location_name" },
    { label: "Vendor Name", value: "oi.vendor_name" },
    { label: "Last Purchase Date", value: "oi.last_purchase_date" },
    { label: "Last Sale Date", value: "oi.last_sale_date" },
    { label: "Item Category", value: "oi.item_category" },
    { label: "Item Type", value: "oi.item_type" },
    { label: "Drug Group", value: "oi.drug_group" },
    { label: "Drug Category", value: "oi.drug_category" },
    { label: "Fast Moving Flag", value: "oi.fast_moving_flag" },
    { label: "Slow Moving Flag", value: "oi.slow_moving_flag" },
    { label: "Out of Stock Flag", value: "oi.out_of_stock_flag" },
  ]);

  const { dropdownOptions } = useItemHelper();

  const item_codeOptions = [
    { label: "Equal to", value: "equal_to" },
    { label: "Range", value: "range" },
  ];
  const [tableData, setTableData] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState(tableHeading);
  const [openMoreFilter, setOpenMoreFilter] = useState(false);
  const [filters, setFilters] = useState({
    filter_by: "",
    filters: [
      { column: null, condition: null, value: "", from: null, to: null },
    ],
    selectedColumns: selectedColumns.map((column) => tableColoumsn[column]),
    from: null,
    to: null,
    group_by: "",
  });

  const initFilter = {
    filter_by: "",
    filters: [
      { column: null, condition: null, value: "", from: null, to: null },
    ],
    selectedColumns: selectedColumns.map((column) => tableColoumsn[column]),
    from: null,
    to: null,
    group_by: "",
  };
  function ApplyFilterFn(filters) {
    setLoading(true);
    dispatch(inventoryReportAction(filters, onSuccess, onError));
  }

  const onSuccess = (data) => {
    setLoading(false);
    const transformedData = transformData(data?.data?.result, tableColoumsn);
    setTableData(transformedData);
    setOpenMoreFilter(false);
  };

  const onError = (data) => {
    setLoading(false);
    setOpenMoreFilter(false);
  };

  useEffect(() => {
    if (openMoreFilter && filterContainerRef.current) {
      const filterContainerHeight = filterContainerRef.current.clientHeight;
      setOverlayHeight(`calc(85vh - ${filterContainerHeight}px)`);
    } else {
      setOverlayHeight("85vh");
    }
  }, [openMoreFilter]);

  useEffect(() => {
    ApplyFilterFn(filters);
  }, []);

  return (
    <ReportLayout
      tableData={tableData}
      availableColumns={tableHeading}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      groupByOptions={groupByOptions}
      handleApply={(filters) => {
        ApplyFilterFn(filters);
      }}
      openMoreFilter={openMoreFilter}
      setOpenMoreFilter={setOpenMoreFilter}
      filters={filters}
      setFilters={setFilters}
      title={"Inventory Report"}
      initFilter={initFilter}
    >
      {openMoreFilter && (
        <div className="flex flex-col gap-3 pt-5 mx-6" ref={filterContainerRef}>
          {filters?.filters.map((row, index) => (
            <div key={index} className="flex gap-3">
              <div
                style={{ maxWidth: "11rem", height: "2.6rem" }}
                className={`w-full flex items-center rounded-md input_border`}
              >
                <Select
                  className="border-0 w-full"
                  menuPortalTarget={document.body}
                  styles={customStylesForFilter}
                  value={filterOptions?.filter(
                    (item) => item?.value === row?.column
                  )}
                  options={filterOptions}
                  placeholder="Filters"
                  onChange={(selectedOption) =>
                    handleFilterRowChange(
                      index,
                      "column",
                      selectedOption?.value,
                      setFilters,
                      filters
                    )
                  }
                />
              </div>
              <div
                style={{ maxWidth: "11rem", height: "2.6rem" }}
                className={`w-full flex items-center rounded-md input_border`}
              >
                <Select
                  className="border-0 w-full"
                  menuPortalTarget={document.body}
                  styles={customStylesForFilter}
                  value={conditionsOptions.filter(
                    (item) => item.value === row.condition
                  )}
                  options={conditionsOptions?.filter((opt) =>
                    filterOptions
                      ?.find((option) => option.value === row.column)
                      ?.operators?.includes(opt?.value)
                  )}
                  placeholder="Conditions"
                  onChange={(selectedOption) =>
                    handleFilterRowChange(
                      index,
                      "condition",
                      selectedOption?.value,
                      setFilters,
                      filters
                    )
                  }
                />
              </div>

              {row?.condition === "range" ? (
                <>
                  {filterOptions?.find((option) => option.value === row.column)
                    ?.valueField === "search" ? (
                    <>
                      <SearchSelectFieldNonFormik
                        isMulti={true}
                        value={{
                          label: row?.from ? (
                            row?.from
                          ) : (
                            <p className="text-667085 text-sm">
                              Search and Select Item..
                            </p>
                          ),
                          value: row?.from,
                        }}
                        handleChange={(selectedOption, actionMeta) => {
                          console.log(selectedOption, "dfgfdgddfdg");
                          setFilters({
                            ...filters,
                            filters: filters?.filters.map((row, idx) =>
                              idx === index
                                ? {
                                    ...row,
                                    from: selectedOption?.value,
                                  }
                                : row
                            ),
                          });
                        }}
                        action={
                          filterOptions?.find(
                            (option) => option.value === row.column
                          )?.action
                        }
                        field={
                          filterOptions?.find(
                            (option) => option.value === row.column
                          )?.field
                        }
                      />

                      <SearchSelectFieldNonFormik
                        value={{
                          label: row?.to ? (
                            row?.to
                          ) : (
                            <p className="text-667085 text-sm">
                              Search and Select Item..
                            </p>
                          ),
                          value: row?.to,
                        }}
                        handleChange={(selectedOption, actionMeta) => {
                          console.log(selectedOption, "dfgfdgddfdg");
                          setFilters({
                            ...filters,
                            filters: filters?.filters.map((row, idx) =>
                              idx === index
                                ? {
                                    ...row,
                                    to: selectedOption?.value,
                                  }
                                : row
                            ),
                          });
                        }}
                        action={
                          filterOptions?.find(
                            (option) => option.value === row.column
                          )?.action
                        }
                        field={
                          filterOptions?.find(
                            (option) => option.value === row.column
                          )?.field
                        }
                      />
                    </>
                  ) : (
                    <>
                      <div
                        style={{ maxWidth: "15rem", height: "2.6rem" }}
                        className={`w-full flex items-center rounded-md input_border `}
                      >
                        <input
                          min={0}
                          placeholder="From"
                          className="table_search_input w-full rounded-md fs_12 h-full placeholder:text-fs_12 placeholder:text-787878 px-3"
                          type={"number"}
                          value={row.from}
                          onChange={(e) =>
                            handleFilterRowChange(
                              index,
                              "from",
                              e.target.value,
                              setFilters,
                              filters
                            )
                          }
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "Subtract") {
                              e.preventDefault(); // Prevent minus key
                            }
                          }}
                        />
                      </div>
                      <div
                        style={{ maxWidth: "15rem", height: "2.6rem" }}
                        className={`w-full flex items-center rounded-md input_border`}
                      >
                        <input
                          min={0}
                          placeholder="To"
                          className="table_search_input w-full rounded-md fs_12 h-full placeholder:text-fs_12 placeholder:text-787878 px-3"
                          type={"number"}
                          value={row.to}
                          onChange={(e) =>
                            handleFilterRowChange(
                              index,
                              "to",
                              e.target.value,
                              setFilters,
                              filters
                            )
                          }
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "Subtract") {
                              e.preventDefault(); // Prevent minus key
                            }
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : filterOptions?.find((option) => option.value === row.column) // Find the column in filterOptions
                  ?.valueField === "search" ? (
                <>
                  <SearchSelectFieldNonFormik
                    isMulti={row?.condition === "IN" ? true : false}
                    // value={{
                    //   label: row?.value ? (
                    //     row?.value
                    //   ) : (
                    //     <p className="text-667085 text-sm">
                    //       Search and Select Item..
                    //     </p>
                    //   ),
                    //   value: row?.value ?? row?.value,
                    // }}
                    value={
                      row?.condition === "IN"
                        ? row?.value?.map((item) => ({
                            label: item,
                            value: item,
                          })) || [
                            {
                              label: <p className="text-667085 text-sm">Search and Select Items...</p>,
                              value: null,
                            },
                          ]
                        : {
                            label: row?.value || (
                              <p className="text-667085 text-sm">Search and Select Item...</p>
                            ),
                            value: row?.value,
                          }
                    }
                    // handleChange={(selectedOption, actionMeta) => {
                    //   setFilters({
                    //     ...filters,
                    //     filters: filters?.filters.map((row, idx) =>
                    //       idx === index
                    //         ? {
                    //             ...row,
                    //             value: selectedOption?.value,
                    //           }
                    //         : row
                    //     ),
                    //   });
                    // }}
                    handleChange={(selectedOption, actionMeta) => {
                      setFilters({
                        ...filters,
                        filters: filters?.filters.map((row, idx) =>
                          idx === index
                            ? {
                                ...row,
                                value: row?.condition === "IN"
                                  ? selectedOption?.map((option) => option.value)
                                  : selectedOption?.value,
                              }
                            : row
                        ),
                      });
                    }}
                    action={
                      filterOptions?.find(
                        (option) => option.value === row.column
                      )?.action
                    }
                    field={
                      filterOptions?.find(
                        (option) => option.value === row.column
                      )?.field
                    }
                  />
                </>
              ) : filterOptions?.find((option) => option.value === row.column) // Find the column in filterOptions
                  ?.valueField === "dropdown" ? (
                <div
                  style={{ maxWidth: "15rem", height: "2.6rem" }}
                  className={`w-full flex items-center rounded-md input_border`}
                >
                  <Select
                    className="border-0 w-full"
                    menuPortalTarget={document.body}
                    styles={customStylesForFilter}
                    options={
                      dropdownOptions[
                        filterOptions?.find(
                          (option) => option.value === row.column
                        )?.field
                      ]
                    }
                    placeholder="Select"
                    onChange={(selectedOption) =>
                      handleFilterRowChange(
                        index,
                        "value",
                        selectedOption?.value,
                        setFilters,
                        filters
                      )
                    }
                  />
                </div>
              ) : (
                <div
                  style={{ maxWidth: "15rem", height: "2.6rem" }}
                  className={`w-full flex items-center rounded-md input_border`}
                >
                  <input
                    min={0}
                    placeholder="Enter Values"
                    className="table_search_input w-full rounded-md fs_12 h-full placeholder:text-fs_12 placeholder:text-787878 px-3"
                    type={
                      filterOptions?.find((opt) => opt?.value === row?.column)
                        ?.valueField
                    }
                    value={row.value}
                    onChange={(e) =>
                      handleFilterRowChange(
                        index,
                        "value",
                        e.target.value,
                        setFilters,
                        filters
                      )
                    }
                    onKeyDown={(e) => {
                      if (
                        e.key === "-" ||
                        (e.key === "Subtract" &&
                          filterOptions?.find(
                            (opt) => opt?.value === row?.column
                          )?.valueField === "number")
                      ) {
                        e.preventDefault(); // Prevent minus key
                      }
                    }}
                  />
                </div>
              )}

              {index === filters?.filters.length - 1 ? (
                <PlusIcon
                  onClick={() => {
                    handleAddFilterRow(setFilters, filters);
                  }}
                  className={`w-5 text-primary cursor-pointer`}
                />
              ) : (
                <div
                  className="mt-3"
                  onClick={() =>
                    handleRemoveFilterRow(index, setFilters, filters)
                  }
                >
                  <DeleteIcon className="cursor-pointer" />
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {openMoreFilter ? (
        <div
          className="mt-6 w-full flex bg-[#0000005a] relative z-2"
          style={{ height: overlayHeight }}
          onClick={() => {
            setOpenMoreFilter(!openMoreFilter);
          }}
        ></div>
      ) : (
        <>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div
              className="mt-3"
              style={{
                maxWidth: "100%",
                maxHeight: "87vh",
                overflowX: "auto",
                overflowY: "auto",
              }}
            >
              <table className="w-full">
                <thead className="bg-F2F4F5 sticky top-0">
                  <tr>
                    <th
                      className={`tab-header whitespace-nowrap text-sm fw_500 text-475467 bg-F2F4F5 text-start`}
                    >
                      Sr No
                    </th>
                    {tableHeading.map((heading, index) => (
                      <th
                        className={`tab-header whitespace-nowrap text-sm fw_500 text-475467 bg-F2F4F5 text-start`}
                        key={heading}
                      >
                        {heading}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item, index) => (
                    <tr key={index}>
                      <td className="tab-cell text-sm fw_400 text-secondary">
                        {index + 1}
                      </td>
                      {tableHeading.map((heading, idx) => (
                        <td
                          className="tab-cell text-sm fw_400 text-secondary text-nowrap"
                          key={idx}
                        >
                          {item[heading]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </ReportLayout>
  );
};

const tableHeading = [
  // Primary Columns (Frozen and displayed by default)
  "ItemCode",
  "ItemName",
  "GenericName",
  "BatchNo",
  "HsnCode",
  "OpeningStock",
  "ClosingStock",
  "CurrentQty",
  "StockIn",
  "StockOut",
  "ExpiredStockQty",
  "NearExpiryQty",
  "ReorderLevel (Min Qty - Max Qty)",
  "MRP",
  "PurchaseRate",
  "SalesRate",
  "GrossAmount",
  "Discount Percentage",
  "Discount Amount",
  "Tax Percentage",
  "Tax Amount",
  "NetAmount",
  "ProfitAmount",
  "Purchase InvoiceDate",
  "LastPurchaseDate",
  "LastSaleDate",
  "ExpiryDate",
  "BranchName",
  "LocationName",
  "VendorName",

  "ItemCategory",
  "ItemType",
  "DrugGroup",
  "DrugCategory",
  "PurchaseQty",
  "SalesQty",
  "ReturnQty",
  "StockAdjustmentQty",
  "AveragePurchaseCost",
  "InventoryHoldingCost",
  "FastMovingFlag",
  "SlowMovingFlag",
  "OutOfStockFlag",

  // New calculated columns
  "DaysToExpiry",
  "LeadTime",
  "CostOfGoodsSold (COGS)(calculated)",
  "AverageDaysInInventory (calculated)",
  "CurrentStock (calculated)",
  "AverageSalesPerDay (calculated)",
  "DaysOfInventory (calculated)",
  "ReorderPoint (calculated)",
  "StockValue (calculated)",
  "PotentialSalesValue (calculated)",
  "StockTurnoverRatio (calculated)",
  "NetSalesQty (calculated)",
  "NetPurchaseQty (calculated)",
  "DaysSinceLastPurchase",
];


const tableColoumsn = {
  // Primary Columns
  ItemCode: "oi.item_code",
  ItemName: "oi.item_name",
  GenericName: "oi.generic_name",
  BatchNo: "oi.batch_no",
  HsnCode: "oi.hsn_code",
  OpeningStock: "oi.opening_stock",
  ClosingStock: "oi.closing_stock",
  CurrentQty: "oi.current_qty",
  StockIn: "oi.stock_in",
  StockOut: "oi.stock_out",
  ExpiredStockQty: "oi.expired_stock_qty",
  NearExpiryQty: "oi.near_expiry_qty",
  "ReorderLevel (Min Qty - Max Qty)": "oi.reorder_level",
  MRP: "oi.mrp",
  PurchaseRate: "oi.purchase_rate",
  SalesRate: "oi.sales_rate",
  GrossAmount: "oi.gross_amount",
  "Discount Percentage": "oi.discount_percentage",
  "Discount Amount": "oi.discount_amount",
  "Tax Percentage": "oi.tax_percentage",
  "Tax Amount": "oi.tax_amount",
  NetAmount: "oi.net_amount",
  ProfitAmount: "oi.profit_amount",
  "Purchase InvoiceDate": "oi.purchase_invoice_date",
  LastPurchaseDate: "oi.last_purchase_date",
  LastSaleDate: "oi.last_sale_date",
  ExpiryDate: "oi.expiry_date",
  BranchName: "oi.branch_name",
  LocationName: "oi.location_name",
  VendorName: "oi.vendor_name",

  // Secondary Columns
  ItemCategory: "oi.item_category",
  ItemType: "oi.item_type",
  DrugGroup: "oi.drug_group",
  DrugCategory: "oi.drug_category",
  PurchaseQty: "oi.purchase_qty",
  SalesQty: "oi.sales_qty",
  ReturnQty: "oi.return_qty",
  StockAdjustmentQty: "oi.stock_adjustment_qty",
  AveragePurchaseCost: "oi.average_purchase_cost",
  InventoryHoldingCost: "oi.inventory_holding_cost",
  FastMovingFlag: "oi.fast_moving_flag",
  SlowMovingFlag: "oi.slow_moving_flag",
  OutOfStockFlag: "oi.out_of_stock_flag",

  // New calculated columns
  DaysToExpiry: "oi.days_to_expiry",
  LeadTime: "oi.lead_time",
  CostOfGoodsSold: "oi.cogs", // COGS (calculated)
  AverageDaysInInventory: "oi.average_days_in_inventory", // calculated
  CurrentStock: "oi.current_stock", // calculated
  AverageSalesPerDay: "oi.average_sales_per_day", // calculated
  DaysOfInventory: "oi.days_of_inventory", // calculated
  ReorderPoint: "oi.reorder_point", // calculated
  StockValue: "oi.stock_value", // calculated
  PotentialSalesValue: "oi.potential_sales_value", // calculated
  StockTurnoverRatio: "oi.stock_turnover_ratio", // calculated
  NetSalesQty: "oi.net_sales_qty", // calculated
  NetPurchaseQty: "oi.net_purchase_qty", // calculated
  DaysSinceLastPurchase: "oi.days_since_last_purchase",
};


const filterOptions = [
  // Product Information Filters
  {
    label: "Item Code",
    value: "oi.item_code",
    operators: ["equal_to", "IN", "LIKE"],
    valueField: "search",
    action: productListAction,
    field: "item_code",
  },
  {
    label: "Item Name",
    value: "oi.item_name",
    operators: ["equal_to", "IN", "LIKE"],
    valueField: "search",
    action: productListAction,
    field: "item_name",
  },
  {
    label: "Generic Name",
    value: "oi.generic_name",
    operators: ["equal_to", "IN", "LIKE"],
    valueField: "search",
    action: productListAction,
    field: "generic_name",
  },
  {
    label: "Batch No",
    value: "oi.batch_no",
    operators: ["equal_to", "IN", "LIKE"],
    valueField: "text",
  },
  {
    label: "HSN Code",
    value: "oi.hsn_code",
    operators: ["equal_to", "IN"],
    valueField: "text",
  },

  // Stock Filters
  {
    label: "Current Qty",
    value: "so.current_qty",
    operators: ["greater_than", "less_than", "range"],
    valueField: "number",
  },
  {
    label: "Stock In",
    value: "so.stock_in",
    operators: ["greater_than", "less_than", "range"],
    valueField: "number",
  },
  {
    label: "Stock Out",
    value: "so.stock_out",
    operators: ["greater_than", "less_than", "range"],
    valueField: "number",
  },
  {
    label: "Near Expiry Qty",
    value: "so.near_expiry_qty",
    operators: ["greater_than", "less_than", "range"],
    valueField: "number",
  },
  {
    label: "Expired Stock Qty",
    value: "so.expired_stock_qty",
    operators: ["greater_than", "less_than", "range"],
    valueField: "number",
  },

  // Location Filters
  {
    label: "Branch Name",
    value: "oi.branch_name",
    operators: ["equal_to", "IN"],
    valueField: "dropdown",
  },
  {
    label: "Location Name",
    value: "oi.location_name",
    operators: ["equal_to", "IN"],
    valueField: "dropdown",
  },
  {
    label: "Vendor Name",
    value: "oi.cmr_name",
    operators: ["equal_to", "IN"],
    valueField: "search",
    action: customerListAction,
  },

  // Category Filters
  {
    label: "Item Category",
    value: "oi.item_category",
    operators: ["equal_to", "IN"],
    valueField: "dropdown",
    field: "catList",
  },
  {
    label: "Item Type",
    value: "oi.item_type",
    operators: ["equal_to", "IN"],
    valueField: "dropdown",
    field: "itemType",
  },
  {
    label: "Item Group",
    value: "oi.item_group",
    operators: ["equal_to", "IN"],
    valueField: "dropdown",
    field: "itemGroup",
  },

  // Financial Filters
  {
    label: "Gross Amount",
    value: "oi.gross_amount",
    operators: ["greater_than", "less_than", "range"],
    valueField: "number",
  },
  {
    label: "Discount",
    value: "oi.discount",
    operators: ["greater_than", "less_than", "range"],
    valueField: "number",
  },
  {
    label: "Net Amount",
    value: "oi.net_amount",
    operators: ["greater_than", "less_than", "range"],
    valueField: "number",
  },
  {
    label: "Profit Amount",
    value: "oi.profit_amount",
    operators: ["greater_than", "less_than", "range"],
    valueField: "number",
  },

  // Additional Recommended Filters and Operators
  {
    label: "Reorder Level",
    value: "oi.reorder_level",
    operators: ["less_than", "equal_to", "range"],
    valueField: "number",
  },
  {
    label: "Average Sales Per Day",
    value: "oi.avg_sales_per_day",
    operators: ["greater_than", "less_than", "range"],
    valueField: "number",
  },
  {
    label: "Days of Inventory",
    value: "oi.days_of_inventory",
    operators: ["greater_than", "less_than", "range"],
    valueField: "number",
  },
  {
    label: "Potential Sales Value",
    value: "oi.potential_sales_value",
    operators: ["greater_than", "less_than", "range"],
    valueField: "number",
  },
  {
    label: "Stock Turnover Ratio",
    value: "oi.stock_turnover_ratio",
    operators: ["greater_than", "less_than", "range"],
    valueField: "number",
  },
  {
    label: "Average Days in Inventory",
    value: "oi.avg_days_in_inventory",
    operators: ["greater_than", "less_than", "range"],
    valueField: "number",
  },
];

export default InventoryReport;
