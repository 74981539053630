import { Field, useFormikContext } from "formik";
import React, { useRef } from "react";
import TextAreaField from "../forms/textarea-field";
import SelectField from "../forms/select-field";
import { url } from "../../redux/config";
import { api } from "../../redux/apis/api";
import { getBackgroundColor } from "../helper-components/helper-function";

const BillingFinalTable = ({ disabled = false, payModesList }) => {
  const { values, setFieldValue } = useFormikContext();
  const inputRef1 = useRef(null);
  const totalInvoiceAmount = calculateTotal(values);
  const getAvailablePaymentModes = (index) => {
    const selectedPaymentModes = values.paymentData
      .filter((_, i) => i !== index)
      .map((item) => item.payment_mode_name);
    return payModesList.filter(
      (mode) => !selectedPaymentModes.includes(mode.label)
    );
  };

  const totalPaymentAmount =
    parseFloat(values.paymentData[0]?.payment_amount ?? 0) +
    parseFloat(values.paymentData[1]?.payment_amount ?? 0) +
    parseFloat(values.paymentData[2]?.payment_amount ?? 0);

  console.log(totalPaymentAmount, "sdfdsfsdfdsfdsfsdfsdfsdf");

  const initPayment = (data, amount, index) => {
    const options = {
      key: "rzp_test_0tpemkHKm5K1Bc",
      currency: data.currency,
      amount: data.amount,
      name: "ZOTA POS",
      handler: async function (response) {
        response.order_id = data.id;
        const id = response?.razorpay_payment_id;
        setFieldValue(`paymentData[${index}].transaction`, id);
      },
      prefill: {
        name: "Rajat",
        email: "rajat@rajat.com",
        phone_number: "9899999999",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handlePayment = async (amount, index) => {
    try {
      const orderUrl = `${url}/order/payment`;
      const response = await api.post(orderUrl, {
        amount: amount,
      });
      initPayment(response?.data?.order, amount, index);
    } catch (error) {}
  };

  return (
    <div className="table-container flex">
      <div
        style={{
          minWidth: `calc(100% - 16rem)`,
        }}
        className="bg-F2F4F5  flex "
      >
        <div className="w-[40%] border-r-EAECF0 border-r-2 px-4 py-3 ">
          <span className="text-475467 fw_600 text-sm">Remarks</span>
          <TextAreaField
            defaultInputClass="textAreaField text-secondary focus_outline__none"
            autoFocus={false}
            errMsgContainerClass=""
            isDisabled={disabled}
            containerClass="mt-2 text-secondary"
            placeholder="Enter Remarks"
            name="remarks"
            id={"remarks"}
            isRequired={false}
            labelWidth="7rem"
            // feildHeight="5.6rem"
          />
        </div>

        <div className="w-[40%] border-r-EAECF0 border-r-2 px-2 py-3 ">
          <span className="text-475467  fw_600 text-sm">Payment method</span>
          <div className="mt-2 flex flex-col gap-2 w-full">
            <div className="flex gap-2 w-full">
              <div
                style={{
                  width: "30%",
                }}
              >
                <SelectField
                  general_color={false}
                  handleSelected={(sel) => {
                    if (
                      !(
                        parseFloat(values?.paymentData[1]?.payment_amount) >
                          0 ||
                        parseFloat(values?.paymentData[2]?.payment_amount) > 0
                      )
                    )
                      setFieldValue(
                        `paymentData[${0}].payment_amount`,
                        totalInvoiceAmount
                      );
                    setFieldValue(`paymentData[${0}].tender_check`, false);
                    setFieldValue(`paymentData[${1}].tender_check`, false);
                    setFieldValue(`paymentData[${2}].tender_check`, false);
                  }}
                  defaultSelectClass="text-sm fw_500 fm_inter text-030304 h-[2.5rem] focus_outline__none "
                  isDisabled={disabled || totalInvoiceAmount <= 0}
                  defaultValue={
                    values.paymentData[0]?.payment_mode_name && {
                      label: values.paymentData[0]?.payment_mode_name,
                    }
                  }
                  errMsgContainerClass="flex justify-start hidden"
                  id={`paymentData[${0}].payment_mode_name`}
                  isContainer={false}
                  options={getAvailablePaymentModes(0)}
                  name={`paymentData[${0}].payment_mode_name`}
                  placeholder={"Select"}
                  style={{
                    control: (base, state) => ({
                      ...base,
                      color: "#0D111A",
                      height: "100%",
                      minHeight: "",
                      fontFamily: "Poppins,sans-serif ",
                      fontWeight: "400",
                      fontSize: "14px",
                      borderRadius: "10px",
                      width: "100%",
                      backgroundColor: state.isDisabled
                        ? "var(--disabled-color)"
                        : base.backgroundColor,
                      borderColor: state.isFocused ? "#277dfe" : "transparent",
                      boxShadow: state.isFocused ? null : null,
                      "&:hover": {},
                    }),
                    input: (base) => ({
                      ...base,
                      margin: "0",
                      padding: "0",
                    }),
                  }}
                />
              </div>
              <div
                style={{
                  height: "2.5rem",
                }}
                className={` w-[40%]  relative`}
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  ₹
                </span>
                <Field name={`paymentData[${0}].payment_amount`}>
                  {({ field, form }) => (
                    <input
                      {...field}
                      value={field.value || ""} // Ensure controlled value
                      ref={inputRef1}
                      disabled={
                        disabled ||
                        totalInvoiceAmount <= 0 ||
                        // values?.paymentData[0]?.transaction ||
                        !values?.paymentData[0]?.payment_mode_name
                      }
                      placeholder=""
                      name={`paymentData[${0}].payment_amount`}
                      type="number"
                      className={` w-full h-full text-sm   pl-7 fw_600 focus_outline__none input-field-no-spin-buttons rounded-lg border-D0D5DD border focus:border-primary focus:border bg-white disabled:bg-disabled-color `}
                      onChange={(e) => {
                        const value = e?.target?.value;
                        form.setFieldValue(field.name, value);
                        if (Number(value) >= totalInvoiceAmount) {
                          if (!values?.paymentData[1]?.transaction) {
                            setFieldValue(
                              `paymentData[${1}].payment_amount`,
                              null
                            );
                            setFieldValue(
                              `paymentData[${1}].payment_mode_name`,
                              ""
                            );
                          }
                          if (!values?.paymentData[2]?.transaction) {
                            setFieldValue(
                              `paymentData[${2}].payment_amount`,
                              null
                            );
                            setFieldValue(
                              `paymentData[${2}].payment_mode_name`,
                              ""
                            );
                          }
                        } else if (!values?.paymentData[1]?.transaction) {
                          setFieldValue(
                            `paymentData[${1}].payment_amount`,
                            totalInvoiceAmount - Number(value)
                          );
                        }
                        setFieldValue(`paymentData[${0}].tender_check`, false);
                      }}
                    />
                  )}
                </Field>
              </div>

              {values?.paymentData[0]?.payment_mode_name === "Cash" &&
                (totalInvoiceAmount < totalPaymentAmount ||
                  values?.paymentData[0]?.tender_check === true) && (
                  <div
                    style={{
                      backgroundColor: getBackgroundColor(
                        values?.paymentData[0]?.tender_check
                      ),
                    }}
                    className={`  px-2 flex gap-2 items-center rounded-lg text-nowrap  ${
                      values?.paymentData[0]?.tender_check === true
                        ? "border border-primary"
                        : "border border-D0D5DD"
                    }`}
                  >
                    <Field name={`paymentData[${0}].tender_check`}>
                      {({ field }) => (
                        <input
                          {...field}
                          checked={field.value}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setFieldValue(
                              `paymentData[${0}].tender_check`,
                              isChecked
                            );
                            if (isChecked) {
                              setFieldValue(
                                `paymentData[${0}].payment_amount`,
                                parseFloat(
                                  values?.paymentData[0]?.payment_amount
                                ) -
                                  (parseFloat(totalPaymentAmount) -
                                    parseFloat(totalInvoiceAmount))
                              );
                            }
                            //  else {
                            //   setFieldValue(
                            //     `paymentData[${0}].payment_amount`,
                            //     totalInvoiceAmount
                            //   ); // Reset payment_amount if unchecked
                            // }
                          }}
                          type="checkbox"
                        />
                      )}
                    </Field>

                    <span
                      className={` ${
                        values?.paymentData[0]?.tender_check === true
                          ? "text-primary"
                          : "text-F51D00"
                      }`}
                    >
                      -{" "}
                      {parseFloat(totalPaymentAmount) -
                        parseFloat(totalInvoiceAmount)}
                    </span>
                  </div>
                )}
              {/* <div className="min-w-[20%] h-[2.4rem]">
                {values?.paymentData[0]?.transaction ? (
                  <>
                    <div className="w-[2.5rem] bg-22B378 rounded-md h-full flex items-center justify-center">
                      <SuccessIcon />
                    </div>
                  </>
                ) : (
                  <>
                    {values?.paymentData[0]?.payment_mode_name !== "Cash" &&
                      !disabled && (
                        <button
                          type="button"
                          onClick={() => {
                            if (
                              parseFloat(
                                values?.paymentData[0]?.payment_amount
                              ) <= calculateTotal(values)
                            ) {
                              handlePayment(
                                values?.paymentData[0]?.payment_amount,
                                0
                              );
                            } else {
                              errorToast("Amount is not matching");
                            }
                          }}
                          className="w-full h-full  bg-primary rounded-md flex justify-center items-center"
                        >
                          <PagRightIcon
                            width="22"
                            height="22"
                            fill="#ffffff "
                          />
                        </button>
                      )}
                  </>
                )}
              </div> */}
            </div>
            {/* 2222222222222222222222 */}
            {parseFloat(values?.paymentData[0]?.payment_amount) <
              totalInvoiceAmount &&
              values?.paymentData[0]?.payment_amount >= 0 && (
                //  ||
                // values?.paymentData[1]?.transaction
                <div className="flex gap-2 ">
                  <div
                    style={{
                      width: "30%",
                    }}
                  >
                    <SelectField
                      general_color={false}
                      handleSelected={() => {
                        setFieldValue(`paymentData[${0}].tender_check`, false);
                        setFieldValue(`paymentData[${1}].tender_check`, false);
                        setFieldValue(`paymentData[${2}].tender_check`, false);
                      }}
                      defaultSelectClass="text-sm fw_500 fm_inter text-030304 h-[2.5rem] focus_outline__none "
                      isDisabled={
                        disabled || totalInvoiceAmount <= 0
                        // ||
                        // values?.paymentData[1]?.transaction
                      }
                      defaultValue={
                        values.paymentData[1]?.payment_mode_name && {
                          label: values.paymentData[1]?.payment_mode_name,
                        }
                      }
                      errMsgContainerClass="flex justify-start hidden"
                      id={`paymentData[${1}].payment_mode_name`}
                      isContainer={false}
                      options={getAvailablePaymentModes(1)}
                      name={`paymentData[${1}].payment_mode_name`}
                      placeholder={"Select"}
                      style={{
                        control: (base, state) => ({
                          ...base,
                          color: "#0D111A",
                          height: "100%",
                          minHeight: "",
                          fontFamily: "Poppins,sans-serif ",
                          fontWeight: "400",
                          fontSize: "14px",
                          borderRadius: "10px",
                          width: "100%",
                          backgroundColor: state.isDisabled
                            ? "#EAECF0"
                            : base.backgroundColor,
                          borderColor: state.isFocused
                            ? "#277dfe"
                            : "transparent",
                          boxShadow: state.isFocused ? null : null,
                          "&:hover": {},
                        }),
                        input: (base) => ({
                          ...base,
                          margin: "0",
                          padding: "0",
                        }),
                      }}
                    />
                  </div>
                  <div
                    style={{
                      height: "2.5rem",
                    }}
                    className={` w-[40%]  relative`}
                  >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      ₹
                    </span>
                    <Field name={`paymentData[${1}].payment_amount`}>
                      {({ field, form }) => (
                        <input
                          {...field}
                          value={field.value || ""}
                          disabled={
                            disabled ||
                            totalInvoiceAmount <= 0 ||
                            // values?.paymentData[1]?.transaction ||
                            !values?.paymentData[1]?.payment_mode_name
                          }
                          placeholder=""
                          name={`paymentData[${1}].payment_amount`}
                          type="number"
                          className={` w-full h-full pl-7 fw_600 focus_outline__none input-field-no-spin-buttons  rounded-lg border-D0D5DD border focus:border-primary focus:border bg-white disabled:bg-EAECF0 `}
                          onChange={(e) => {
                            const value = e?.target?.value;
                            form.setFieldValue(field.name, value);
                            if (
                              Number(value) +
                                parseFloat(
                                  values?.paymentData?.[0]?.payment_amount
                                ) >=
                              totalInvoiceAmount
                            ) {
                              // if (!values?.paymentData[2]?.transaction) {
                              setFieldValue(
                                `paymentData[${2}].payment_amount`,
                                null
                              );
                              setFieldValue(
                                `paymentData[${2}].payment_mode_name`,
                                ""
                              );
                              // }
                            }
                            // if (!values?.paymentData[2]?.transaction)
                            else {
                              setFieldValue(
                                `paymentData[${2}].payment_amount`,
                                totalInvoiceAmount -
                                  (Number(value) +
                                    parseFloat(
                                      values.paymentData[0]?.payment_amount
                                    ))
                              );
                            }
                            setFieldValue(
                              `paymentData[${0}].tender_check`,
                              false
                            );
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  {values?.paymentData[1]?.payment_mode_name === "Cash" &&
                    (totalInvoiceAmount < totalPaymentAmount ||
                      values?.paymentData[1]?.tender_check === true) && (
                      <div
                        style={{
                          backgroundColor: getBackgroundColor(
                            values?.paymentData[1]?.tender_check
                          ),
                        }}
                        className={`  px-2 flex gap-2 items-center rounded-lg text-nowrap  ${
                          values?.paymentData[1]?.tender_check === true
                            ? "border border-primary"
                            : "border border-D0D5DD"
                        }`}
                      >
                        <Field name={`paymentData[${1}].tender_check`}>
                          {({ field }) => (
                            <input
                              {...field}
                              checked={field.value}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                setFieldValue(
                                  `paymentData[${1}].tender_check`,
                                  isChecked
                                );
                                if (isChecked) {
                                  setFieldValue(
                                    `paymentData[${1}].payment_amount`,
                                    parseFloat(
                                      values?.paymentData[1]?.payment_amount
                                    ) -
                                      (parseFloat(totalPaymentAmount) -
                                        parseFloat(totalInvoiceAmount))
                                  );
                                }
                              }}
                              type="checkbox"
                            />
                          )}
                        </Field>

                        <span
                          className={` ${
                            values?.paymentData[1]?.tender_check === true
                              ? "text-primary"
                              : "text-F51D00"
                          }`}
                        >
                          -{" "}
                          {parseFloat(totalPaymentAmount) -
                            parseFloat(totalInvoiceAmount)}
                        </span>
                      </div>
                    )}

                  {/* <div className="min-w-[20%] h-[2.4rem]">
                    {values?.paymentData[1]?.transaction ? (
                      <>
                        <div className="w-[2.5rem] bg-22B378 rounded-md h-full flex items-center justify-center">
                          <SuccessIcon />
                        </div>
                      </>
                    ) : (
                      <>
                        {values?.paymentData[1]?.payment_mode_name !== "Cash" &&
                          !disabled && (
                            <button
                              type="button"
                              onClick={() => {
                                handlePayment(
                                  values?.paymentData[1]?.payment_amount,
                                  1
                                );
                              }}
                              className="w-full h-full  bg-primary rounded-md flex justify-center items-center"
                            >
                              <PagRightIcon
                                width="22"
                                height="22"
                                fill="#ffffff"
                              />
                            </button>
                          )}
                      </>
                    )}
                  </div> */}
                </div>
              )}

            {/* 3333333333333333333333333 */}
            {parseFloat(values?.paymentData[0]?.payment_amount) +
              parseFloat(values?.paymentData[1]?.payment_amount) <
              totalInvoiceAmount &&
              values?.paymentData[1]?.payment_amount !== null && (
                //  ||
                // values?.paymentData[2]?.transaction
                <div className="flex gap-2 ">
                  <div
                    style={{
                      width: "30%",
                    }}
                  >
                    <SelectField
                      general_color={false}
                      handleSelected={() => {
                        setFieldValue(`paymentData[${0}].tender_check`, false);
                        setFieldValue(`paymentData[${1}].tender_check`, false);
                        setFieldValue(`paymentData[${2}].tender_check`, false);
                      }}
                      defaultSelectClass="text-sm fw_500 fm_inter text-030304 h-[2.5rem] focus_outline__none "
                      isDisabled={
                        disabled || totalInvoiceAmount <= 0
                        // ||
                        // values?.paymentData[2]?.transaction
                      }
                      defaultValue={
                        values.paymentData[2]?.payment_mode_name && {
                          label: values.paymentData[2]?.payment_mode_name,
                        }
                      }
                      errMsgContainerClass="flex justify-start hidden"
                      id={`paymentData[${1}].payment_mode_name`}
                      isContainer={false}
                      options={getAvailablePaymentModes(2)}
                      name={`paymentData[${2}].payment_mode_name`}
                      placeholder={"Select"}
                      style={{
                        control: (base, state) => ({
                          ...base,
                          color: "#0D111A",
                          height: "100%",
                          minHeight: "",
                          fontFamily: "Poppins,sans-serif ",
                          fontWeight: "400",
                          fontSize: "14px",
                          borderRadius: "10px",
                          width: "100%",
                          backgroundColor: state.isDisabled
                            ? "#EAECF0"
                            : base.backgroundColor,
                          borderColor: state.isFocused
                            ? "#277dfe"
                            : "transparent",
                          boxShadow: state.isFocused ? null : null,
                          "&:hover": {},
                        }),
                        input: (base) => ({
                          ...base,
                          margin: "0",
                          padding: "0",
                        }),
                      }}
                    />
                  </div>
                  <div
                    style={{
                      height: "2.5rem",
                    }}
                    className={` w-[40%]  relative`}
                  >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      ₹
                    </span>
                    <Field name={`paymentData[${2}].payment_amount`}>
                      {({ field, form }) => (
                        <input
                          {...field}
                          value={field.value || ""}
                          onChange={() => {
                            setFieldValue(
                              `paymentData[${0}].tender_check`,
                              false
                            );
                          }}
                          disabled={
                            disabled ||
                            totalInvoiceAmount <= 0 ||
                            // values?.paymentData[2]?.transaction ||
                            !values?.paymentData[2]?.payment_mode_name
                          }
                          placeholder=""
                          name={`paymentData[${2}].payment_amount`}
                          type="number"
                          className={` w-full h-full pl-7 fw_600 focus_outline__none input-field-no-spin-buttons  rounded-lg border-D0D5DD border focus:border-primary focus:border bg-white disabled:bg-EAECF0`}
                        />
                      )}
                    </Field>
                  </div>

                  {values?.paymentData[2]?.payment_mode_name === "Cash" &&
                    (totalInvoiceAmount < totalPaymentAmount ||
                      values?.paymentData[2]?.tender_check === true) && (
                      <div
                        style={{
                          backgroundColor: getBackgroundColor(
                            values?.paymentData[2]?.tender_check
                          ),
                        }}
                        className={`  px-2 flex gap-2 items-center rounded-lg text-nowrap  ${
                          values?.paymentData[2]?.tender_check === true
                            ? "border border-primary"
                            : "border border-D0D5DD"
                        }`}
                      >
                        <Field name={`paymentData[${2}].tender_check`}>
                          {({ field }) => (
                            <input
                              {...field}
                              checked={field.value}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                setFieldValue(
                                  `paymentData[${2}].tender_check`,
                                  isChecked
                                );
                                if (isChecked) {
                                  setFieldValue(
                                    `paymentData[${2}].payment_amount`,
                                    parseFloat(
                                      values?.paymentData[2]?.payment_amount
                                    ) -
                                      (parseFloat(totalPaymentAmount) -
                                        parseFloat(totalInvoiceAmount))
                                  );
                                }
                              }}
                              type="checkbox"
                            />
                          )}
                        </Field>

                        <span
                          className={` ${
                            values?.paymentData[1]?.tender_check === true
                              ? "text-primary"
                              : "text-F51D00"
                          }`}
                        >
                          -{" "}
                          {parseFloat(totalPaymentAmount) -
                            parseFloat(totalInvoiceAmount)}
                        </span>
                      </div>
                    )}
                  {/* <div className="min-w-[20%] h-[2.4rem]">
                    {values?.paymentData[2]?.transaction ? (
                      <>
                        <div className="w-[2.5rem] bg-22B378 rounded-md h-full flex items-center justify-center">
                          <SuccessIcon />
                        </div>
                      </>
                    ) : (
                      <>
                        {values?.paymentData[2]?.payment_mode_name !== "Cash" &&
                          !disabled && (
                            <button
                              type="button"
                              onClick={() => {
                                if (
                                  parseFloat(
                                    values?.paymentData[2]?.payment_amount
                                  ) < calculateTotal(values)
                                ) {
                                  handlePayment(
                                    values?.paymentData[2]?.payment_amount,
                                    2
                                  );
                                } else {
                                  errorToast("Amount is not matching");
                                }
                              }}
                              className="w-full h-full  bg-primary rounded-md flex justify-center items-center"
                            >
                              <PagRightIcon
                                width="22"
                                height="22"
                                fill="#ffffff "
                              />
                            </button>
                          )}
                      </>
                    )}
                  </div> */}
                </div>
              )}
          </div>
        </div>

        <div className="flex flex-col justify-between xl:px-5 w-[20%] text-sm border-r-EAECF0 border-r-2 px-4 py-3 fw_500 text-secondary whitespace-nowrap">
          <span className="text-475467  fw_600 text-sm">GST Information</span>
          <div className="w-full flex justify-between ">
            <span>CGST (₹)</span>{" "}
            <span className="text-000000">
              {calculateTotalCGST(values?.data).toFixed(2)}
            </span>
          </div>
          <div className="w-full flex justify-between ">
            <span>SGST (₹)</span>{" "}
            <span className="text-000000">
              {" "}
              {calculateTotalSGST(values?.data).toFixed(2)}
            </span>
          </div>
          <div className="w-full flex justify-between ">
            <span>IGST (₹)</span>{" "}
            <span className="text-000000">
              {calculateTotalIGST(values?.data).toFixed(2)}
            </span>
          </div>
        </div>
      </div>
      <div
        className="bg-F2F4F5 text-sm fw_500 text-secondary"
        style={{
          minWidth: "16rem",
        }}
      >
        <div className="pt-2 pb-1 px-4 flex justify-between items-center">
          <span>Sub Total (₹)</span>
          <span>
            {" "}
            <input
              disabled={true}
              className="text-end w-[8rem] px-2 py-1 border border-D0D5DD rounded-md text-000000"
              type="text"
              value={`${calculateSubtotal(values?.data).toFixed(2)}`}
            />
          </span>
        </div>
        <div className="py-1 px-4 flex justify-between items-center">
          <span>Tax (₹)</span>
          <span>
            <input
              disabled={true}
              className="text-end w-[8rem] px-2 py-1 border border-D0D5DD rounded-md text-000000"
              type="text"
              value={`${calculateTotalTax(values?.data).toFixed(2)}`}
            />
          </span>
        </div>
        <div className="py-1 px-4 flex justify-between items-center">
          <span>Discount (₹)</span>
          <span>
            <input
              value={calculateTotalDiscount(values?.data).toFixed(2)}
              disabled={true}
              className="text-end w-[8rem] px-2 py-1 rounded-md border focus:placeholder-transparent focus:outline-none border-D0D5DD input-field-no-spin-buttons text-000000"
              type="text"
            />
          </span>
        </div>
        <div className="py-1 px-4 flex justify-between fw_700 text-sm items-center">
          <span>Total (₹)</span>
          <span>
            <input
              disabled={true}
              className="text-end w-[8rem] px-2 py-1 border rounded-md border-D0D5DD text-000000"
              type="text"
              value={calculateTotal(values)?.toFixed(2)}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default BillingFinalTable;

export const calculateTotal = (values) => {
  return values.data?.reduce(
    (total, item) => total + parseFloat(item.item_total_amount || 0),
    0
  );
};
export const calculateTotalTax = (data) => {
  let totalTax = 0;

  data?.forEach((rowData) => {
    totalTax += parseFloat(rowData.item_tax_amount) || 0;
  });

  return totalTax;
};

export const calculateSubtotal = (data) => {
  return data.reduce((subtotal, item) => {
    return subtotal + parseFloat(item.item_price_wiithout_tax);
  }, 0);
};

const calculateTotalDiscount = (data) => {
  return data.reduce((totalDiscount, item) => {
    const item_discount_amount = parseFloat(item.item_discount_amount || 0);
    return totalDiscount + item_discount_amount;
  }, 0);
};

export const calculateTotalCGST = (data) => {
  let totalCGST = 0;

  data?.forEach((rowData) => {
    totalCGST += parseFloat(rowData.item_cgst) || 0;
  });

  return totalCGST;
};

export const calculateTotalSGST = (data) => {
  let totalSGST = 0;

  data?.forEach((rowData) => {
    totalSGST += parseFloat(rowData.item_sgst) || 0;
  });

  return totalSGST;
};
export const calculateTotalIGST = (data) => {
  let totalIGST = 0;

  data?.forEach((rowData) => {
    totalIGST += parseFloat(rowData.item_igst) || 0;
  });

  return totalIGST;
};
export const calculateTotalPayment = (values) => {
  return values.paymentData?.reduce((acc, item) => {
    return acc + parseFloat(item.payment_amount || 0);
  }, 0);
};

// export function validatePaymentData(paymentData) {
//   let isValid = true;

//   paymentData.forEach((payment) => {
//     if (
//       (payment.payment_mode_name === "UPI" ||
//         payment.payment_mode_name === "Card") &&
//       !payment.transaction
//     ) {
//       isValid = false;
//     }
//   });
//   if (isValid) {
//     return false;
//   } else {
//     return true;
//   }
// }

export function validatePaymentmethod(paymentData) {
  let isValid = true;

  paymentData.forEach((payment) => {
    const isPaymentAmountValid =
      payment.payment_amount && parseFloat(payment.payment_amount) > 0;

    if (isPaymentAmountValid && !payment.payment_mode_name) {
      isValid = false;
    }
  });

  return !isValid;
}
