import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import InputField from "../../forms/input-field";
import SelectField from "../../forms/select-field";
import TextAreaField from "../../forms/textarea-field";
import { useDispatch } from "react-redux";
import { stateListAction } from "../../../redux/actions/general-action";
import AddButton, { DraftButton } from "../../buttons/button";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  storeDetailsGetAction,
  storeDetailsUpdateAction,
} from "../../../redux/actions/settings-action";
import successToast from "../../helper-components/success-toast";
import ImageField from "../../forms/images-field";
import LoadingSpinner from "../../helper-components/loading-spinner";
import { formatString } from "../../helper-components/helper-function";

const StoreCreation = ({ isView = false }) => {
  const dispatch = useDispatch();
  const [storeDetails, setStoreDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();
  const [stateList, setStateList] = useState([]);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    dispatch(stateListAction(onSuccessState, onErrorState));
    dispatch(storeDetailsGetAction("STR0002", onSuccessStore, onErrorStore));
  }, [reload]);
  const onSuccessStore = (data) => {
    setLoading(false);
    setStoreDetails(data?.data);
  };
  const onErrorStore = (data) => {
    setLoading(false);
  };

  const onSuccessState = (data) => {
    setStateList(
      data.map((item) => {
        return { label: item?.name, value: item?.name };
      })
    );
  };
  const onErrorState = () => {};
  const initialValues = {
    pos_id: storeDetails?.pos_id,
    company: storeDetails?.company,
    company_area: storeDetails?.company_area,
    company_landmark: storeDetails?.company_landmark,
    company_state: storeDetails?.company_state,
    company_city: storeDetails?.company_city,
    company_pincode: storeDetails?.company_pincode,
    company_address: storeDetails?.company_address,
    store_id: storeDetails?.store_id,
    cpos_ip_address: storeDetails?.cpos_ip_address,
    company_alias_name: storeDetails?.company_alias_name,
    company_email: storeDetails?.company_email,
    company_telephone1: storeDetails?.company_telephone1,
    company_telephone2: storeDetails?.company_telephone2,
    company_gstn: storeDetails?.company_gstn,
    company_logo: storeDetails?.company_logo || "", // Add company logo here
  };
  const validationSchema = Yup.object().shape({
    pos_id: Yup.string().required("Required"),
    company_area: Yup.string().required("Required"),
    company_landmark: Yup.string().required("Required"),
    company_state: Yup.string().required("Required"),
    company_city: Yup.string().required("Required"),
    store_id: Yup.string().required("Required"),
    cpos_ip_address: Yup.string()
      .matches(
        /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/,
        "Invalid IP address"
      )
      .required("Required"),
    company_pincode: Yup.string().required("Required"),
    company_address: Yup.string().required("Required"),
    company_alias_name: Yup.string().required("Required"),
    company_email: Yup.string().required("Required"),
    company_telephone1: Yup.string().required("Required"),
    company_telephone2: Yup.string().required("Required"),
    company_gstn: Yup.string()
      .nullable()
      .matches(
        /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})?$/,
        "Invalid GST number"
      )
      .required("Required"),
    company_logo: Yup.mixed().required("Company Logo is required"), // Add validation for company logo
  });

  const onSubmit = (values, {}) => {
    setButtonLoading(true);
    dispatch(
      storeDetailsUpdateAction(
        {
          store_id: "STR0002",
          ...values,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    setButtonLoading(false);
    setReload(!reload);
    successToast(formatString(data?.message));
  };
  const onError = (data) => {
    setButtonLoading(false);
  };
  return (
    <div className="h-full">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleSubmit }) => {
            return (
              <Form className="flex flex-col justify-between h-full mt-5">
                <div className="px-5">
                  <div className="h-full flex flex-wrap  2xl:gap-[10%] gap-[6%]  w-[100%]  ">
                    <div className="2xl:w-[40%] lg:w-[45%] w-[100%]">
                      <InputField
                        isDisabled={isView}
                        containerClass="mt-1"
                        isRequired={true}
                        type="text"
                        name="pos_id"
                        placeholder="POS integration Id"
                        label="POS Integration Id"
                        labelWidth="11rem"
                      />
                      <InputField
                        isDisabled={isView}
                        containerClass="mt-1"
                        isRequired={true}
                        type="text"
                        name="company"
                        placeholder="Company Name"
                        label="Company Name"
                        labelWidth="11rem"
                      />
                      <InputField
                        isDisabled={isView}
                        containerClass="mt-1"
                        isRequired={true}
                        type="text"
                        name="company_area"
                        placeholder="Area"
                        label="Area"
                        labelWidth="11rem"
                      />
                      <InputField
                        isDisabled={isView}
                        containerClass="mt-1"
                        isRequired={true}
                        type="text"
                        name="company_landmark"
                        placeholder="Landmark"
                        label="Landmark"
                        labelWidth="11rem"
                      />
                      <InputField
                        isDisabled={isView}
                        containerClass="mt-1"
                        isRequired={true}
                        type="text"
                        name="company_city"
                        placeholder="City"
                        label="City"
                        labelWidth="11rem"
                      />
                      <InputField
                        isDisabled={isView}
                        containerClass="mt-9"
                        isRequired={true}
                        type="text"
                        name="store_id"
                        placeholder="Store Code"
                        label="POS ID"
                        labelWidth="11rem"
                      />
                      <InputField
                        isDisabled={isView}
                        containerClass="mt-1"
                        isRequired={true}
                        type="text"
                        name="cpos_ip_address"
                        placeholder="Enter CPOS IP address"
                        label="POS IP address"
                        labelWidth="11rem"
                      />
                      <InputField
                        isDisabled={isView}
                        containerClass="mt-1"
                        isRequired={true}
                        type="text"
                        name="company_alias_name"
                        placeholder="Alias Name"
                        label="Alias Name"
                        labelWidth="11rem"
                      />
                      <InputField
                        onKeyDown={() => {}}
                        isDisabled={isView}
                        containerClass="mt-1"
                        isRequired={true}
                        type="text"
                        name="company_email"
                        placeholder="email"
                        label="Email"
                        labelWidth="11rem"
                      />
                      <ImageField
                        mainLabel="Store Logo"
                        label="Store Logo"
                        isRequired={true}
                        labelWidth="11rem"
                        name="company_logo"
                      />
                    </div>

                    <div className="2xl:w-[40%] lg:w-[45%] w-[100%]">
                      <InputField
                        isRequired={true}
                        type="text"
                        name="company_gstn"
                        placeholder="xxxxxxxxxxxxxxx"
                        label="GSTIN"
                        labelWidth="11rem"
                      />
                      <SelectField
                        placeholder={"Select state"}
                        options={stateList}
                        label={"State"}
                        id={"company_state"}
                        name="company_state"
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        isRequired={true}
                        labelWidth="11rem"
                      />
                      <InputField
                        isDisabled={isView}
                        containerClass="mt-1"
                        isRequired={true}
                        type="text"
                        name="company_pincode"
                        placeholder="Enter Pincode"
                        label="Pincode"
                        labelWidth="11rem"
                      />
                      <TextAreaField
                        label="Address"
                        placeholder="Address"
                        name="company_address"
                        id={"company_address"}
                        isRequired={true}
                        labelWidth="11rem"
                        feildHeight="6rem"
                      />
                      <InputField
                        isDisabled={isView}
                        containerClass="mt-10"
                        isRequired={true}
                        type="number"
                        name="company_telephone1"
                        placeholder="000-000-000"
                        label="Telephone 1"
                        labelWidth="11rem"
                      />
                      <InputField
                        isDisabled={isView}
                        containerClass="mt-1"
                        isRequired={true}
                        type="number"
                        name="company_telephone2"
                        placeholder="000-000-000"
                        label="Telephone 2"
                        labelWidth="11rem"
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-between px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  <DraftButton onclkFn={() => navigate(-1)} text="Cancel" />
                  <AddButton
                    loading={buttonLoading}
                    onclkFn={handleSubmit}
                    prefixIcon={false}
                    text="Save"
                    type="submit"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default StoreCreation;
