import { Field, useFormikContext } from "formik";
import React from "react";
import SelectField from "../../forms/select-field";
import AddButton from "../../buttons/button";
import DeleteIcon from "../../../assets/icons/delete-icon";
import { api } from "../../../redux/apis/api";
import { url } from "../../../redux/config";
const BatchQuantityExpiryPopoverCredit = ({
  setAddbatch,
  itemId,
  setItemId,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const defaultSelectedItem = values?.data?.find(
    (item) => itemId === item?.item_id
  );
  const defaultSelectedItemIndex = values?.data?.findIndex(
    (item) => itemId === item?.item_id
  );
  const initData = {
    item_id: defaultSelectedItem?.item_id,
    item_batch_number: "",
    item_batch_quantity: null,
    item_exp_date: new Date(),
  };
  const handleQuantityChange = async (rowIndex, batchIndex, e) => {
    const updatedData = values.data.map((row, index) => {
      if (index === defaultSelectedItemIndex) {
        const updatedItemBatches = row.item_batches.map((batch, idx) => {
          if (idx === batchIndex) {
            return {
              ...batch,
              item_batch_quantity: parseFloat(e.target.value),
            };
          } else {
            return batch;
          }
        });
        return { ...row, item_batches: updatedItemBatches };
      } else {
        return row;
      }
    });
    setFieldValue("data", updatedData);
    const itemBatches = updatedData[rowIndex].item_batches;
    let sumQuantity = 0;

    for (let i = 0; i < itemBatches.length; i++) {
      if (i === batchIndex) {
        sumQuantity += parseFloat(e?.target?.value);
      } else {
        sumQuantity += parseFloat(itemBatches[i].item_batch_quantity);
      }
    }

    try {
      const response = await api.get(
        `${url}/discount/get?totalAmountOfItem=${
          sumQuantity * parseFloat(values?.data?.[rowIndex]?.item_unit_price)
        }&item_quantity=${sumQuantity}&item_id=${
          values?.data?.[rowIndex]?.item_id
        }&cmr_phone_number=${values?.phone?.label}`
      );
      const discount =
        response.data?.code === 200
          ? response.data.data.item_discount_amount
          : 0;
      const tax =
        response.data?.code === 200 ? response.data.data.item_tax_amount : 0;
      const item_discount_percentage =
        response.data?.code === 200
          ? response.data.data.item_discount_percentage
          : 0;

      const updatedDataRow = {
        ...updatedData[rowIndex],
        quantity: sumQuantity,
        item_discount_amount: discount,
        item_tax_amount: tax,
        item_discount_percentage: item_discount_percentage,
        // item_batches:
        //   itemDetail?.map((batch) => ({
        //     item_batch_number: batch?.batchNumber,
        //     item_batch_quantity: batch?.quantity,
        //     item_exp_date: batch?.expiryDate,
        //     item_id: item.item_id,
        //   })) ?? [],
        item_gst:
          response.data?.code === 200
            ? response.data.data.item_total_tax_percentage
            : 0,
        item_sgst:
          response.data?.code === 200
            ? (
                (sumQuantity *
                  parseFloat(values?.data?.[rowIndex]?.item_unit_price) -
                  parseFloat(discount)) *
                (response.data.data.sgstRate / 100)
              )?.toFixed(2)
            : 0,
        item_cgst:
          response.data?.code === 200
            ? (
                (sumQuantity *
                  parseFloat(values?.data?.[rowIndex]?.item_unit_price) -
                  parseFloat(discount)) *
                (response.data.data.cgstRate / 100)
              )?.toFixed(2)
            : 0,
        item_igst:
          response.data?.code === 200
            ? (
                (sumQuantity *
                  parseFloat(values?.data?.[rowIndex]?.item_unit_price) -
                  parseFloat(discount)) *
                (response.data.data.igstRate / 100)
              )?.toFixed(2)
            : 0,

        item_total_amount:
          parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
            parseFloat(sumQuantity) -
          parseFloat(discount) +
          parseFloat(tax),
        instock: true,
      };

      updatedData[rowIndex] = updatedDataRow;

      setFieldValue("data", [...updatedData]);
    } catch (error) {
      onErrorDis(error);
    }
  };
  const onErrorDis = (data) => {};

  return (
    <div className="absolute w-[-webkit-fill-available]  z-40">
      <div className="  h-[72vh] bg-white flex flex-col justify-between">
        <div>
          <div className="bg-[#F4F6F9] w-full p-3 gap-2 font-semibold text-xl  flex items-center">
            Batch details -
            <SelectField
              general_color = {false}
              handleSelected={(selected) => {
                setItemId(selected?.value);
              }}
              containerClass="w-[20rem]"
              placeholder={"Select product"}
              defaultValue={
                defaultSelectedItem
                  ? {
                      label: defaultSelectedItem.item_name,
                      value: defaultSelectedItem.item_id,
                    }
                  : null
              }
              options={values?.data
                ?.map((item) => ({
                  label: item?.item_name,
                  value: item?.item_id,
                }))
                ?.filter((item) => item?.value)}
              label={""}
              id={"item_id"}
              name="item_id"
              isSearchable={true}
              errMsgContainerClass="hidden"
              labelWidth="10.5rem"
            />
          </div>
          <div className="p-3 overflow-y-auto h-[38vh]">
            <table className="lg:w-[50%] w-full border-D0D5DD border border-1 rounded-lg ">
              <thead>
                <tr className="bg-F2F4F5">
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm">
                    Batch No.
                  </th>{" "}
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm">
                    Qty
                  </th>
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm">
                    Exp. Date
                  </th>
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm"></th>
                </tr>
              </thead>
              <tbody>
                {defaultSelectedItem?.item_batches.map((batch, batchindex) => (
                  <tr key={batchindex}>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm w-[35%]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_number`}
                      >
                        {({ field }) => (
                          <input
                            autoFocus={true}
                            placeholder=""
                            {...field}
                            type="text"
                            className={`w-full focus_outline__none focus:border-primary focus:border rounded-[10px] p-2`}
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault(); // Prevent space from being typed
                              }
                            }}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm w-[35%]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_quantity`}
                      >
                        {({ field, form }) => (
                          <input
                            min={0}
                            {...field}
                            placeholder=""
                            type="number"
                            className={`w-full focus_outline__none focus:border-primary focus:border rounded-[10px] p-2 `}
                            onChange={(e) => {
                              field.onChange(e);
                              handleQuantityChange(
                                defaultSelectedItemIndex,
                                batchindex,
                                e
                              );
                            }}
                            onInput={(e) => {
                              const value = e.target.value;
                              if (value < 0) {
                                form.setFieldValue(field.name, 0); // Prevent negative value
                              }
                            }}
                            onKeyDown={(e) => {
                              if (["-", "e", " "].includes(e.key)) {
                                e.preventDefault(); // Prevent negative sign, scientific notation, and space
                              }
                            }}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm w-[30%]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_exp_date`}
                      >
                        {({ field }) => (
                          <input
                            placeholder=""
                            {...field}
                            type="date"
                            className={`w-full focus_outline__none focus:border-primary focus:border rounded-[10px] p-2 `}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-2 text-secondary fw_400 text-sm">
                      {/* {batchindex > 0 && ( */}
                      <button
                        onClick={() => {
                          const updatedBatches = [
                            ...defaultSelectedItem.item_batches,
                          ];
                          updatedBatches.splice(batchindex, 1);
                          setFieldValue(
                            `data.${defaultSelectedItemIndex}.item_batches`,
                            updatedBatches
                          );
                        }}
                        type="button"
                      >
                        <DeleteIcon />
                      </button>
                      {/* )} */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>{" "}
            <div className="flex justify-end lg:w-[50%] w-full">
              <AddButton
                className="bg-primary text-white px-8 py-1 mt-2 rounded flex justify-center fw_500 text-sm"
                onclkFn={() => {
                  setFieldValue(
                    `data.${defaultSelectedItemIndex}.item_batches`,
                    [
                      ...values?.data?.[defaultSelectedItemIndex]?.item_batches,
                      initData,
                    ]
                  );
                }}
                prefixIcon={true}
                text=""
                type="button"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between px-10 sticky bottom-0   bg-white py-2 invoice-bottom-container">
          <AddButton
            onclkFn={() => setAddbatch(false)}
            prefixIcon={false}
            text="Done"
            type="button"
          />
        </div>
      </div>
      <div
        className="bg-black h-[23vh] opacity-50"
        onClick={() => setAddbatch(false)}
      ></div>
    </div>
  );
};

export default BatchQuantityExpiryPopoverCredit;
