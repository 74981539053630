import React, { useEffect, useState } from "react";
import CreateEditViewPurchase from "../../../components/purchase-section/purchase-order/create-edit-view-purchase";
import InnerLayout from "../../../components/layouts/inner-layout";
import { useDispatch } from "react-redux";
import { salesOrderItemsGetAction } from "../../../redux/actions/sales-action";
import { useLocation, useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";

const AddPurchase = () => {
  const location = useLocation();
  const suggestedProducts = location.state?.orderDetail || [];
  console.log(suggestedProducts, "rfgsgsdgdsgsgdsgds");

  const { itemId, type } = useParams();
  const itemIdArray = JSON.parse(decodeURIComponent(itemId));

  const allitems = itemIdArray.map((item) => {
    const { cmr_id, ...rest } = item;
    return rest;
  });
  const [orderDetail, setOrderDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(salesOrderItemsGetAction(allitems, onSuccPur, onErrPur));
  }, []);

  const onSuccPur = (data) => {
    setOrderDetail(data?.data);
    setLoading(false);
  };

  const onErrPur = (data) => {
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <InnerLayout list={true} title="Purchase order">
          <CreateEditViewPurchase
            type={type}
            orderDetail={
              suggestedProducts?.length > 0
                ? suggestedProducts
                : orderDetail?.itemData ?? []
            }
            vendorDetails={[orderDetail?.orderData]}
          />
        </InnerLayout>
      )}
    </>
  );
};
export default AddPurchase;
