import React from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import MainLayout from "../components/layouts/main-layout";
import AddInvoice from "../modules/invoices/add-invoice";
import { ToastContainer } from "react-toastify";
import AddCustomer from "../modules/customer-information/add-customer";
import CustomerTable from "../modules/customer-information/customer-table";
import AddProduct from "../modules/products/add-product";
import ProductTable from "../modules/products/product-table";
import ViewInvoice from "../modules/invoices/view-invoice";
import UserLogin from "../modules/login/user-login";
import ForgotPassword from "../modules/login/forgot-password";
import EditInvoice from "../modules/invoices/edit-invoice";
import PastOrdersTable from "../modules/invoices/past-orders";
import PasswordReset from "../modules/login/password-reset";
import ViewCustomer from "../modules/customer-information/customer-view";
import EditCustomer from "../modules/customer-information/customer-edit";
import ViewProduct from "../modules/products/view-product";
import EditProduct from "../modules/products/edit-product";
import ErrorBoundary from "../components/layouts/error-boundary";
import AddStore from "../modules/store-information/add-store";
import StoreTable from "../modules/store-information/store-table";
import EditStore from "../modules/store-information/edit-store";
import ViewStore from "../modules/store-information/view-store";
import PrivateRoute from "./private-route";
import ProtectedRoute from "./protected-route";
import AddUser from "../modules/users/add-user";
import UserTable from "../modules/users/user-table";
import ViewUser from "../modules/users/user-view";
import EditUser from "../modules/users/user-edit";
import PriceListTable from "../modules/discounts/price-lists/price-list-table";
import AddPriceList from "../modules/discounts/price-lists/add-price-list";
import EditPriceList from "../modules/discounts/price-lists/edit-price-list";
import DiscountGroup from "../modules/discounts/discount-group/discount-group";
import InventoryBase from "../modules/inventory/inventory-base";
import BinLocationManagement from "../modules/inventory/bin-location-management";
import CreateWarehouse from "../modules/inventory/create-warehouse";
import PeriodVolumeDiscounts from "../modules/discounts/period-volume-discounts/period-volume-discounts";
import UnderDevelopment from "../modules/under-development";
import SalesReports from "../modules/sales/sales-report";
import AddSales from "../modules/sales-section/sales-order/add-sales";
import SalesTable from "../modules/sales-section/sales-order/sales-table";
import GoodsReceipt from "../modules/purchase-order/goods-receipt/goods-receipt";
import GoodsTable from "../modules/purchase-order/goods-receipt/goods-table";
import SalesDeliveyTable from "../modules/sales-section/sales-delivery/sales-delivery-table";
import ChartOfAccount from "../modules/accounts/chart-of-accounts";
import GlAccountAllocation from "../modules/accounts/g-l-acc-allocation";
import JournalEntry from "../modules/accounts/journal-entry";
import JournalTable from "../modules/accounts/journal-table";
import SalesInvoice from "../modules/sales-section/sales-invoice/sales-invoice";
import AddSalesInvoice from "../modules/sales-section/sales-invoice/add-sales-invoice";
import AddDelivery from "../modules/sales-section/sales-delivery/add-delivery";
import SalesReturn from "../modules/sales-section/sales-return/sales-return";
import AddSalesReturn from "../modules/sales-section/sales-return/add-sales-return";
import AddSalesPaymentIn from "../modules/sales-section/sales-payment-in/add-sales-payment-in";
import AddPurchaseInvoice from "../modules/purchase-order/purchase-invoice/add-purchase-invoice";
import Settings from "../modules/settings/settings-main-page";
import SalesCreditNote from "../modules/sales-section/sales-credit-note/sales-credit-note";
import AddSalesCreditNote from "../modules/sales-section/sales-credit-note/add-sales-credit-note";
import SalesCreditView from "../modules/sales-section/sales-credit-note/sales-credit-note-view";
import SalesOrderView from "../modules/sales-section/sales-order/sales-view";
import SalesOrderEdit from "../modules/sales-section/sales-order/sales-edit";
import ViewDelivery from "../modules/sales-section/sales-delivery/view-delivery";
import AddPurchase from "../modules/purchase-order/purchase-order/add-purchase";
import PurchaseTable from "../modules/purchase-order/purchase-order/purchase-table";
import ViewPurchase from "../modules/purchase-order/purchase-order/view-purchase";
import EditPurchase from "../modules/purchase-order/purchase-order/edit-purchase";
import ViewGoodsReceipt from "../modules/purchase-order/goods-receipt/view-goods-receipt";
import ViewPurchaseInvoice from "../modules/purchase-order/purchase-invoice/view-purchase-invoice";
import PurchaseInvoiceTable from "../modules/purchase-order/purchase-invoice/purchase-invoice-table";
import PurchaseReturnTable from "../modules/purchase-order/purchase-return/purchase-return-table";
import AddPurchaseReturn from "../modules/purchase-order/purchase-return/add-purchase-return";
import ViewPurchaseReturn from "../modules/purchase-order/purchase-return/view-purchase-return";
import PurchaseCreditTable from "../modules/purchase-order/purchase-credit-note/purchase-credit-table";
import AddPurchaseCredit from "../modules/purchase-order/purchase-credit-note/add-purchase-credit";
import ViewPurchaseCredit from "../modules/purchase-order/purchase-credit-note/view-purchase-credit";
import EditDelivery from "../modules/sales-section/sales-delivery/edit-delivery";
import SalesInvoiceEdit from "../modules/sales-section/sales-invoice/sales-invoice-edit";
import SalesInvoiceView from "../modules/sales-section/sales-invoice/sales-invoice-view";
import SalesReturnView from "../modules/sales-section/sales-return/sales-return-view";
import SalesReturnEdit from "../modules/sales-section/sales-return/sales-return-edit";
import SalesCreditEdit from "../modules/sales-section/sales-credit-note/sales-credit-note-edit";
import EditGoodsReceipt from "../modules/purchase-order/goods-receipt/edit-goods-receipt";
import EditPurchaseInvoice from "../modules/purchase-order/purchase-invoice/edit-purchase-invoice";
import EditPurchaseReturn from "../modules/purchase-order/purchase-return/edit-purchase-return";
import EditPurchaseCredit from "../modules/purchase-order/purchase-credit-note/edit-purchase-credit";
import ManufacturerSetup from "../components/settings/data-setup/all-groups/manufacturer-setup";
import ShippingSetup from "../components/settings/data-setup/all-groups/shipping-setup";
import UnitOfMeasure from "../components/settings/data-setup/inventory/unit-of-measure";
import UomGroup from "../components/settings/data-setup/inventory/uom-group";
import ItemSetup from "../components/settings/data-setup/all-groups/item-setup";
import TaxSetup from "../components/settings/data-setup/accounting/taxSetup/tax-setup";
import DocumentNumbering from "../components/settings/system-settings/document-numbering";
import SmtpConfiguration from "../components/settings/communication/smtp/smtp-config";
import SalesPaymentIn from "../modules/sales-section/sales-payment-in/payment-in-table";
import WhatsappConfiguration from "../components/settings/communication/whatsapp/whatsapp-config";
import ViewPaymentIn from "../modules/sales-section/sales-payment-in/view-payment-in";
import PurchasePaymentOut from "../modules/purchase-order/purchase-payment-out/payment-out-table";
import AddPurchasePaymentOut from "../modules/purchase-order/purchase-payment-out/add-sales-payment-out";
import ViewPaymentOut from "../modules/purchase-order/purchase-payment-out/view-payment-out";
import RazorPayConfiguration from "../components/settings/payment/razorpay-config";
import StripeConfiguration from "../components/settings/payment/stripe-config";
import PrintPreference from "../components/settings/print-preference/print-preference";
import AddDoctor from "../modules/doctor-information/add-doctor";
import DoctorTable from "../modules/doctor-information/doctor-table";
import ViewDoctor from "../modules/doctor-information/doctor-view";
import EditDoctor from "../modules/doctor-information/doctor-edit";
import SmsConfiguration from "../components/settings/communication/sms/sms-config";
import PhonePeConfiguration from "../components/settings/payment/phonepe-config";
import PaytmConfiguration from "../components/settings/payment/paytm-config";
import PineLabConfiguration from "../components/settings/payment/pine-lab-config";
import AddStoreTransfer from "../modules/inventory/store-transfer/add-store-transfer";
import DailyBillingSalesReport from "../modules/reports/sales-reports/daily-billing-sales";
import OverallSalesReport from "../modules/reports/sales-reports/overall-sales";
import Reports from "../modules/reports/reports-main-page";
import StockTransferTable from "../modules/inventory/store-transfer/stock-transfer-table";
import ViewStockTransfer from "../modules/inventory/store-transfer/view-stock-transfer";
import ProductWiseSalesReport from "../modules/reports/sales-reports/product-wise-sales";
import BranchWiseSalesReport from "../modules/reports/sales-reports/branch-wise-sales";
import DoctorWiseSalesReport from "../modules/reports/sales-reports/doctor-wise-sales";
import PaymentModeWiseSalesReport from "../modules/reports/sales-reports/payment-mode-wise-sales";
import DashBoard from "../modules/dashboard/dashboard";
import PartyWiseSalesReport from "../modules/reports/sales-reports/party-wise-sales";
import PartyWiseProfitReport from "../modules/reports/financial-reports/party-wise-profit";
import DiscountAnalysisReport from "../modules/reports/financial-reports/discount-analysis";
import SalesReturnReport from "../modules/reports/sales-return/sales-return";
import CustomerPurchaseFreqReport from "../modules/reports/customer-reports/purchase-frequency";
import CustomerPurchaseBehaviorReport from "../modules/reports/customer-reports/purchase-behavior";
import BounceReport from "../modules/reports/inventory-stock-reports/bounce-report";
import InventoryReport from "../modules/reports/inventory-stock-reports/inventory-report";
import ScheduledHdrugRegReport from "../modules/reports/inventory-stock-reports/scheduled-hdrug-reg";
import ExpiryLossReport from "../modules/reports/inventory-stock-reports/expiry-loss";
import BillingTable from "../modules/invoices/billing-table";
import ReturnBillTable from "../modules/return-bill/return-bill-table";
import AddReturnBill from "../modules/return-bill/add-return-bill";
import ViewReturnBill from "../modules/return-bill/view-return-bill";
import SlowFastMovingProductReport from "../modules/reports/inventory-stock-reports/slow-fast-moving-product";
import BulkPurchaseOrder from "../modules/purchase-order/bulk-purchase-order";
import AlternativeItemSetup from "../components/settings/data-setup/all-groups/alter-item-setup";
import Gstr1Report from "../modules/reports/gst-reports/gst-r-1";
import Gstr2aReport from "../modules/reports/gst-reports/gstr-2a";
import Gstr3aReport from "../modules/reports/gst-reports/gst-hsn-code-report";
import Gstr3bReport from "../modules/reports/gst-reports/gstr-3b";
import Gstr2bReport from "../modules/reports/gst-reports/gst-hsn-code-report";
import GstHsnCodeReport from "../modules/reports/gst-reports/gst-hsn-code-report";
import Gstr9Report from "../modules/reports/gst-reports/gstr-9";
import Gstr9cReport from "../modules/reports/gst-reports/gstr-9c";
import GstItcMisMatchReport from "../modules/reports/gst-reports/itc-mismatch-report";
import GstEwayBillReport from "../modules/reports/gst-reports/gst-e-way-bill-report";
import GstPaymentSummaryReport from "../modules/reports/gst-reports/gst-payment-summary-report";
import GstItcAgingReport from "../modules/reports/gst-reports/itc-aging-report";
import PurchaseOrderCsv from "../modules/purchase-order/purchase-order-csv";
import StockSalesReport from "../modules/reports/inventory-stock-reports/stock-and-sales-report";
// import { ipcRenderer } from "electron";

const Index = () => {
  // ipcRenderer.on("update_available", () => {
  //   console.log("Update available!");
  //   ipcRenderer.send("download-update");
  // });

  // ipcRenderer.on("update_downloaded", () => {
  //   console.log("Update downloaded and ready to install");
  //   ipcRenderer.send("install-update");
  // });
  return (
    <>
      <Router>
        <ErrorBoundary>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<MainLayout />}>
                {/* INVOICES */}
                <Route path="/" element={<Navigate to="/add-invoice" />} />
                <Route path="/add-invoice" element={<AddInvoice />} />
                <Route path="/bills" element={<BillingTable />} />
                <Route path="/view-invoice/:id" element={<ViewInvoice />} />
                <Route path="/edit-invoice/:id" element={<EditInvoice />} />
                <Route path="/past-orders/:id" element={<PastOrdersTable />} />
                {/*----------------RETURN BILL-----------------*/}
                <Route path="/return-bills" element={<ReturnBillTable />} />
                <Route
                  path="/add-return-bill/:itemId/:type"
                  element={<AddReturnBill />}
                />
                <Route
                  path="/view-return-bill/:id"
                  element={<ViewReturnBill />}
                />
                {/*----------------SALES ORDER-----------------*/}
                <Route path="/add-sales/:itemId/:type" element={<AddSales />} />
                <Route path="/sales" element={<SalesTable />} />
                <Route path="/view-sales/:id" element={<SalesOrderView />} />
                <Route path="/edit-sales/:id" element={<SalesOrderEdit />} />
                {/* SALES INVOICE */}
                <Route path="/sales-invoice" element={<SalesInvoice />} />
                <Route
                  path="/view-sales-invoice/:id"
                  element={<SalesInvoiceView />}
                />
                <Route
                  path="/edit-sales-invoice/:id"
                  element={<SalesInvoiceEdit />}
                />
                <Route
                  path="/add-sales-invoice/:itemId/:type"
                  element={<AddSalesInvoice />}
                />
                {/* SALES RETURN */}
                <Route path="/sales-return" element={<SalesReturn />} />
                <Route
                  path="/add-sales-return/:itemId/:type"
                  element={<AddSalesReturn />}
                />
                <Route
                  path="/view-sales-return/:id"
                  element={<SalesReturnView />}
                />
                <Route
                  path="/edit-sales-return/:id"
                  element={<SalesReturnEdit />}
                />
                {/* SALES CREDIT NOTE */}
                <Route
                  path="/sales-credit-note"
                  element={<SalesCreditNote />}
                />
                <Route
                  path="/add-sales-credit-note/:itemId/:type"
                  element={<AddSalesCreditNote />}
                />
                <Route
                  path="/view-sales-credit/:id"
                  element={<SalesCreditView />}
                />
                <Route
                  path="/edit-sales-credit/:id"
                  element={<SalesCreditEdit />}
                />
                {/* SALES PAYMENT IN */}
                <Route path="/sales-payment-in" element={<SalesPaymentIn />} />
                <Route
                  path="add-sales-payment-in"
                  element={<AddSalesPaymentIn />}
                />
                <Route
                  path="/view-payment-in/:id"
                  element={<ViewPaymentIn />}
                />
                {/*Sales Delivery*/}
                <Route
                  path="/add-delivery/:itemId/:type"
                  element={<AddDelivery />}
                />
                <Route path="/view-delivery/:id" element={<ViewDelivery />} />
                <Route path="/edit-delivery/:id" element={<EditDelivery />} />
                <Route path="/sales-delivery" element={<SalesDeliveyTable />} />
                {/*----------------------------PURCHASE ORDER--------------------*/}
                <Route
                  path="/add-purchase/:itemId/:type"
                  element={<AddPurchase />}
                />
                <Route path="/purchase" element={<PurchaseTable />} />
                <Route path="/view-purchase/:id" element={<ViewPurchase />} />
                <Route path="/edit-purchase/:id" element={<EditPurchase />} />
                <Route
                  path="/purchase-order-csv"
                  element={<PurchaseOrderCsv />}
                />
                {/*GOODS RECEIPT*/}
                <Route
                  path="/goods-receipt/:itemId/:type"
                  element={<GoodsReceipt />}
                />
                <Route path="/view-goods/:id" element={<ViewGoodsReceipt />} />
                <Route path="/edit-goods/:id" element={<EditGoodsReceipt />} />
                <Route path="/goods" element={<GoodsTable />} />
                {/* PURCHASE INVOICE */}
                <Route
                  path="/purchase-invoice"
                  element={<PurchaseInvoiceTable />}
                />
                <Route
                  path="/add-purchase-invoice/:itemId/:type"
                  element={<AddPurchaseInvoice />}
                />
                <Route
                  path="/view-purchase-invoice/:id"
                  element={<ViewPurchaseInvoice />}
                />
                <Route
                  path="/edit-purchase-invoice/:id"
                  element={<EditPurchaseInvoice />}
                />
                {/* PURCHASE RETURN */}
                <Route
                  path="/purchase-return"
                  element={<PurchaseReturnTable />}
                />
                <Route
                  path="/add-purchase-return/:itemId/:type"
                  element={<AddPurchaseReturn />}
                />
                <Route
                  path="/view-purchase-return/:id"
                  element={<ViewPurchaseReturn />}
                />
                <Route
                  path="/edit-purchase-return/:id"
                  element={<EditPurchaseReturn />}
                />
                {/* PURCHASE CREDIT NOTE */}
                <Route
                  path="/purchase-credit"
                  element={<PurchaseCreditTable />}
                />
                <Route
                  path="/add-purchase-credit/:itemId/:type"
                  element={<AddPurchaseCredit />}
                />
                <Route
                  path="/view-purchase-credit/:id"
                  element={<ViewPurchaseCredit />}
                />
                <Route
                  path="/edit-purchase-credit/:id"
                  element={<EditPurchaseCredit />}
                />
                {/* PURCHASE PAYMENT OUT */}
                <Route
                  path="/purchase-payment-out"
                  element={<PurchasePaymentOut />}
                />
                <Route
                  path="/add-purchase-payment-out"
                  element={<AddPurchasePaymentOut />}
                />
                <Route
                  path="/view-payment-out/:id"
                  element={<ViewPaymentOut />}
                />
                {/* BULK PURCHASE ORDER */}
                <Route
                  path="/bulk-purchase-order"
                  element={<BulkPurchaseOrder />}
                />
                {/* //CUSTOMER  */}
                <Route path="/add-customer" element={<AddCustomer />} />
                <Route path="/customer" element={<CustomerTable />} />
                <Route path="/view-customer/:id" element={<ViewCustomer />} />
                <Route path="/edit-customer/:id" element={<EditCustomer />} />
                {/* //DOCTOR  */}
                <Route path="/add-doctor" element={<AddDoctor />} />
                <Route path="/doctor" element={<DoctorTable />} />
                <Route path="/view-doctor/:id" element={<ViewDoctor />} />
                <Route path="/edit-doctor/:id" element={<EditDoctor />} />
                {/* //PRODUCT  */}
                <Route path="/add-product" element={<AddProduct />} />
                <Route path="/product" element={<ProductTable />} />
                <Route path="/view-product/:id" element={<ViewProduct />} />
                <Route path="/edit-product/:id" element={<EditProduct />} />
                {/* //STORE  */}
                <Route path="/add-store" element={<AddStore />} />
                <Route path="/store-information" element={<StoreTable />} />
                <Route path="/view-store/:id" element={<ViewStore />} />
                <Route path="/edit-store/:id" element={<EditStore />} />
                {/* //CUSTOMER  */}
                <Route path="/add-user" element={<AddUser />} />
                <Route path="/user" element={<UserTable />} />
                <Route path="/view-user/:id" element={<ViewUser />} />
                <Route path="/edit-user/:id" element={<EditUser />} />
                {/* //Discounts  */}
                <Route path="/price-list" element={<PriceListTable />} />
                <Route path="/add-price-list" element={<AddPriceList />} />
                <Route path="/discount-group" element={<DiscountGroup />} />
                <Route
                  path="/period-volume"
                  element={<PeriodVolumeDiscounts />}
                />
                <Route
                  path="/edit-price-list/:id"
                  element={<EditPriceList />}
                />
                {/* //Inventory  */}
                <Route path="/inventory" element={<InventoryBase />} />
                <Route path="/create-warehouse" element={<CreateWarehouse />} />
                <Route
                  path="/bin-location-management"
                  element={<BinLocationManagement />}
                />

                {/* STORE TRANSFER */}
                <Route
                  path="/add-stock-transfer"
                  element={<AddStoreTransfer />}
                />
                <Route
                  path="/stock-transfer"
                  element={<StockTransferTable />}
                />
                <Route
                  path="/view-stock-transfer/:id"
                  element={<ViewStockTransfer />}
                />
                {/* //CUSTOMER  */}
                <Route path="/under-dev/:page" element={<UnderDevelopment />} />
                <Route path="/sales-reports" element={<SalesReports />} />
                {/* //Account  */}
                <Route path="/chart-of-account" element={<ChartOfAccount />} />
                <Route
                  path="/account-allocation"
                  element={<GlAccountAllocation />}
                />
                <Route path="/journal-entry/:id" element={<JournalEntry />} />
                <Route path="/journal-entry" element={<JournalTable />} />
                {/*--------------------------SETTINGS ---------------------------------------*/}
                <Route path="/settings" element={<Settings />} />
                <Route
                  path="/settings/manufacturer-setup"
                  element={<ManufacturerSetup />}
                />
                <Route
                  path="/settings/shipping-setup"
                  element={<ShippingSetup />}
                />
                <Route path="/settings/uom-setup" element={<UnitOfMeasure />} />
                <Route
                  path="/settings/uom-group-setup"
                  element={<UomGroup />}
                />
                <Route path="/settings/item-setup" element={<ItemSetup />} />
                <Route path="/settings/tax-setup" element={<TaxSetup />} />
                <Route
                  path="/settings/alternative-item-setup"
                  element={<AlternativeItemSetup />}
                />
                <Route
                  path="/settings/document-numbering-series"
                  element={<DocumentNumbering />}
                />
                <Route
                  path="/settings/smtp-configuration"
                  element={<SmtpConfiguration />}
                />
                <Route
                  path="/settings/whatsapp-configuration"
                  element={<WhatsappConfiguration />}
                />
                <Route
                  path="/settings/sms-configuration"
                  element={<SmsConfiguration />}
                />
                <Route
                  path="/settings/razorpay-configuration"
                  element={<RazorPayConfiguration />}
                />
                <Route
                  path="/settings/stripe-configuration"
                  element={<StripeConfiguration />}
                />
                <Route
                  path="/settings/phonepe-configuration"
                  element={<PhonePeConfiguration />}
                />
                <Route
                  path="/settings/paytm-configuration"
                  element={<PaytmConfiguration />}
                />
                <Route
                  path="/settings/pine-labs-configuration"
                  element={<PineLabConfiguration />}
                />

                <Route
                  path="/settings/print-preference"
                  element={<PrintPreference />}
                />

                <Route path="/reports" element={<Reports />} />
                <Route
                  path="/reports/sales/daily-billing"
                  element={<DailyBillingSalesReport />}
                />
                <Route
                  path="/reports/sales/over-all"
                  element={<OverallSalesReport />}
                />
                <Route
                  path="/reports/sales/product-wise"
                  element={<ProductWiseSalesReport />}
                />
                <Route
                  path="/reports/sales/branch-wise"
                  element={<BranchWiseSalesReport />}
                />
                <Route
                  path="/reports/sales/doctor-wise"
                  element={<DoctorWiseSalesReport />}
                />
                <Route
                  path="/reports/sales/party-wise"
                  element={<PartyWiseSalesReport />}
                />
                <Route
                  path="/reports/sales/payment-mode-wise"
                  element={<PaymentModeWiseSalesReport />}
                />

                <Route
                  path="/reports/financial/party-wise-profit"
                  element={<PartyWiseProfitReport />}
                />
                <Route
                  path="/reports/financial/discount-analysis"
                  element={<DiscountAnalysisReport />}
                />

                <Route
                  path="/reports/sales-return/sales-return"
                  element={<SalesReturnReport />}
                />
                <Route
                  path="/reports/customer/purchase-frequency"
                  element={<CustomerPurchaseFreqReport />}
                />
                <Route
                  path="/reports/customer/purchase-behavior"
                  element={<CustomerPurchaseBehaviorReport />}
                />
                <Route
                  path="/reports/inventory/bounce"
                  element={<BounceReport />}
                />
                <Route
                  path="/reports/inventory/stock-sales"
                  element={<StockSalesReport />}
                />
                <Route
                  path="/reports/inventory"
                  element={<InventoryReport />}
                />
                <Route
                  path="/reports/scheduled-hdrug-reg"
                  element={<ScheduledHdrugRegReport />}
                />
                <Route
                  path="/reports/expiry-loss"
                  element={<ExpiryLossReport />}
                />
                <Route
                  path="/reports/slow-fast-moving-products"
                  element={<SlowFastMovingProductReport />}
                />
                {/* Gst Reports */}
                <Route path="/reports/gstr-1" element={<Gstr1Report />} />
                <Route path="/reports/gstr-2a" element={<Gstr2aReport />} />
                <Route
                  path="/reports/hsn-code-summary"
                  element={<GstHsnCodeReport />}
                />
                <Route path="/reports/gstr-3b" element={<Gstr3bReport />} />
                <Route path="/reports/gstr-9" element={<Gstr9Report />} />
                <Route path="/reports/gstr-9c" element={<Gstr9cReport />} />
                <Route
                  path="/reports/gst-itc-mismatch"
                  element={<GstItcMisMatchReport />}
                />
                <Route
                  path="/reports/gst-e-way-bill"
                  element={<GstEwayBillReport />}
                />
                <Route
                  path="/reports/gst-payment-summary"
                  element={<GstPaymentSummaryReport />}
                />
                <Route
                  path="/reports/gst-itc-aging"
                  element={<GstItcAgingReport />}
                />
                {/*--------------------------DASHBOARD ---------------------------------------*/}

                <Route path="/dashboard" element={<DashBoard />} />
              </Route>
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route path="/login" element={<UserLogin />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<PasswordReset />} />
            </Route>
          </Routes>
        </ErrorBoundary>
        <ToastContainer limit={2} />
      </Router>
    </>
  );
};

export default Index;
