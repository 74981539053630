import { Field, useFormikContext } from "formik";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "../../../assets/icons/delete-icon";
import { url } from "../../../redux/config";
import "../../forms/form.css";
import SearchAddFeild from "../../forms/search-add-field";
import errorToast from "../../helper-components/error-toast";
import { api } from "../../../redux/apis/api";
import useBarcodeScanner from "../../helper-components/helper-function";
import { invoiceScannedItemAction } from "../../../redux/actions/invoices-action";
import { salesProductListAction } from "../../../redux/actions/sales-action";
import SelectField from "../../forms/select-field";

const SalesInvoiceBillingTable = ({
  initialData,
  disabled = false,
  addBatchFn = () => {},
  searchRef,
}) => {
  const inputRefs = useRef(null);
  const { values, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const [localData, setLocalData] = useState([]);
  const tableHeading = [
    { label: "SR No.", value: "sl_no" },
    { label: "Product Name", value: "item_product_name" },
    { label: "Open quantity", value: "item_to_be_delivered" },
    { label: "Quantity", value: "item_quantity" },
    { label: "UOM", value: "item_uom" },
    { label: "Batch No.", value: "item_batch_no" },
    { label: "Unit Price (₹)", value: "item_unit_price" },
    { label: "Discount (₹)", value: "item_discount_amount" },
    { label: "Discount (%)", value: "item_discount_percentage" },
    { label: "Tax (₹)", value: "item_tax_amount" },
    { label: "Total (₹)", value: "item_total" },
    ...(disabled ? [] : [{ label: "", value: "" }]),
  ];

  const fetchData = async (inputValue, callback, field) => {
    const onSuccess = (data) => {
      const options =
        data?.data === null
          ? []
          : data.data
              .map((item) => ({
                value: item.item_id,
                label: item[field],
              }))
              .filter(
                (option) =>
                  !values?.data?.some((item) => item?.item_id === option.value)
              );
      setLocalData(data.data);
      callback(options);
    };
    const onError = (data) => {};
    if (inputValue?.length > 0) {
      dispatch(
        salesProductListAction(
          {
            field: field,
            value: inputValue,
          },
          onSuccess,
          onError
        )
      );
    }
  };

  const removeRow = (rowIndex) => {
    const updatedData = [...values.data];
    updatedData.splice(rowIndex, 1);
    setFieldValue("data", updatedData);
  };

  async function discountFn(value) {
    try {
      const response = await api.get(
        `${url}/discount/get?totalAmountOfItem=${value}`,
        JSON.stringify(value)
      );
      const discount = response.data.data.item_discount_amount || 0;
      const tax = response.data.data.item_tax_amount || 0;
      const item_discount_percentage =
        response.data.data.item_discount_percentage || 0;

      return {
        discount: discount,
        tax: tax,
        item_discount_percentage: item_discount_percentage,
      };
    } catch (error) {
      onErrorDis(error);
      return 0;
    }
  }

  const onErrorDis = (data) => {};

  //ON QUANTITY CHANGE
  const handleQuantityChange = async (event, rowIndex) => {
    const updatedData = values.data.map((row, index) =>
      index === rowIndex
        ? { ...row, quantity: parseFloat(event.target.value) }
        : row
    );
    setFieldValue("data", updatedData);
    const totalAmount =
      parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
      parseFloat(event.target.value);

    const item = values.data[rowIndex];
    const stockApiUrl = `${url}/v1/inventory/stock/items?item_id=${item.item_id}&item_quantity=${event.target.value}`;
    if (parseFloat(event?.target?.value) > 0) {
      try {
        const stockApiResponse = await api.get(stockApiUrl);
        if (
          stockApiResponse?.data?.code === 200 &&
          stockApiResponse?.data?.data?.itemDetails?.length > 0
        ) {
          const itemDetail = stockApiResponse?.data?.data?.itemDetails;
          const itemBatchList = stockApiResponse?.data?.data?.itemBatchList;

          try {
            const response = await api.get(
              `${url}/discount/get?totalAmountOfItem=${
                values?.data?.[rowIndex]?.quantity *
                parseFloat(values?.data?.[rowIndex]?.item_unit_price)
              }&item_quantity=${values?.data?.[rowIndex]?.quantity}&item_id=${
                values?.data?.[rowIndex]?.item_id
              }&cmr_phone_number=${values?.phone?.label}`,
              JSON.stringify(totalAmount)
            );

            const discount =
              response.data?.code === 200
                ? response.data.data.item_discount_amount
                : 0;
            const tax =
              response.data?.code === 200
                ? response.data.data.item_tax_amount
                : 0;
            const item_discount_percentage =
              response.data?.code === 200
                ? response.data.data.item_discount_percentage
                : 0;

            const updatedDataRow = {
              ...updatedData[rowIndex],
              item_discount_amount: discount,
              item_tax_amount: tax,
              item_discount_percentage: item_discount_percentage,
              item_batches:
                itemDetail?.map((batch) => ({
                  item_batch_number: batch?.batchNumber,
                  item_batch_quantity: batch?.quantity,
                  item_exp_date: batch?.expiryDate,
                  item_id: item.item_id,
                })) ?? [],
              item_gst:
                response.data?.code === 200
                  ? response.data.data.item_total_tax_percentage
                  : 0,
              item_sgst:
                response.data?.code === 200
                  ? (parseFloat(tax) / 2).toFixed(2)
                  : 0,
              item_cgst:
                response.data?.code === 200
                  ? (parseFloat(tax) / 2).toFixed(2)
                  : 0,
              item_igst:
                response.data?.code === 200
                  ? (
                      values?.data?.[rowIndex]?.quantity *
                      parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
                      (response.data.data.igstRate / 100)
                    )?.toFixed(2)
                  : 0,

              item_total_amount:
                parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
                parseFloat(values?.data?.[rowIndex]?.quantity),
              itemBatchList: itemBatchList ?? [],
              instock: true,
            };

            updatedData[rowIndex] = updatedDataRow;

            setFieldValue("data", [...updatedData]);
          } catch (error) {
            onErrorDis(error);
          }
        } else {
          const updatedDataWithInstock = updatedData.map((row, index) =>
            index === rowIndex
              ? {
                  ...row,
                  instock: false,
                  item_discount_amount: 0,
                  item_tax_amount: 0,
                  item_discount_percentage: 0,
                }
              : row
          );
          setFieldValue("data", updatedDataWithInstock);
          errorToast(
            `Product ${item.item_name} with quantity ${event.target.value} is not available`
          );
        }
      } catch (error) {
        const updatedDataWithError = updatedData.map((row, index) =>
          index === rowIndex
            ? {
                ...row,
                instock: false,
                item_discount_amount: 0,
                item_tax_amount: 0,
                item_discount_percentage: 0,
              }
            : row
        );
        setFieldValue("data", updatedDataWithError);
        errorToast(
          `${item.item_name} available quantity is ${error?.response?.data?.data?.itemDetails?.item_sellable_quantity}`
        );
      }
    } else {
      const updatedData = values.data.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              quantity: event?.target?.value,
              instock: false,
              item_discount_amount: 0,
              item_tax_amount: 0,
              item_discount_percentage: 0,
            }
          : row
      );
      setFieldValue("data", updatedData);
    }
  };

  const handleDisChange = (event, rowIndex) => {
    if (event?.target?.value >= 0) {
      const item_discount_amount = event.target.value;
      const totalAmountwithoutDis =
        parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
        parseFloat(values?.data?.[rowIndex]?.quantity);
      const item_discount_percentage =
        (item_discount_amount / totalAmountwithoutDis) * 100;
      const updatedData = values.data.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              item_discount_amount: item_discount_amount,
              item_discount_percentage: item_discount_percentage,
            }
          : row
      );
      setFieldValue("data", updatedData);
      const totalAmount =
        parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
          parseFloat(values?.data?.[rowIndex]?.quantity) -
        parseFloat(event.target.value) +
        parseFloat(values?.data?.[rowIndex]?.item_tax_amount);

      const updatedDataWithTotal = updatedData.map((row, index) =>
        index === rowIndex ? { ...row, item_total_amount: totalAmount } : row
      );
      setFieldValue("data", updatedDataWithTotal);
    }
  };
  const handleDisPerChange = (event, rowIndex) => {
    if (event?.target?.value >= 0) {
      const item_discount_percentage =
        event.target.value <= 100 ? event.target.value : 0;
      const totalAmountwithoutDis =
        parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
        parseFloat(values?.data?.[rowIndex]?.quantity);
      const item_discount_amount =
        (totalAmountwithoutDis * item_discount_percentage) / 100;
      const updatedData = values.data.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              item_discount_amount: item_discount_amount,
              item_discount_percentage: item_discount_percentage,
            }
          : row
      );
      setFieldValue("data", updatedData);
      const totalAmount =
        parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
          parseFloat(values?.data?.[rowIndex]?.quantity) -
        parseFloat(item_discount_amount) +
        parseFloat(values?.data?.[rowIndex]?.item_tax_amount);

      const updatedDataWithTotal = updatedData.map((row, index) =>
        index === rowIndex ? { ...row, item_total_amount: totalAmount } : row
      );
      setFieldValue("data", updatedDataWithTotal);
    }
  };

  const hasDuplicateItemId = (itemId, currentIndex) => {
    const itemIdsExceptCurrent = values.data
      .filter((_, index) => index !== currentIndex)
      .map((item) => item.item_id);

    return itemIdsExceptCurrent.includes(itemId);
  };

  useBarcodeScanner((barcode) => {
    if (barcode) {
      dispatch(
        invoiceScannedItemAction(barcode, onSuccessScanned, onErrorScanned)
      );
    }
  });

  const onSuccessScanned = (data) => {
    const itemData = data?.data?.item_information;
    if (
      !values?.data?.some(
        (item) => item?.item_id === data?.data?.item_information?.item_id
      )
    ) {
      const newdata = {
        sl_no: 1,
        item_code: itemData?.item_code,
        item_generic_name: itemData?.item_generic_name,
        item_name: itemData?.item_name,
        item_to_be_delivered: 0,
        quantity: 1,
        item_uom: itemData?.item_uom,
        item_batches: data?.data?.item_fullfilled_Batches.map((batch) => ({
          item_batch_number: batch?.batchNumber,
          item_batch_quantity: batch?.quantity,
          item_exp_date: batch?.expiryDate,
          item_id: itemData?.item_id,
        })),
        item_unit_price: itemData?.item_unit_price,
        item_discount_amount: data.data.discount_details?.item_discount_amount,
        item_discount_percentage: parseFloat(
          data.data.discount_details?.item_discount_percentage
        )?.toFixed(2),
        item_tax_amount: data.data.discount_details?.item_tax_amount,
        item_total_amount:
          parseFloat(itemData?.item_unit_price) * 1 -
          data.data.discount_details?.item_discount_amount +
          data.data.discount_details?.item_tax_amount,
        item_id: itemData?.item_id,
        instock: true,
        itemBatchList: data?.data?.itemBatchList,
        item_gst: data.data.discount_details?.item_total_tax_percentage,
        item_sgst: (
          (parseFloat(itemData?.item_unit_price) -
            parseFloat(data.data.discount_details?.item_discount_amount)) *
          (data.data.discount_details?.sgstRate / 100)
        )?.toFixed(2),
        item_cgst: (
          (parseFloat(itemData?.item_unit_price) -
            parseFloat(data.data.discount_details?.item_discount_amount)) *
          (data.data.discount_details?.cgstRate / 100)
        )?.toFixed(2),
        item_igst: (
          (parseFloat(itemData?.item_unit_price) -
            parseFloat(data.data.discount_details?.item_discount_amount)) *
          (data.data.discount_details?.igstRate / 100)
        )?.toFixed(2),
      };
      const oldData = [...values?.data];
      oldData.pop();
      setFieldValue("data", [...oldData, newdata, initialData]);
      // successToast(data?.message);
    } else {
      errorToast(
        "Item already added to invoice. Please adjust quantity if you wish to add more."
      );
    }
  };
  const onErrorScanned = (data) => {
    errorToast(data?.data?.message);
  };
  return (
    <>
      <div
        className="table-container mt-3"
        style={{
          maxHeight: `calc(100% - 15rem)`,
          height: `calc(100% - 15rem)`,
        }}
      >
        <table className="my-table m-0">
          <thead className="sticky top-0 z-10">
            <tr className="bg-F2F4F5">
              {tableHeading.map((heading, index) => (
                <th
                  key={index}
                  className={`${
                    index === tableHeading?.length - 1
                      ? "table-header"
                      : "table-header"
                  } whitespace-nowrap text-sm fw_500 text-475467 ${
                    index === 2 ||
                    index === 3 ||
                    index === 6 ||
                    index === 7 ||
                    index === 8 ||
                    index === 9 ||
                    index === 10
                      ? "text-right w-[5rem]"
                      : "text-left"
                  }
                  ${index === 4 && "min-w-[8rem] p-0"}
                  ${index === 5 && "text-center w-[5rem]"} ${
                    (index === 11 || index === 0) && "w-[2.5rem]"
                  } ${index === 1 ? "w-full min-w-[15rem]" : ""} `}
                >
                  {heading?.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {values?.data?.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {Object?.values(rowData)?.map((cellData, colIndex) => {
                  if (colIndex < 13 && colIndex !== 1 && colIndex !== 2) {
                    return (
                      <td
                        key={colIndex}
                        className={`table-cell text-sm fw_400 text-secondary ${
                          colIndex === 8 ||
                          colIndex === 9 ||
                          colIndex === 10 ||
                          colIndex === 11 ||
                          colIndex === 4 ||
                          colIndex === 12
                            ? "text-end"
                            : ""
                        } ${colIndex === 7 && "text-center"} `}
                      >
                        {colIndex === 1 ||
                        colIndex === 2 ||
                        colIndex === 3 ||
                        colIndex === 5 ? (
                          colIndex !== 5 ? (
                            <div>
                              <SearchAddFeild
                                searchRef={colIndex === 3 ? searchRef : {}}
                                isDisabled={
                                  values?.data?.length - 1 !== rowIndex ||
                                  disabled
                                    ? true
                                    : false
                                }
                                errorClass="hidden"
                                isRequired={false}
                                value={{
                                  label:
                                    values?.data[rowIndex][
                                      Object.keys(rowData)[colIndex]
                                    ],
                                  value: "",
                                }}
                                name={`data[${rowIndex}].${
                                  Object.keys(rowData)[colIndex]
                                }`}
                                field={Object.keys(rowData)[colIndex]}
                                isLabelReq={false}
                                defaultSelectClass=""
                                placeholder=""
                                fetchData={fetchData}
                                onChange={async (selectedOption) => {
                                  const data = await discountFn(
                                    parseFloat(
                                      localData?.find(
                                        (item) =>
                                          item?.item_id ===
                                          selectedOption?.value
                                      )?.item_unit_price
                                    ) * Object?.values(rowData)?.[4]
                                  );

                                  const filteredData = localData
                                    ?.filter(
                                      (item) =>
                                        item?.item_id === selectedOption?.value
                                    )
                                    .map((item) => ({
                                      sl_no: 1,
                                      item_code: item.item_code,
                                      item_generic_name:
                                        item?.item_generic_name,
                                      item_name: item.item_name,
                                      item_to_be_delivered: 0,
                                      quantity: "",
                                      item_uom:
                                        item.item_sales_uom_name === null
                                          ? ""
                                          : item.item_sales_uom_name,
                                      item_batches: [],
                                      item_unit_price: item?.item_unit_price,
                                      item_discount_amount:
                                        data?.discount?.toFixed(0),
                                      item_discount_percentage:
                                        data?.item_discount_percentage,
                                      item_tax_amount: data?.tax?.toFixed(0),
                                      item_total_amount: 0,
                                      item_id: item?.item_id,
                                      instock: true,

                                      item_uom_id:
                                        item?.item_sales_uom_name_id ?? "",
                                      uom_dropdown: item?.uom_dropdown
                                        ? item?.uom_dropdown.map((uom) => ({
                                            item_uom_id: uom?.item_id,
                                            label: uom?.alt_uom,
                                            value: uom?.alt_uom,
                                          }))
                                        : [],
                                    }));
                                  const oldData = [...values?.data];
                                  oldData.pop();

                                  setFieldValue("data", [
                                    ...oldData,
                                    ...filteredData,
                                    initialData,
                                  ]);
                                  inputRefs.current?.focus();
                                }}
                              />
                            </div>
                          ) : (
                            <div>
                              <Field name={`data[${rowIndex}].quantity`}>
                                {({ field }) => (
                                  <input
                                    min={0}
                                    ref={inputRefs}
                                    {...field}
                                    type="number"
                                    step="1"
                                    placeholder=""
                                    readOnly={
                                      values?.data?.length - 1 === rowIndex ||
                                      hasDuplicateItemId(
                                        rowData.item_id,
                                        rowIndex
                                      ) ||
                                      disabled
                                    }
                                    // onKeyDown={(event) => {
                                    //   if (
                                    //     event.key === "-" ||
                                    //     event.key === "e" ||
                                    //     event.key === "."
                                    //   ) {
                                    //     event.preventDefault();
                                    //   }
                                    //   if (
                                    //     event.key === "Tab" ||
                                    //     event.key === "Enter"
                                    //   ) {
                                    //     handleQuantityChange(event, rowIndex);
                                    //     setFieldValue(
                                    //       `data[${rowIndex}].quantity`,
                                    //       event.target.value
                                    //     );
                                    //   }
                                    // }}
                                    onKeyDown={(event) => {
                                      if (
                                        event.key === "-" ||
                                        event.key === "e" ||
                                        event.key === "."
                                      ) {
                                        event.preventDefault();
                                      }
                                      if (
                                        event.key === "Tab" ||
                                        event.key === "Enter"
                                      ) {
                                        if (searchRef && searchRef.current) {
                                          setTimeout(() => {
                                            searchRef.current.focus();
                                          }, 0);
                                        }
                                      }
                                    }}
                                    onBlur={(event) => {
                                      if (
                                        values?.data?.length - 1 === rowIndex ||
                                        hasDuplicateItemId(
                                          rowData.item_id,
                                          rowIndex
                                        ) ||
                                        disabled
                                      ) {
                                        event?.preventDefault();
                                      } else {
                                        handleQuantityChange(event, rowIndex);
                                      }
                                    }}
                                    className="focus-within:ring-2 text-right px-2 w-full py-3 rounded focus-within:outline-none focus-within:shadow-[0px_0px_0px_4px_#EBF2FF input-field-no-spin-buttons"
                                  />
                                )}
                              </Field>
                            </div>
                          )
                        ) : colIndex === 0 ? (
                          <div>
                            <label
                              htmlFor={rowIndex}
                              className="block cursor-pointer select-none rounded-xl p-3 text-center hover:bg-slate-100 peer-checked:bg-blue-100 peer-checked:font-bold peer-checked:outline-none peer-checked:ring-2 peer-checked:ring-blue-500"
                            >
                              {rowIndex + 1}
                            </label>
                          </div>
                        ) : colIndex === 7 ? (
                          <>
                            {rowIndex < values?.data?.length - 1 &&
                              rowData.item_batches?.length > 0 && (
                                <button
                                  onClick={() => addBatchFn(rowData?.item_id)}
                                  type="button"
                                  className="text-secondary fw_500 text-sm opacity-30"
                                >
                                  Click to Update
                                </button>
                              )}
                            {Array.isArray(rowData.item_batches) &&
                              rowData?.item_batches.map((batch, index) => (
                                <div className="whitespace-nowrap" key={index}>
                                  {batch?.item_batch_number} -{" "}
                                  {batch?.item_batch_quantity} -{" "}
                                  {moment(batch?.item_exp_date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </div>
                              ))}
                          </>
                        ) : colIndex === 9 ? (
                          <>
                            <div>
                              <input
                                style={{
                                  textAlign: "right",
                                }}
                                readOnly={
                                  values?.data?.length - 1 === rowIndex
                                    ? true
                                    : false
                                }
                                type="text"
                                onChange={(event) => {
                                  handleDisChange(event, rowIndex);
                                }}
                                name={`data[${rowIndex}].item_discount_amount`}
                                value={
                                  values.data[rowIndex].item_discount_amount < 0
                                    ? 0
                                    : values.data[rowIndex].item_discount_amount
                                }
                                className="border-0 focus_outline__none w-full "
                              />
                            </div>
                          </>
                        ) : colIndex === 10 ? (
                          <>
                            <div>
                              <input
                                style={{
                                  textAlign: "right",
                                }}
                                readOnly={
                                  values?.data?.length - 1 === rowIndex
                                    ? true
                                    : false
                                }
                                type="number"
                                min="0"
                                max="100"
                                onChange={(event) => {
                                  handleDisPerChange(event, rowIndex);
                                }}
                                name={`data[${rowIndex}].item_discount_percentage`}
                                value={
                                  values.data[rowIndex]
                                    .item_discount_percentage < 0
                                    ? 0
                                    : values.data[rowIndex]
                                        .item_discount_percentage
                                }
                                className="border-0 focus_outline__none w-full input-field-no-spin-buttons"
                              />
                            </div>
                          </>
                        ) : colIndex === 11 || colIndex === 12 ? (
                          parseFloat(cellData)?.toFixed(2)
                        ) : colIndex === 6 ? (
                          <>
                            <SelectField
general_color={false}
                              handleSelected={(sel) => {
                                setFieldValue(
                                  `data[${rowIndex}].item_uom_id`,
                                  sel?.item_uom_id
                                );
                              }}
                              borderclass=""
                              defaultSelectClass="border-0"
                              selectClass="w-full"
                              defaultValue={
                                values.data[rowIndex]?.item_uom
                                  ? {
                                      label:
                                        values.data[rowIndex]?.item_uom ?? "",
                                    }
                                  : null
                              }
                              isDisabled={disabled}
                              errMsgContainerClass="hidden"
                              id={`data[${rowIndex}].item_uom`}
                              isContainer={false}
                              options={values.data[rowIndex]?.uom_dropdown}
                              name={`data[${rowIndex}].item_uom`}
                              placeholder={"Select UOM"}
                            />
                          </>
                        ) : colIndex === 6 ? (
                          <>
                            <SelectField
                              dynColor="var(--secondary)"
                              handleSelected={(sel) => {
                                setFieldValue(
                                  `data[${rowIndex}].item_uom_id`,
                                  sel?.item_uom_id
                                );
                              }}
                              borderclass=""
                              defaultSelectClass="border-0"
                              selectClass="w-full"
                              defaultValue={
                                values.data[rowIndex]?.item_uom
                                  ? {
                                      label:
                                        values.data[rowIndex]?.item_uom ?? "",
                                    }
                                  : null
                              }
                              isDisabled={disabled}
                              errMsgContainerClass="hidden"
                              id={`data[${rowIndex}].item_uom`}
                              isContainer={false}
                              options={values.data[rowIndex]?.uom_dropdown}
                              name={`data[${rowIndex}].item_uom`}
                              placeholder={"Select UOM"}
                            />
                          </>
                        ) : (
                          cellData
                        )}
                      </td>
                    );
                  }
                  return null;
                })}
                {!disabled && (
                  <td className="last-column">
                    {rowIndex !== values?.data?.length - 1 && (
                      <DeleteIcon onClickFn={() => removeRow(rowIndex)} />
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SalesInvoiceBillingTable;
