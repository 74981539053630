import { Field, useFormikContext } from "formik";
import React from "react";
import SelectField from "../../../components/forms/select-field";
import AddButton from "../../../components/buttons/button";
import DeleteIcon from "../../../assets/icons/delete-icon";
import { api } from "../../../redux/apis/api";
import { url } from "../../../redux/config";
import {
  decimalNumberFn,
  positiveIntegerFn,
} from "../../helper-components/helper-function";
import moment from "moment";
import errorToast from "../../helper-components/error-toast";

const BatchQuantityExpiryPopover = ({
  setAddbatch,
  itemId,
  setItemId,
  binLocationList = [],
}) => {
  const { values, setFieldValue, errors } = useFormikContext();
  const defaultSelectedItem = values?.data?.find(
    (item, index) => itemId === item?.item_id
  );
  const defaultSelectedItemIndex = values?.data?.findIndex(
    (item, index) => itemId === item?.item_id
  );
  const initData = {
    item_id: defaultSelectedItem?.item_id,
    item_batch_number: "",
    item_batch_purchase_rate: 0,
    item_batch_unit_price: 0,
    item_sellable_quantity: null,
    item_non_sellable_quantity: 0,
    item_batch_free_quantity: 0,
    item_exp_date: moment(new Date()).format("YYYY-MM-DD"),
    item_mfg_date: "2023/12/02",
    from_bin_location: "",
    to_bin_location: "receiving-bin",
    to_bin_id: binLocationList?.find(
      (bin) => bin?.actualLabel === "receiving-bin"
    )?.value,
    from_bin_id: "",
  };

  // const handleQuantityChange = async (rowIndex, batchIndex, qty, del) => {
  //   let updatedData = [...values.data];

  //   if (del) {
  //     const updatedBatches = [...updatedData[rowIndex].item_batches];
  //     updatedBatches.splice(batchIndex, 1);
  //     updatedData[rowIndex].item_batches = updatedBatches;
  //   } else {
  //     updatedData = updatedData.map((row, index) => {
  //       if (index === rowIndex) {
  //         const updatedItemBatches = row.item_batches.map((batch, idx) => {
  //           if (idx === batchIndex) {
  //             return {
  //               ...batch,
  //               item_sellable_quantity: parseFloat(qty),
  //             };
  //           } else {
  //             return batch;
  //           }
  //         });
  //         return { ...row, item_batches: updatedItemBatches };
  //       } else {
  //         return row;
  //       }
  //     });
  //   }

  //   setFieldValue("data", updatedData);

  //   const itemBatches = updatedData[rowIndex].item_batches;
  //   let sumQuantity = 0;

  //   for (let i = 0; i < itemBatches.length; i++) {
  //     sumQuantity += parseFloat(itemBatches[i].item_sellable_quantity) || 0;
  //   }

  //   try {
  //     const response = await api.get(
  //       `${url}/discount/get?totalAmountOfItem=${
  //         sumQuantity * parseFloat(values?.data?.[rowIndex]?.item_unit_price)
  //       }&item_quantity=${sumQuantity}&item_id=${
  //         values?.data?.[rowIndex]?.item_id
  //       }&cmr_phone_number=${values?.cmr_phone_number}`
  //     );

  //     const discount =
  //       response.data?.code === 200
  //         ? response.data.data.item_discount_amount
  //         : 0;
  //     const tax =
  //       response.data?.code === 200 ? response.data.data.item_tax_amount : 0;
  //     const item_discount_percentage =
  //       response.data?.code === 200
  //         ? response.data.data.item_discount_percentage
  //         : 0;

  //     const updatedDataRow = {
  //       ...updatedData[rowIndex],
  //       quantity: sumQuantity,
  //       item_discount_amount: discount,
  //       item_tax_amount: tax,
  //       item_discount_percentage: item_discount_percentage,
  //       // item_price_wiithout_tax: (
  //       //   parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
  //       //     parseFloat(sumQuantity) -
  //       //     parseFloat(discount) -
  //       //     parseFloat(tax) || 0
  //       // ).toFixed(2),
  //       item_gst:
  //         response.data?.code === 200
  //           ? response.data.data.item_total_tax_percentage
  //           : 0,
  //       item_sgst:
  //         response.data?.code === 200 ? (parseFloat(tax) / 2).toFixed(2) : 0,
  //       item_cgst:
  //         response.data?.code === 200 ? (parseFloat(tax) / 2).toFixed(2) : 0,
  //       item_igst:
  //         response.data?.code === 200
  //           ? (
  //               (sumQuantity *
  //                 parseFloat(values?.data?.[rowIndex]?.item_unit_price) -
  //                 parseFloat(discount)) *
  //               (response.data.data.igstRate / 100)
  //             ).toFixed(2)
  //           : 0,
  //       item_total_amount: (
  //         parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
  //           parseFloat(sumQuantity) -
  //         parseFloat(discount)
  //       )
  //         //  +
  //         // parseFloat(tax)
  //         .toFixed(2),
  //       instock: true,
  //     };

  //     updatedData[rowIndex] = updatedDataRow;

  //     setFieldValue("data", [...updatedData]);
  //   } catch (error) {
  //     onErrorDis(error);
  //   }
  // };
  const handleQuantityChange = async (rowIndex, batchIndex, qty, pr) => {
    let updatedData = [...values.data];
    console.log(rowIndex, batchIndex, qty, pr, "dsfsfsdffgdsdfdsfsdf");
    if (qty > 0 && parseFloat(pr) > 0) {
      try {
        const response = await api.get(
          `${url}/discount/get?totalAmountOfItem=${
            qty * pr
          }&item_quantity=${qty}&item_id=${
            values?.data?.[rowIndex]?.item_id
          }&cmr_phone_number=${values?.cmr_phone_number}`
        );
        console.log(rowIndex, batchIndex, qty, pr, "dsfsfsdffgdsdfdsfsdf");
        console.log(response, "dsfsfsdffgdsdfdsfsdf");

        if (response.data?.code === 200) {
          const {
            item_discount_amount,
            item_tax_amount,
            item_discount_percentage,
            igstRate,
            item_total_tax_percentage,
          } = response.data.data;
          console.log(rowIndex, batchIndex, qty, pr, "dsfsfsdffgdsdfdsfsdf");

          // Batch-level calculations
          const discount = item_discount_amount || 0;
          const tax = +item_tax_amount || 0;
          const sgst = +(tax / 2).toFixed(2);
          const cgst = +(tax / 2).toFixed(2);
          const igst = 0;
          const updatedBatch = {
            ...updatedData[rowIndex].item_batches[batchIndex],
            item_sellable_quantity: +qty,
            item_batch_purchase_rate: +pr,
            item_batch_discount: +discount,
            item_batch_discount_percentage: +item_discount_percentage,
            item_batch_sgst: +sgst,
            item_batch_cgst: +cgst,
            item_batch_igst: +igst,
            item_batch_tax_amount: +tax,
            item_total_amount: pr * qty - discount.toFixed(2),
          };

          updatedData[rowIndex].item_batches[batchIndex] = updatedBatch;
          // Calculate aggregated values for the parent row
          const sumQuantity = updatedData[rowIndex].item_batches.reduce(
            (sum, batch) =>
              sum + (parseFloat(batch.item_sellable_quantity) || 0),
            0
          );
          const totalDiscount = updatedData[rowIndex].item_batches.reduce(
            (sum, batch) => sum + (parseFloat(batch.item_batch_discount) || 0),
            0
          );
          const totalTax = updatedData[rowIndex].item_batches.reduce(
            (sum, batch) =>
              sum + (parseFloat(batch.item_batch_tax_amount) || 0),
            0
          );
          const totalAmt = updatedData[rowIndex].item_batches.reduce(
            (sum, batch) => sum + (parseFloat(batch.item_total_amount) || 0),
            0
          );
          const totalFreeQty = updatedData[rowIndex].item_batches.reduce(
            (sum, batch) =>
              sum + (parseFloat(batch.item_batch_free_quantity) || 0),
            0
          );
          // Update the parent row
          console.log(rowIndex, batchIndex, qty, pr, "dsfsfsdffgdsdfdsfsdf");

          const updatedDataRow = {
            ...updatedData[rowIndex],
            quantity: sumQuantity,
            item_free_quantity: +totalFreeQty,
            gort_total_discount: +totalDiscount,
            item_tax_amount: +totalTax,
            item_discount_percentage: +item_discount_percentage,
            item_gst: +item_total_tax_percentage || 0,
            item_sgst: +(totalTax / 2).toFixed(2),
            item_cgst: +(totalTax / 2).toFixed(2),
            item_igst: +igst,
            item_total_amount: totalAmt.toFixed(2),
            instock: +sumQuantity > 0,
          };
          updatedData[rowIndex] = updatedDataRow;
          console.log(rowIndex, batchIndex, qty, pr, "dsfsfsdffgdsdfdsfsdf");

          setFieldValue("data", updatedData);
        }
      } catch (error) {
        onErrorDis("Failed to calculate discounts and taxes");
      }
    }
  };

  const onErrorDis = (data) => {
    console.error("API Error:", data);
  };

  const handleShowErrorToast = async () => {
    if (errors?.data && errors.data[defaultSelectedItemIndex]) {
      const dataError = errors.data[defaultSelectedItemIndex];

      if (dataError?.item_batches) {
        let hasError = false;

        // Loop through each item batch and show the first error found in the correct order
        for (const batchError of dataError.item_batches) {
          if (batchError) {
            if (batchError.item_batch_number && !hasError) {
              errorToast(batchError.item_batch_number + "!");
              hasError = true;
            } else if (batchError.item_batch_purchase_rate && !hasError) {
              errorToast(batchError.item_batch_purchase_rate + "!");
              hasError = true;
            } else if (batchError.item_batch_unit_price && !hasError) {
              errorToast(batchError.item_batch_unit_price + "!");
              hasError = true;
            } else if (batchError.item_sellable_quantity && !hasError) {
              errorToast(batchError.item_sellable_quantity + "!");
              hasError = true;
            } else if (batchError.item_batch_free_quantity && !hasError) {
              errorToast(batchError.item_batch_free_quantity + "!");
              hasError = true;
            } else if (batchError.item_exp_date && !hasError) {
              errorToast(batchError.item_exp_date + "!");
              hasError = true;
            } else if (batchError.to_bin_id && !hasError) {
              errorToast(batchError.to_bin_id + "!");
              hasError = true;
            }
          }

          // If an error is found in the batch, stop and don't check the rest of the fields in that batch
          if (hasError) {
            break;
          }
        }

        // If no errors were found, set addBatch to false
        if (!hasError) {
          setAddbatch(false);
        }
      } else {
        setAddbatch(false);
      }
    } else {
      setAddbatch(false);
    }
  };

  console.log(errors, "asfasfafafasfasfa");
  return (
    <div className="absolute w-[-webkit-fill-available]  z-40">
      <div className="  h-[72vh] bg-white flex flex-col justify-between">
        <div>
          <div className="bg-[#F4F6F9] w-full p-3 gap-2 font-semibold text-xl  flex items-center">
            Batch details -
            <SelectField
              general_color={false}
              handleSelected={(selected) => {
                setItemId(selected?.value);
              }}
              containerClass="w-[20rem]"
              placeholder={"Select product"}
              defaultValue={
                defaultSelectedItem
                  ? {
                      label: defaultSelectedItem.item_name,
                      value: defaultSelectedItem.item_id,
                    }
                  : null
              }
              options={values?.data
                ?.map((item) => ({
                  label: item?.item_name,
                  value: item?.item_id,
                }))
                ?.filter((item) => item?.value)}
              label={""}
              id={"item_id"}
              name="item_id"
              isSearchable={true}
              errMsgContainerClass="hidden"
              labelWidth="10.5rem"
            />
          </div>
          <div className="p-3 overflow-y-auto h-[38vh]">
            <table className="lg:w-[100%] w-full border-D0D5DD border border-1 rounded-lg ">
              <thead>
                <tr className="bg-F2F4F5">
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm">
                    Batch No.
                  </th>{" "}
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm">
                    Purchase Rate
                  </th>
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm">
                    MRP
                  </th>
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm">
                    Qty
                  </th>
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm">
                    Free Qty
                  </th>
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm">
                    Exp. Date
                  </th>
                  {/* <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm">
                    From Bin
                  </th> */}
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm">
                    To Bin{" "}
                  </th>
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm"></th>
                </tr>
              </thead>
              <tbody>
                {defaultSelectedItem?.item_batches.map((batch, batchindex) => (
                  <tr key={batchindex}>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm w-[20%]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_number`}
                      >
                        {({ field }) => (
                          <input
                            autoFocus={true}
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault(); // Prevent space from being typed
                              }
                            }}
                            placeholder=""
                            {...field}
                            type="text"
                            className={`w-full focus_outline__none focus:border-primary focus:border rounded-md py-2 px-2`}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm w-[15%]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_purchase_rate`}
                      >
                        {({ field }) => (
                          <input
                            // autoFocus={true}
                            value={field.value ? parseInt(field.value, 10) : ""}
                            onKeyDown={decimalNumberFn}
                            placeholder=""
                            {...field}
                            type="number"
                            onChange={(e) => {
                              field.onChange(e); // Ensure field.onChang
                              handleQuantityChange(
                                defaultSelectedItemIndex,
                                batchindex,
                                +batch?.item_sellable_quantity,
                                +e?.target?.value
                              );
                            }}
                            className={`w-full focus_outline__none focus:border-primary focus:border rounded-md py-2 px-2`}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm w-[15%]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_unit_price`}
                      >
                        {({ field }) => (
                          <input
                            // autoFocus={true}
                            onKeyDown={decimalNumberFn}
                            placeholder=""
                            {...field}
                            type="number"
                            className={`w-full focus_outline__none focus:border-primary focus:border rounded-md py-2 px-2`}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm w-[8%]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_sellable_quantity`}
                      >
                        {({ field, form }) => (
                          <input
                            min={0}
                            placeholder=""
                            {...field}
                            type="number"
                            className={`w-full focus_outline__none focus:border-primary focus:border rounded-md py-2 px-2`}
                            onChange={(e) => {
                              field.onChange(e); // Ensure field.onChang
                              handleQuantityChange(
                                defaultSelectedItemIndex,
                                batchindex,
                                +e?.target?.value,
                                +batch?.item_batch_purchase_rate,
                                false
                              );
                            }}
                            onInput={(e) => {
                              const value = e.target.value;
                              if (value < 0) {
                                form.setFieldValue(field.name, 0);
                              }
                            }}
                            onKeyDown={positiveIntegerFn}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm w-[8%]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_free_quantity`}
                      >
                        {({ field }) => (
                          <input
                            onKeyDown={positiveIntegerFn}
                            placeholder=""
                            {...field}
                            type="number"
                            className={`w-full focus_outline__none focus:border-primary focus:border rounded-md py-2 px-2`}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm w-[15%]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_exp_date`}
                      >
                        {({ field }) => (
                          <input
                            placeholder=""
                            {...field}
                            type="date"
                            className={`w-full focus_outline__none focus:border-primary focus:border rounded-md py-2`}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1  py-2 px-1 text-secondary fw_400 text-sm w-[15%]">
                      <SelectField
                        general_color={false}
                        autoFocus={
                          values.data[defaultSelectedItemIndex]?.item_batches[
                            batchindex
                          ]?.item_batch_number
                        }
                        defaultValue={{
                          label:
                            binLocationList?.find(
                              (bin) =>
                                bin?.value ===
                                values.data[defaultSelectedItemIndex]
                                  ?.item_batches[batchindex]?.to_bin_id
                            )?.label ?? "",
                          value: "",
                        }}
                        handleSelected={(selectedoption) => {
                          setFieldValue(
                            `data.${defaultSelectedItemIndex}.item_batches.${batchindex}.to_bin_location`,
                            selectedoption?.actualLabel
                          );
                        }}
                        labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
                        containerClass="w-full"
                        isDisabled={false}
                        placeholder={"select Bin"}
                        options={binLocationList}
                        label={""}
                        id={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.to_bin_id`}
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.to_bin_id`}
                        isSearchable={true}
                        errMsgContainerClass="ps-2 hidden"
                        labelWidth="7rem"
                      />
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-2 text-secondary fw_400 text-sm w-[4%]">
                      <button
                        onClick={() => {
                          const updatedBatches = [
                            ...defaultSelectedItem.item_batches,
                          ];
                          updatedBatches.splice(batchindex, 1);
                          setFieldValue(
                            `data.${defaultSelectedItemIndex}.item_batches`,
                            updatedBatches
                          );
                          handleQuantityChange(
                            defaultSelectedItemIndex,
                            null,
                            0,
                            true
                          );
                        }}
                        type="button"
                      >
                        <DeleteIcon />
                      </button>
                      {/* )} */}
                    </td>

                    {/* <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm">
                      <SelectField
                        defaultValue={{
                          label:
                            binLocationList?.find(
                              (bin) =>
                                bin?.value ===
                                values.data[defaultSelectedItemIndex]
                                  ?.item_batches[batchindex]?.from_bin_id
                            )?.label ?? "",
                          value: "",
                        }}
                        handleSelected={(selectedoption) => {
                          setFieldValue(
                            `data.${defaultSelectedItemIndex}.item_batches.${batchindex}.from_bin_location`,
                            selectedoption?.actualLabel
                          );
                        }}
                        labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
                        containerClass="w-full"
                        isDisabled={true}
                        placeholder={"select Bin"}
                        options={binLocationList}
                        label={""}
                        id={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.from_bin_id`}
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.from_bin_id`}
                        isSearchable={true}
                        errMsgContainerClass="ps-2 hidden"
                        labelWidth="7rem"
                      />
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>{" "}
            <div className="flex justify-end lg:w-[100%] w-full">
              <AddButton
                className="bg-primary text-white px-8 py-1 mt-2 rounded flex justify-center fw_500 text-sm"
                onclkFn={() => {
                  setFieldValue(
                    `data.${defaultSelectedItemIndex}.item_batches`,
                    [
                      ...values?.data?.[defaultSelectedItemIndex]?.item_batches,
                      initData,
                    ]
                  );
                }}
                prefixIcon={true}
                text=""
                type="button"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between px-10 sticky bottom-0   bg-white py-2 invoice-bottom-container">
          <AddButton
            onclkFn={handleShowErrorToast}
            prefixIcon={false}
            text="Done"
            type="button"
          />
        </div>
      </div>
      <div
        className="bg-black h-[23vh] opacity-50"
        onClick={() => handleShowErrorToast()}
      ></div>
    </div>
  );
};

export default BatchQuantityExpiryPopover;
