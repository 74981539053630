import React, { useEffect, useState } from "react";
import ReportsLayout from "../../../components/reports/reports-layout";
import { productWiseSalesReportAction } from "../../../redux/actions/report-action";
import { useDispatch } from "react-redux";
import moment from "moment";

const Gstr9Report = () => {
  const [dateRangeFilter, setDateRangeFilterValue] = useState({
    from: null,
    to: null,
  });
  const dispatch = useDispatch();
  const [groupByOptions, setgroupByOptions] = useState([
    { label: "None", value: "" },
    { label: "Drug Code", value: "oi.item_code" },
    { label: "Drug Name", value: "oi.item_name" },
    { label: "Bill Date", value: "so.created_date" },
    { label: "Drug Code & Bill Date", value: "oi.item_code,so.created_date" },
    { label: "Drug Name & Bill Date", value: "oi.item_name,so.created_date" },
    { label: "Drug Code & Branch", value: "oi.item_code,si.firm_name" },
    { label: "Drug Code & Customer", value: "oi.item_code,c.cmr_first_name" },
    { label: "Drug Code & UOM", value: "oi.item_code,oi.item_uom" },
    {
      label: "Drug Code & Batch No",
      value: "oi.item_code,oi.item_batch_number",
    },
    { label: "Drug Code & Qty", value: "oi.item_code,oi.item_quantity" },
    { label: "Drug Code & Rate", value: "oi.item_code,oi.item_unit_price" },
  ]);

  const [loading, setLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState(
    Object.entries(tableColoumsn).map(([label, value]) => ({
      label,
      value,
    }))
  );
  const [selectedColumns, setSelectedColumns] = useState(tableHeading);
  const [filters, setFilters] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [dateRange, setDateRange] = useState("");
  const [groupBy, setGroupBy] = useState("");

  useEffect(() => {
    const selectedColumnValues = selectedColumns.map(
      (column) => tableColoumsn[column]
    );
    const requestData = {
      selectedColumns: selectedColumnValues,
      filters: filters.map(({ id, ...rest }) => rest),
      filter_by: dateRange,
      group_by: groupBy,
      from: dateRangeFilter?.from,
      to: dateRangeFilter?.to,
    };
    dispatch(productWiseSalesReportAction(requestData, onSuccess, onError));
    setLoading(true);
  }, [selectedColumns, filters, dateRange, groupBy]);

  const isIso8601Date = (dateString) => {
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return iso8601Regex.test(dateString);
  };

  const formatDate = (value) => {
    return moment(value).format("DD-MM-YYYY");
  };

  const transformData = (data) => {
    return data.map((item) => {
      let transformedItem = {};
      for (let [key, value] of Object.entries(tableColoumsn)) {
        const rawValue = item[value.split(".").pop()];
        transformedItem[key] = isIso8601Date(rawValue)
          ? formatDate(rawValue)
          : rawValue;
      }
      return transformedItem;
    });
  };

  const onSuccess = (data) => {
    console.log("data is", data);
    const transformedData = transformData(data?.data?.result);
    setTableData(transformedData);
    setLoading(false);
  };
  const onError = (data) => {
    console.log("error is", data);
    setLoading(false);
  };

  return (
    <ReportsLayout
    reportType={"gst"}

      title={"GSTR-9 (Annual Return)"}
      tableHeading={selectedColumns}
      tableData={tableData}
      groupByOptions={groupByOptions}
      filterOptions={filterOptions}
      availableColumns={tableHeading}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      setFilters={setFilters}
      loading={loading}
      setDateRange={setDateRange}
      setGroupBy={setGroupBy}
      setDateRangeFilterValue={setDateRangeFilterValue}
    />
  );
};

const tableHeading = [
  "Financial Year", // New entry
  "Aggregate Turnover", // New entry
  "Taxable Supplies", // New entry
  "Exempt Supplies", // New entry
  "Total GST Paid", // New entry
  "SGST Paid", // Replacing SGST Amount
  "CGST Paid", // Replacing CGST Amount
  "IGST Paid", // Replacing IGST Amount
  "ITC Claimed", // Already present
  "ITC Reversed", // New entry
  "Late Fees", // New entry
  "Interest", // Replacing Interest/Penalty
  "Cess Paid", // Replacing Cess Amount
];

const tableColoumsn = {
  "Financial Year": "so.financial_year", // New entry for financial year
  "Aggregate Turnover": "so.aggregate_turnover", // New entry for aggregate turnover
  "Taxable Supplies": "so.taxable_supplies", // New entry for taxable supplies
  "Exempt Supplies": "so.exempt_supplies", // New entry for exempt supplies
  "Total GST Paid": "so.total_gst_paid", // New entry for total GST paid
  "SGST Paid": "so.sgst_paid", // Replacing SGST Amount
  "CGST Paid": "so.cgst_paid", // Replacing CGST Amount
  "IGST Paid": "so.igst_paid", // Replacing IGST Amount
  "ITC Claimed": "so.itc_claimed", // Already present for ITC claimed
  "ITC Reversed": "so.itc_reversed", // New entry for ITC reversed
  "Late Fees": "so.late_fees", // New entry for late fees
  Interest: "so.interest_amount", // Replacing Interest/Penalty
  "Cess Paid": "so.cess_paid", // Replacing Cess Amount
};

export default Gstr9Report;
